import styled from "styled-components";
import { Dialog } from "@headlessui/react";

export const WrapperPanel = styled(Dialog.Panel).attrs((props) => ({
  className: `inline-block w-full max-w-${
    props.size && props.size
  } p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl`,
}))`
  background: #f8fafc;
  border-radius: 10px;
`;

export const WrapperHeader = styled(Dialog.Title)`
  background-color: ${(props) =>
    props.type === "success"
      ? "#43C667"
      : props.type === "failed"
      ? "#E94141"
      : props.type === "overripe"
      ? "#ffa24d"
      : "transparent"};
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  border-radius: 18px 18px 0px 0px;

  span {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #f8fafc;
  }
`;

export const WrapperIcons = styled.div.attrs({
  className: "flex justify-center items-center",
})`
  background: ${(props) => (props.type === "success" ? "rgba(67, 198, 103, 0.25)" : "rgba(255, 0, 0, 0.25);")};
  width: 64px;
  height: 64px;
  border-radius: 50px;
`;

export const WrapperConfirm = styled.div.attrs({
  className: "flex flex-col justify-center items-center",
})`
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 16px;
    text-align: center;
    color: #6f8998;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #6f8998;
  }
`;

export const WrapperButton = styled.div.attrs({
  className: "flex flex-row w-100 justify-center items-center pt-14 mb-4 gap-5 px-6",
})``;

export const WrapperButtonConfirm = styled.div.attrs({
  className: "flex flex-row w-100 justify-center items-center pt-14 mb-4 gap-5",
})``;
