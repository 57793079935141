import Modal from "../../../modal/modal.component";
import Button from "../../../button/button.component";
import Moment from "moment";
import { useSelector } from "react-redux";
import { selectProductActiveById } from "../../../../store/product/product.slice";
import {
  Description,
  WrapperButton,
  WrapperDescription,
  WrapperDetail,
  WrapperImageDetail,
} from "./garmination.styles";

const Detail = ({ isOpen, handleClose, id, setTypeModal, product }) => {
  const handleUpdateStatus = () => {
    setTypeModal();
  };
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      size="md"
      type="success"
      padding="p-0"
      title={product?.productName}
    >
      <WrapperDetail className="rounded-b-[18px] overflow-hidden">
        <WrapperImageDetail className="relative">
          {product?.imageLink && <img src={product?.imageLink} alt="img" className="h-full w-full object-contain" />}
        </WrapperImageDetail>
        <WrapperDescription>
          <Description>
            <span className="label-desc">Batch</span>
            <span className="value-desc">{product?.codeBatch}</span>
          </Description>
          <Description>
            <span className="label-desc">Code LT</span>
            <span className="value-desc">{product?.pod?.codeName}</span>
          </Description>
          <Description>
            <span className="label-desc">Plant Date</span>
            <span className="value-desc">{Moment(product?.plantDate).format("DD MMMM YYYY")}</span>
          </Description>
          <Description>
            <span className="label-desc">Seed Weight</span>
            <span className="value-desc">{product?.seedVolume}</span>
          </Description>
          <Description>
            <span className="label-desc">Quantity</span>
            <span className="value-desc">{product?.totalTray}</span>
          </Description>
          <Description>
            <span className="label-desc">Status</span>
            <span className="value-desc value-orange capitalize">{product?.harvestStateLabel}</span>
          </Description>
        </WrapperDescription>
        <WrapperButton>
          <Button variants="mainFilled" size="lg" type="button" onClick={handleUpdateStatus} className="w-52">
            Update Status
          </Button>
        </WrapperButton>
      </WrapperDetail>
    </Modal>
  );
};

export default Detail;
