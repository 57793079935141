import { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  Address,
  EnvironmentContentDetail,
  Header,
  Notif,
  Status,
} from './active-pod.styles';
import Toggle from '../../button/toggle.component';
import CameraSvg from '../../../assets/img/camera.svg';
import LocationSvg from '../../../assets/img/location-info.svg';
import { useSelector } from 'react-redux';
import { selectAllPresetss } from '../../../store/presets/presets.slice';
import { selectPodById } from '../../../store/pod/pod.slice';
import Dropdown from '../../dropdown/dropdown.component';
import { WrapperTextInput } from '../styles';
import ColumnChart from '../../chart/column.component';
import SunOn from '../../../assets/img/sun.svg';
import SunOff from '../../../assets/img/sun-off.svg';
import WaterOn from '../../../assets/img/water.svg';
import WaterOff from '../../../assets/img/water-off.svg';
import DotSvg from '../../../assets/img/dot-water.svg';
import { Progress } from '@material-tailwind/react';
import SimpleDateTime from 'react-simple-timestamp-to-date';
import { selectAllUserPods } from '../../../store/pod/pod.slice';

const ModalDialog = ({ isOpen, handleClose, id }) => {
  let refDiv = useRef(null);
  const presets = useSelector(selectAllPresetss);
  const pod = useSelector((state) => selectPodById(state, id));
  const [optionPresets, setOptionPresets] = useState([]);
  const [selectedPreset, setSelectedPreset] = useState({});
  const users = useSelector(selectAllUserPods);
  const [userPod, setUserPod] = useState('');

  useEffect(() => {
    if (users.length > 0) {
      const findUser = users.find((obj) => obj.pod?.codeName === pod.codeName);
      if (findUser) {
        setUserPod(findUser?.user?.name || '');
      }
    }
  }, [pod, users]);

  useEffect(() => {
    const newOption = [];
    presets?.map(({ _id: id, label }) => {
      if (id === pod?.preset) {
        setSelectedPreset({ id, label });
      }
      newOption.push({ id, label });
    });
    setOptionPresets(newOption);
  }, [presets, pod]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        initialFocus={refDiv}
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={handleClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 "
              style={{ backgroundColor: 'rgba(137, 226, 162, 0.3)' }}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={`inline-block w-full max-w-md overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl`}
              ref={refDiv}
            >
              <Header>
                <div className="flex justify-between items-center">
                  <div className="flex flex-col">
                    <div className="flex flex-row gap-2 items-center">
                      <span>{pod?.codeName}</span>
                      <Status variant="greener">
                        {pod?.podTypeLabel || '-'}
                      </Status>
                      <Toggle value={pod?.visible} />
                    </div>
                    <span className="heartbeat ">
                      <SimpleDateTime
                        dateFormat="DMY"
                        dateSeparator="-"
                        timeSeparator=":"
                      >
                        {pod?.heartbeatTimestamp}
                      </SimpleDateTime>
                      {/* {Moment(pod?.heartbeatTimestamp).format('DD MMMM YYYY hh:mm:ss')} */}
                    </span>
                  </div>

                  <img src={CameraSvg} alt="icon" className="cursor-pointer" />
                </div>
              </Header>
              <hr className="text-grey-300" />
              <Address className="flex justify-between h-100">
                <div className="flex flex-col gap-1">
                  <div className="flex flex-row gap-1">
                    <span className="value-orange">Garden</span>
                    <span className="">-</span>
                    <span className="">{userPod}</span>
                  </div>
                  <span className="value-address">{pod?.address || '-'}</span>
                </div>
                <div className="flex flex-col justify-center items-center h-100">
                  <a
                    href={`https://maps.google.com?q=${pod?.coordinate}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={LocationSvg}
                      alt="icon"
                      className="cursor-pointer"
                      height={40}
                      width={40}
                    />
                  </a>
                </div>
              </Address>
              <hr className="text-grey-300" />
              <div className="h-[80px] p flex flex-row justify-center items-center py-[20px] px-[30px] w-100">
                <WrapperTextInput className="w-full">
                  <Dropdown
                    options={optionPresets}
                    selectedOption={selectedPreset}
                    handelChange={(val) => {
                      setSelectedPreset(val);
                    }}
                    placeHolder="Choose Preset"
                    disabled={pod?.visible}
                  />
                </WrapperTextInput>
              </div>
              <Notif
                color="yellow"
                className="flex flex-row justify-center items-center"
              >
                {/* <span>Batches are in proccess.</span> */}
              </Notif>
              <div className="h-[81px] flex flex-row gap-5 justify-center items-center">
                <span className="font-normal text-[11px] leading-[16px] grey-color">
                  Tank
                </span>
                <div className="mt-5">
                  <ColumnChart pod={pod} />
                </div>
              </div>
              <EnvironmentContentDetail className="flex flex-col gap-2">
                <div className="bg-white h-[44px] flex justify-between items-center p-[16px] rounded-[8px]">
                  <span>Life Support</span>
                  {pod?.snapshot?.life_support_lamp ? (
                    <img src={SunOn} height={25} width={25} alt="sun" />
                  ) : (
                    <img src={SunOff} height={25} width={25} alt="sun" />
                  )}
                  {pod?.snapshot?.life_support_pump ? (
                    <img src={WaterOn} height={25} width={25} alt="sun" />
                  ) : (
                    <img src={WaterOff} height={25} width={25} alt="sun" />
                  )}
                </div>
                <div className="h-[147px] bg-white rounded-[8px] p-[16px]">
                  <div className="flex flex-col gap-2">
                    <div className="flex justify-between items-center">
                      <div className="flex flex-row gap-2  items-center">
                        <img src={DotSvg} alt="icon" height={15} width={15} />
                        <span>Water Condition</span>
                      </div>
                      <label className="main-color font-normal text-[16px] leading-[28px]">
                        {pod?.snapshot?.water_temp}° C
                      </label>
                    </div>
                    <div className="flex flex-col">
                      <div className="flex justify-between">
                        <span>Acid</span>
                        <span className="main-color">Stable</span>
                      </div>
                      <Progress
                        value={pod?.snapshot?.water_acid}
                        className="min-h-fit main-color"
                        color="green"
                      />
                    </div>
                    <div className="flex flex-col mt-4">
                      <div className="flex justify-between">
                        <span>Nutrient</span>
                        <span className="main-color">Stable</span>
                      </div>
                      <Progress
                        value={pod?.snapshot?.water_nutrient}
                        className="min-h-fit main-color"
                        color="green"
                      />
                    </div>
                  </div>
                </div>
                <div className="h-[147px] bg-white rounded-[8px] p-[16px]">
                  <div className="flex flex-col gap-2">
                    <div className="flex justify-between items-center">
                      <div className="flex flex-row gap-2  items-center">
                        <img src={DotSvg} alt="icon" height={15} width={15} />
                        <span>Air Condition</span>
                      </div>
                      <label className="main-color font-normal text-[16px] leading-[28px]">
                        {pod?.snapshot?.air_temp}° C
                      </label>
                    </div>
                    <div className="flex flex-col">
                      <div className="flex justify-between">
                        <span>CO2</span>
                        <span className="main-color">Stable</span>
                      </div>
                      <Progress
                        value={pod?.snapshot?.air_co2}
                        className="min-h-fit main-color"
                        color="green"
                      />
                    </div>
                    <div className="flex flex-col mt-4">
                      <div className="flex justify-between">
                        <span>Humidity</span>
                        <span className="main-color">Stable</span>
                      </div>
                      <Progress
                        value={pod?.snapshot?.air_humidity}
                        className="min-h-fit main-color"
                        color="green"
                      />
                    </div>
                  </div>
                </div>
              </EnvironmentContentDetail>
              <div className="flex flex-row h-[56px] w-100">
                <div
                  className="w-full flex flex-row justify-center items-center bg-white main-color cursor-pointer"
                  onClick={handleClose}
                >
                  <u>Close</u>
                </div>
                <div
                  className={`w-full flex flex-row justify-center items-center text-white cursor-pointer ${
                    pod?.visible ? 'inactive-color' : 'bg-main-color'
                  }`}
                >
                  <u>Manage</u>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
        <button className="opacity-0 absolute bottom-0"></button>
      </Dialog>
    </Transition>
  );
};

export default ModalDialog;
