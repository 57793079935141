import { WrapperListActivity } from '../styles';
import Card from './card.component';
import ModalDetail from './details.component';
import { useState } from 'react';

const ListPods = ({ pods }) => {
  const [id, setId] = useState('');
  const [openDetail, setOpenDetail] = useState(false);

  return (
    <>
      {openDetail && (
        <ModalDetail
          isOpen={openDetail}
          handleClose={() => setOpenDetail(false)}
          id={id}
        />
      )}
      <WrapperListActivity>
        {pods?.map((pod) => (
          <Card
            key={pod?._id}
            openDetail={() => {
              setId(pod?._id);
              setOpenDetail(true);
            }}
            pod={pod}
          />
        ))}
      </WrapperListActivity>
    </>
  );
};

export default ListPods;
