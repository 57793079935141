import { useEffect, useState } from "react";
import Modal from "../modal/modal.component";
import ModalConfirm from "../modal/modal-confirm.component";
import Button from "../button/button.component";
import {
  WrapperForm,
  WrapperFoto,
  WrapperInput,
  WrapperTextInput,
  WrapperContainerForm,
  WrapperButton,
  WrapperButtonEdit,
} from "./styles";
import { useGetUserByIdQuery, useUpdateUserMutation } from "../../store/user/user.slice";
import Dropdown from "../dropdown/dropdown.component";
import { CameraIcon, PencilIcon } from "@heroicons/react/solid";
import getBase64 from "../../utils/base64";

const FormModal = ({ isOpen, handleClose, id }) => {
  let inputRef;
  const { data, isLoading, isSuccess } = useGetUserByIdQuery(id);
  const [updateUser, { isLoading: isLoadingUpdate }] = useUpdateUserMutation();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openConfirmAction, setOpenConfirmAction] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    description: "",
    typeConfirm: "",
    typeModal: "",
    isError: false,
  });

  const optionRoles = [
    {
      id: "admin",
      label: "Admin",
      value: "admin",
    },
    {
      id: "botanist",
      label: "Botanist",
      value: "botanist",
    },
    {
      id: "greener",
      label: "Greener",
      value: "greener",
    },
    {
      id: "guest",
      label: "Guest",
      value: "guest",
    },
  ];

  const [payload, setPayload] = useState({
    email: "",
    password: "",
    role: "",
    name: "",
    nik: "",
    address: "",
    phoneNum: "",
    avatar: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newPayload = { ...payload, role: payload.role?.id };
      console.log("🚀 ~ file: edit-modal.component.jsx:78 ~ handleSubmit ~ newPayload", newPayload);
      const payloadID = { id: id, body: newPayload };
      const response = await updateUser(payloadID).unwrap();
      console.log("🚀 ~ file: edit-modal.component.jsx:78 ~ handleSubmit ~ response", response);
      setOpenConfirmAction(false);
      setModalData({
        title: "User Edited",
        description: "You’ve successfully edited user.",
        typeModal: "",
        typeConfirm: "success",
        isError: false,
      });
      setOpenConfirm(true);
    } catch (err) {
      setOpenConfirmAction(false);
      setModalData({
        title: "Failed",
        description: err?.data?.message,
        typeModal: "",
        typeConfirm: "failed",
        isError: true,
      });
      setOpenConfirm(true);
    }
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      const role = optionRoles.find((obj) => obj.value === data.role);
      setPayload({
        email: data?.email,
        role: role,
        name: data?.name,
        nik: data?.nik,
        address: data?.address,
        phoneNum: data?.phoneNum,
        avatar: data?.avatar,
      });
    }
  }, [data, isLoading, isSuccess]);

  const handleFileSelected = (e) => {
    const file = e.target.files && e.target.files[0];
    getBase64(file, (result) => {
      setPayload({ ...payload, avatar: result });
    });
  };

  return (
    <>
      <ModalConfirm
        isOpen={openConfirm}
        handleClose={() => {
          handleClose();
          setOpenConfirm(false);
        }}
        title={modalData.title}
        description={modalData.description}
        typeConfirm={modalData.typeConfirm}
        typeModal={modalData.typeModal}
        isError={modalData.isError}
      />
      <ModalConfirm
        isOpen={openConfirmAction}
        handleClose={() => setOpenConfirmAction(false)}
        description={"Are you sure, you want to update this user information? "}
        typeModal="success"
        typeConfirm="success"
        title="Edit User"
        isError={modalData.isError}
        withAction={true}
        isLoading={isLoadingUpdate}
        handleSave={handleSubmit}
        titleButton="Save"
      />
      <Modal isOpen={isOpen} handleClose={handleClose} size="lg" title="Edit User" type="success">
        <WrapperContainerForm onSubmit={handleSubmit}>
          <WrapperForm>
            <WrapperFoto className="relative bg-grey-400">
              {payload.avatar && (
                <img
                  src={payload.avatar}
                  style={{ width: "100px", height: "90px", borderRadius: "55px" }}
                  className="img-fluid h-100 w-100"
                  alt="avatar"
                />
              )}
              {!payload.avatar && <CameraIcon height={50} width={50} className="text-black" />}
              <WrapperButtonEdit
                className="absolute h-[30px] w-[30px] flex flex-col justify-center items-center rounded-full bg-black -bottom-0 -right-1 cursor-pointer"
                onClick={() => inputRef.click()}
              >
                <PencilIcon className="text-black" height={20} width={20} />
              </WrapperButtonEdit>
              <input
                ref={(refParam) => (inputRef = refParam)}
                type="file"
                onChange={handleFileSelected}
                hidden={true}
              />
              {/* <span>{payload.name !== "" && payload.name != null ? payload.name[0].toUpperCase() : ""}</span> */}
            </WrapperFoto>
            <WrapperInput>
              <WrapperTextInput>
                <span>Full Name</span>
                <input type="text" value={payload.name} placeholder="Input Name" name="name" onChange={handleChange} />
              </WrapperTextInput>
              <WrapperTextInput>
                <span>Role</span>
                <Dropdown
                  options={optionRoles}
                  selectedOption={payload.role}
                  handelChange={(role) => {
                    setPayload({ ...payload, role: role });
                  }}
                />
              </WrapperTextInput>
              <WrapperTextInput>
                <span>E-mail</span>
                <input
                  type="email"
                  value={payload.email || ""}
                  placeholder="Input Email"
                  name="email"
                  onChange={handleChange}
                />
              </WrapperTextInput>
              <WrapperTextInput>
                <span>NIK</span>
                <input
                  type="text"
                  value={payload.nik || ""}
                  placeholder="Input National ID"
                  name="nik"
                  onChange={handleChange}
                />
              </WrapperTextInput>
              <WrapperTextInput>
                <span>Phone Number</span>
                <input
                  type="text"
                  value={payload.phoneNum || ""}
                  placeholder="Input Phone Number"
                  name="phoneNum"
                  onChange={handleChange}
                />
              </WrapperTextInput>
              <WrapperTextInput>
                <span>Address</span>
                <input
                  type="text"
                  value={payload.address || ""}
                  placeholder="Input Address"
                  name="address"
                  onChange={handleChange}
                />
              </WrapperTextInput>
              <WrapperTextInput>
                <span>Password</span>
                <input
                  type="password"
                  value={payload.password || ""}
                  placeholder="Input Password"
                  name="password"
                  onChange={handleChange}
                />
              </WrapperTextInput>
            </WrapperInput>
          </WrapperForm>
          <WrapperButton>
            {/* <Button
              variant="outlined"
              variants="dangerOutlined"
              size="lg"
              className="w-full hidden"
              onClick={handleClose}
            >
              Delete
            </Button> */}
            <Button
              variant="filled"
              variants="mainFilled"
              size="lg"
              className="w-full"
              type="button"
              disabled={isLoadingUpdate}
              onClick={() => {
                setOpenConfirmAction(true);
              }}
            >
              Save
            </Button>
          </WrapperButton>
        </WrapperContainerForm>
      </Modal>
    </>
  );
};

export default FormModal;
