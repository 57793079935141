import Modal from "../../../modal/modal.component";
import Button from "../../../button/button.component";
import { WrapperConfirm, WrapperConfirmButton, WrappertConfirmImage } from "./new-request.styles";

const Confirm = ({ image, title, description, handleClose, isOpen, type = "success", id, modalData, product }) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} type="" size="md" padding="p-0">
      <div className="flex flex-col rounded-b-[18px] overflow-hidden">
        <WrapperConfirm type={type}>
          <WrappertConfirmImage className="relative">
            {product?.imageLink && <img src={product?.imageLink} alt="img" className="h-full w-full object-contain" />}
          </WrappertConfirmImage>
          <span>{product?.productName}</span>
          {/* <p>{product?.codeBatch}</p> */}
          <p className="px-14">{modalData.description}</p>
        </WrapperConfirm>
        <WrapperConfirmButton>
          <Button
            variants={`${type === "success" ? "mainFilled" : "dangerFilled"}`}
            size="lg"
            type="button"
            onClick={handleClose}
            className="w-52"
          >
            Close
          </Button>
        </WrapperConfirmButton>
      </div>
    </Modal>
  );
};

export default Confirm;
