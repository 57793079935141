import { apiSlice } from "../api/api";

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => "/users?$limit=100",
      providesTags: (result, error, arg) => [
        { type: "User", id: "LIST" },
        // ...result.ids.map((id) => ({ type: 'User', id })),
      ],
    }),
    getUserById: builder.query({
      query: (id) => `/users/${id}`,
      providesTags: (result, error, arg) => [{ type: "User", id: "LIST" }],
    }),
    addUser: builder.mutation({
      query: (initialPost) => ({
        url: `/users`,
        method: "POST",
        body: {
          ...initialPost,
        },
      }),
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),
    updateUser: builder.mutation({
      query: (payload) => ({
        url: `/users/${payload.id}`,
        method: "PATCH",
        body: payload.body,
      }),
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),
    deleteUserPod: builder.mutation({
      query: (id) => ({
        url: `/user/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Pod", id: "LIST" }],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useUpdateUserMutation,
  useAddUserMutation,
} = usersApiSlice;
