import styled, { css } from 'styled-components';
import { Button } from '@material-tailwind/react';

const VARIANTS = {
  mainOutlined: css`
    border: 1px solid #43c667;
    background-color: transparent;
    color: #43c667;
  `,
  mainFilled: css`
    border: 1px solid #43c667;
    background-color: #43c667;
    color: #ffffff;
  `,
  dangerOutlined: css`
    border: 1px solid #e94141;
    background-color: transparent;
    color: #e94141;
  `,
  dangerFilled: css`
    border: 1px solid #e94141;
    background-color: #e94141;
    color: #ffffff;
  `,
  orangeFilled: css`
    border: 1px solid #ffa24d;
    background-color: #ffa24d;
    color: #ffffff;
  `,
};

const SIZE = {
  sm: css`
    height: 32px;
    padding: 10px 16px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-transform: none;
  `,
  md: css`
    height: 40px;
    padding: 10px 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-transform: none;
  `,
  lg: css`
    height: 40px;
    padding: 10px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-transform: none;
  `,
};

const WrapperButton = styled(Button)`
  ${(props) => props.variants && VARIANTS[props.variants]}
  ${(props) => props.size && SIZE[props.size]}
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default WrapperButton;
