import {
  WrapperAction,
  WrapperCard,
  WrapperProfile,
  WrapperProfileDetail,
} from './styles';
import Button from '../button/button.component';

const Card = ({ user, onEdit, openEdit, openManage }) => {
  const handleEdit = () => {
    onEdit(user._id);
    openEdit();
  };

  const handleManage = () => {
    onEdit(user._id);
    openManage();
  };
  return (
    <WrapperCard>
      <div
        className="bg-white mb-2"
        style={{ width: '90px', height: '68px', borderRadius: '50px' }}
      >
        <img
          src={user?.avatar}
          style={{ width: '90px', height: '68px', borderRadius: '55px' }}
          className="img-fluid h-100 w-100"
          alt="avatar"
        />
      </div>
      <WrapperProfile>
        <WrapperProfileDetail>
          <span>{user?.name}</span>
          <p className="mb-0">{user?.email}</p>
          <p className="mb-0 mt-8">Role: {user?.role}</p>
          <p>Pods: {user?.pod}</p>
        </WrapperProfileDetail>
        <WrapperAction>
          <Button
            variant="outlined"
            variants="mainOutlined"
            size="sm"
            onClick={handleEdit}
          >
            Edit
          </Button>
          <u className="cursor-pointer" onClick={handleManage}>
            Manage Pod
          </u>
        </WrapperAction>
      </WrapperProfile>
    </WrapperCard>
  );
};

export default Card;
