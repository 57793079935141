import React from "react";
import { HeaderArea, WrapperHeader } from "./Header.styles";
import BrandLogo from "../../assets/img/brand-logo.svg";

export default function Header() {
  return (
    <HeaderArea>
      <WrapperHeader className="sticky top-0 flex justify-center items-center">
        <img src={BrandLogo} alt="brand" height={90} width={116} />
      </WrapperHeader>
    </HeaderArea>
  );
}
