import Modal from "../../modal/modal.component";
import Button from "../../button/button.component";
import { useState, useEffect } from "react";
import { useCreatePlantCatgeoryMutation, useUpdatePlantCatgeoryMutation } from "../../../store/product/product.slice";
import Spinner from "../../loader/spinner.component";
import { WrapperFormCreate, WrapperTextInput } from "./styles";

const CreatePlantCategory = ({ isOpen, handleClose, setModalData, setOpenConfirm, category }) => {
  const [payload, setPayload] = useState({ text: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [saveCategory] = useCreatePlantCatgeoryMutation();
  const [updateCategory] = useUpdatePlantCatgeoryMutation();

  const onSaveClicked = async () => {
    setIsLoading(true);
    try {
      if (!category) {
        console.log("Create Plant Category with Payload : ", payload);
        console.log(`Create Plant Category URL POST : ${process.env.REACT_APP_API_BASE_URL}/plant-category`);
        const response = await saveCategory(payload).unwrap();
        console.log("Create Plant Category Response : ", response);

        setModalData({
          title: "New Category Added",
          subTitle: "New Category Added",
          description: "You’ve successfully added new category.",
          typeModal: "success",
          typeConfirm: "success",
          isError: false,
        });
      } else {
        console.log("Edit Plant Category with Payload : ", payload);
        console.log(
          `Edit Plant Category URL POST : ${process.env.REACT_APP_API_BASE_URL}/plant-category/${category._id}`
        );
        const response = await updateCategory({ id: category._id, payload }).unwrap();
        console.log("Edit Plant Category Response : ", response);

        setModalData({
          title: "Category Edited",
          subTitle: "Category Edited",
          description: "You’ve successfully edit the category.",
          typeModal: "success",
          typeConfirm: "success",
          isError: false,
        });
      }

      setTimeout(() => {
        setOpenConfirm(true);
        setIsLoading(false);
        handleClose();
      }, 700);
    } catch (err) {
      console.log("Plant Category  Response : ", err);

      setModalData({
        title: "Failed",
        subTitle: "Failed to save category",
        description: err.data.message,
        typeModal: "failed",
        typeConfirm: "failed",
        isError: true,
      });
      setTimeout(() => {
        setOpenConfirm(true);
        setIsLoading(false);
        handleClose();
      }, 500);
    }
  };

  useEffect(() => {
    if (category) {
      setPayload({ text: category.text });
    }
  }, [category]);

  return (
    <>
      <Modal isOpen={isOpen} handleClose={handleClose} size="md" type="success" padding="p-0" title="Create Category">
        <WrapperFormCreate className="justify-between">
          <WrapperTextInput>
            <span>Category name</span>
            <input
              type="text"
              value={payload.text}
              placeholder="Input category name"
              onChange={(e) => setPayload({ ...payload, text: e.target.value })}
            />
          </WrapperTextInput>
          <div className="flex flex-row w-100 justify-center items-center gap-2 pt-2 pb-4">
            <Button
              variant="filled"
              variants="mainFilled"
              size="lg"
              className="w-full"
              type="button"
              disabled={isLoading}
              onClick={() => {
                onSaveClicked();
              }}
            >
              {isLoading && <Spinner />}
              Save
            </Button>
          </div>
        </WrapperFormCreate>
      </Modal>
    </>
  );
};

export default CreatePlantCategory;
