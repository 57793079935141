import Button from '../button/button.component';
import { PlusIcon } from '@heroicons/react/solid';

export default function Filter({ onClickTab, filter, addModal }) {
  return (
    <div className="flex justify-between w-100">
      <div className="flex flex-row gap-3 ">
        <Button
          variant="outlined"
          variants={filter === '' ? `mainFilled` : 'mainOutlined'}
          size="md"
          onClick={() => onClickTab('')}
        >
          All
        </Button>
        <Button
          variant="outlined"
          variants={filter === 'botanist' ? `mainFilled` : 'mainOutlined'}
          size="md"
          onClick={() => onClickTab('botanist')}
        >
          Botanist
        </Button>
        <Button
          variant="outlined"
          variants={filter === 'greener' ? `mainFilled` : 'mainOutlined'}
          size="md"
          onClick={() => onClickTab('greener')}
        >
          Greener
        </Button>
        <Button
          variant="outlined"
          variants={filter === 'admin' ? `mainFilled` : 'mainOutlined'}
          size="md"
          onClick={() => onClickTab('admin')}
        >
          Admin
        </Button>
        <Button
          variant="outlined"
          variants={filter === 'guest' ? `mainFilled` : 'mainOutlined'}
          size="md"
          onClick={() => onClickTab('guest')}
        >
          Guest
        </Button>
      </div>
      <div className="flex w-100">
        <Button
          variant="outlined"
          variants="mainFilled"
          size="md"
          className="flex justify-center items-center"
          onClick={addModal}
        >
          <PlusIcon height={20} width={20} />
          Add New User
        </Button>
      </div>
    </div>
  );
}
