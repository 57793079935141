import { memo } from 'react';
import Modal from '../../../modal/modal.component';
import Dropdown from '../../../dropdown/dropdown.component';
import { useSelector } from 'react-redux';
import { selectProductActiveById, useCreateProductsActiveMutation } from '../../../../store/product/product.slice';
import { useGetPodsQuery } from '../../../../store/pod/pod.slice';
import {
  WrapperButton,
  WrapperDetail,
  WrapperForm,
  WrapperImageDetail,
  WrapperTextInput,
} from './new-request.styles';
import { useState, useEffect } from 'react';
import Button from '../../../button/button.component';

const Form = ({ isOpen = false, handleClose, id, setTypeModal, setModalData, nextModal }) => {
  const product = useSelector((state) => selectProductActiveById(state, id));
  const podsResponse =  useGetPodsQuery()
  const [newRequest, { isLoading, isError, isSuccess }] = useCreateProductsActiveMutation()
  const [podSelected, setPodSelected] = useState({})
  const [optionGreensPod, setOptionGreensPod] = useState([])
  const [payload, setPayload] = useState({
    seedVolume: 0,
    totalTray: 0,
    seedBatch: '',
    seedSource: '',
    seedExpDate: '',
    estHarvestDate: '',
    podId: '',
    productTemplateUserId: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const onStartGarminationClicked = async () => {
      
    try {
      console.log(payload, product.harvestState, id)
        await newRequest( {id, initialPost: payload }).unwrap()
        if (isSuccess) {
          setModalData({
            title: product?.productName,
            description: `${product?.productName}\n Batch Start Garminating.`,
            typeModal: 'confirm-success',
            typeConfirm: 'success',
            isError: false,
          })
          setTypeModal('confirm-success')
          setPayload({
            seedVolume: 0,
            totalTray: 0,
            seedBatch: '',
            seedSource: '',
            seedExpDate: '',
            estHarvestDate: '',
            podId: '',
            productTemplateUserId: '',
          })
        }
       
    } catch (err) {
      setModalData({
        title: product?.productName,
        description: `${product.productName} \n
        Failed Start Garminating.`,
        typeModal: 'confirm-failed',
        typeConfirm: 'failed',
        isError: false,
      })
      setTypeModal('confirm-failed')
      console.error('Failed to save the post', err)
    }
      
  }

  useEffect(() => {
    let data = podsResponse?.data?.entities
    let arrayPods = []
    console.log(data)
    for (const key in data) {
      arrayPods.push({ id: key, label: data[key].codeName });
    }
    
    console.log(arrayPods ,  "== ini array pods")
    
    setOptionGreensPod(arrayPods);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      size="md"
      type="success"
      padding="p-0"
      title={product?.productName}
    >
      <WrapperDetail>
        <WrapperImageDetail />
        <WrapperForm>
          <WrapperTextInput>
            <input
              type="text"
              value={payload.seedVolume || ''}
              placeholder="Input Seed Weight (Gram)"
              name="seedVolume"
              onChange={handleChange}
            />
          </WrapperTextInput>
          <WrapperTextInput>
            <input
              type="text"
              value={payload.totalTray || ''}
              placeholder="Quantity (Pot)"
              name="totalTray"
              onChange={handleChange}
            />
          </WrapperTextInput>
          <WrapperTextInput>
            <input
              type="text"
              value={payload.seedBatch || ''}
              placeholder="Seed Batch"
              name="seedBatch"
              onChange={handleChange}
            />
          </WrapperTextInput>
          <WrapperTextInput>
            <input
              type="text"
              value={payload.seedSource || ''}
              placeholder="Seed Source"
              name="seedSource"
              onChange={handleChange}
            />
          </WrapperTextInput>
          <WrapperTextInput>
            <input
              type="text"
              value={payload.seedExpDate || ''}
              placeholder="Seed Exp. Date"
              name="seedExpDate"
              onChange={handleChange}
            />
          </WrapperTextInput>
          <WrapperTextInput>
            <input
              type="text"
              value={payload.estHarvestDate || ''}
              placeholder="Seed Est. Harvest Date"
              name="estHarvestDate"
              onChange={handleChange}
            />
          </WrapperTextInput>
          <WrapperTextInput>
            <Dropdown
              options={optionGreensPod}
              placeHolder="Choose GREENS Pod"
              selectedOption={optionGreensPod.filter(opt => opt.id === payload.podId)[0]}
              handelChange={(e) => {
                setPodSelected(e)
                setPayload({ ...payload,  podId:e.id })
                console.log(payload)
              }}
            />
          </WrapperTextInput>
        </WrapperForm>
        <WrapperButton className="px-20">
          <Button
            variant="filled"
            variants="mainFilled"
            size="lg"
            className="w-full"
            type="button"
            // disabled={isLoading}
            onClick={onStartGarminationClicked}
          >
            {/* {isLoading && <Spinner />} */}
            Start Garmination
          </Button>
        </WrapperButton>
      </WrapperDetail>
    </Modal>
  );
};

export default memo(Form);
