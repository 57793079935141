import Modal from "../../../modal/modal.component";
import Button from "../../../button/button.component";
import Dropdown from "../../../dropdown/dropdown.component";
import { useUpdateStateMutation } from "../../../../store/product/product.slice";
import { useGetPodsQuery } from "../../../../store/pod/pod.slice";
import {
  WrapperButton,
  WrapperDescription,
  WrapperDetail,
  WrapperImageDetail,
  WrapperTextInput,
} from "./garmination.styles";
import { useState, useEffect } from "react";
import { HARVEST_STATE } from "../../../../helper/harvestState";

const Seedling = ({ isOpen, handleClose, id, setTypeModal, setModalData, product, removeProductAfterAction }) => {
  const podsResponse = useGetPodsQuery("?$limit=50&visible=true");
  const [totalTray, setTotalTray] = useState(0);
  const [podSelected, setPodSelected] = useState({});
  const [optionGreensPod, setOptionGreensPod] = useState([]);
  const [payload, setPayload] = useState({
    totalTray: "",
    podId: "",
  });
  const [updateSeedling, { isLoading, isError, isSuccess }] = useUpdateStateMutation();
  const handleChangeTotalTray = (e) => {
    const { value } = e.target;
    setTotalTray(value);
  };

  const onSaveSeedlingClicked = async () => {
    try {
      console.log("Set Harvest State to Seedling with Payload : ", payload);
      console.log(`Set Harvest State to Seedling URL PATCH : ${process.env.REACT_APP_API_BASE_URL}/product/${id}`);
      const response = await updateSeedling({
        id,
        initialPost: { ...payload, harvestState: HARVEST_STATE["SEEDLING"] },
      }).unwrap();
      console.log("Set Harvest State to Seedling Response : ", response);

      setModalData({
        description: `Batch ${product.codeBatch} is change to batch ${response.codeBatch} for seedling. `,
      });

      removeProductAfterAction();
      setTypeModal("confirm-success");

      setPayload({
        harvestState: "",
        podId: "",
        totalTray: 0,
      });
    } catch (err) {
      setModalData({
        description: err.data.message,
      });
      setTypeModal("confirm-failed");
    }
  };

  useEffect(() => {
    let data = podsResponse;
    if (data.data) {
      let arrayPods = [];
      data.data.data?.map((pod) => {
        arrayPods.push({ id: pod._id, label: `${pod.codeName} - ${pod.podTypeLabel ?? ""}` });
      });
      setOptionGreensPod(arrayPods);
    }
  }, [podsResponse]);

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      size="md"
      type="success"
      padding="p-0"
      title={product?.productName}
    >
      <WrapperDetail className="rounded-b-[18px] overflow-hidden">
        <WrapperImageDetail className="relative">
          {product?.imageLink && <img src={product?.imageLink} alt="img" className="h-full w-full object-contain" />}
        </WrapperImageDetail>
        <WrapperDescription>
          <WrapperTextInput>
            <input
              type="number"
              value={payload.totalTray}
              placeholder="Quantity (Pot)"
              name="totalTray"
              onChange={(e) => {
                setPayload({ ...payload, totalTray: +e.target.value });
              }}
            />
          </WrapperTextInput>
          <WrapperTextInput>
            <Dropdown
              options={optionGreensPod}
              placeHolder="Choose GREENS Pod"
              selectedOption={optionGreensPod.filter((opt) => opt.id === payload.podId)[0]}
              handelChange={(e) => {
                setPodSelected(e);
                setPayload({ ...payload, podId: e.id });
                console.log(payload);
              }}
            />
          </WrapperTextInput>
        </WrapperDescription>
        <WrapperButton>
          <Button variants="mainFilled" size="lg" type="button" onClick={onSaveSeedlingClicked} className="w-52">
            Seedling
          </Button>
        </WrapperButton>
      </WrapperDetail>
    </Modal>
  );
};

export default Seedling;
