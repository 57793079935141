import styled from "styled-components";

export const HeaderArea = styled.header`
  grid-area: header;
`;

export const WrapperHeader = styled.div`
  background: #f8fafc;
  height: 84px;
`;
