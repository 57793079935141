import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/api";

const productAdapter = createEntityAdapter({
  selectId: (state) => state._id,
});

const initialState = productAdapter.getInitialState();

export const productsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //PRODUCT LIST / PRODUCT TEMPLATE
    getProductTemplateQuery: builder.query({
      query: (arg) => `/product-template${arg}`,
      providesTags: (result, error, arg) => [{ type: "PRODUCT_LIST", id: "LIST" }],
    }),
    getProductsTemplate: builder.query({
      query: () => "/product-template?$limit=100",
      transformResponse: (responseData) => {
        return productAdapter.setAll(initialState, responseData.data);
      },
      providesTags: (result, error, arg) => [{ type: "PRODUCT_LIST", id: "LIST" }],
    }),
    createProductsTemplate: builder.mutation({
      query: (payload) => ({
        url: "/product-template",
        method: "POST",
        body: {
          ...payload,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "PRODUCT_LIST", id: "LIST" }],
    }),
    updateProductsTemplate: builder.mutation({
      query: (data) => ({
        url: `/product-template/${data.id}`,
        method: "PATCH",
        body: {
          ...data.payload,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "PRODUCT_LIST", id: "LIST" }],
    }),

    //PRODUCT REQUEST
    getProductRequest: builder.query({
      query: (arg) => `/product-template-request${arg}`,
      providesTags: (result, error, arg) => [{ type: "PRODUCT_REQUEST", id: "LIST" }],
    }),
    approveProductRequest: builder.mutation({
      query: (data) => ({
        url: `/product-template-request/${data.id}`,
        method: "PATCH",
        body: {
          ...data.payload,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "PRODUCT_NEW_REQUEST", id: "LIST" },
        { type: "PRODUCT_REQUEST", id: "LIST" },
      ],
    }),
    rejectProductRequest: builder.mutation({
      query: (data) => ({
        url: `/product-template-request/${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "PRODUCT_REQUEST", id: "LIST" }],
    }),
    createProductsRequest: builder.mutation({
      query: (payload) => ({
        url: "/product-template-request-many",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: [{ type: "PRODUCT_REQUEST", id: "LIST" }],
    }),

    //PRODUCT ACTIVE
    getProductActivePaging: builder.query({
      query: (arg) => `/product${arg}`,
      providesTags: (result, error, arg) => [
        { type: "PRODUCT_ACTIVE", id: "LIST" },
        // ...result.ids.map((id) => ({ type: 'Product', id })),
      ],
    }),
    getProductsActive: builder.query({
      query: (arg) => `/product${arg}`,
      transformResponse: (responseData) => {
        return productAdapter.setAll(initialState, responseData.data);
      },
      providesTags: (result, error, arg) => [
        { type: "PRODUCT_ACTIVE", id: "LIST" },
        // ...result.ids.map((id) => ({ type: 'Product', id })),
      ],
    }),
    getProductsByPod: builder.query({
      query: (podID) => `/product?podId=${podID}&$limit=50`,
      transformResponse: (responseData) => {
        return productAdapter.setAll(initialState, responseData.data);
      },
      providesTags: (result, error, arg) => [
        { type: "PRODUCT_ACTIVE", id: "LIST BY POD" },
        // ...result.ids.map((id) => ({ type: 'Product', id })),
      ],
    }),
    createProductsActive: builder.mutation({
      query: (product) => ({
        url: "/product",
        method: "POST",
        body: product,
      }),
      invalidatesTags: [{ type: "PRODUCT_ACTIVE", id: "LIST" }],
    }),
    assignToBotanist: builder.mutation({
      query: (payload) => ({
        url: "/product-template-user",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [{ type: "PRODUCT_ACTIVE", id: "LIST" }],
    }),

    updateState: builder.mutation({
      query: ({ id, initialPost }) => ({
        url: `/product/${id}`,
        method: "PATCH",
        body: initialPost,
      }),
      invalidatesTags: [{ type: "PRODUCT_ACTIVE", id: "LIST" }],
    }),

    //PRODUCT NEW REQUEST
    getProductNewRequestsActive: builder.query({
      query: (arg) => `/product-template-user${arg}`,
      providesTags: (result, error, arg) => [
        { type: "PRODUCT_NEW_REQUEST", id: "LIST" },
        // ...result.ids.map((id) => ({ type: 'Product', id })),
      ],
    }),
    deleteProductRequest: builder.mutation({
      query: (data) => ({
        url: `/product-template-user/${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "PRODUCT_NEW_REQUEST", id: "LIST" }],
    }),

    //Harvest State
    getHarvestState: builder.query({
      query: () => "/harvest-state",
      transformResponse: (responseData) => {
        return productAdapter.setAll(initialState, responseData.data);
      },
      providesTags: (result, error, arg) => [
        { type: "HARVEST_STATE", id: "LIST" },
        // ...result.ids.map((id) => ({ type: 'Product', id })),
      ],
    }),

    //Plant Product Type
    getPlantCatgeory: builder.query({
      query: () => "/plant-category?$limit=50",
      providesTags: (result, error, arg) => [{ type: "PLANT_CATEGORY", id: "LIST" }],
    }),
    createPlantCatgeory: builder.mutation({
      query: (payload) => ({
        url: "/plant-category",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [{ type: "PLANT_CATEGORY", id: "LIST" }],
    }),
    updatePlantCatgeory: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/plant-category/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: [{ type: "PLANT_CATEGORY", id: "LIST" }],
    }),
    getPlantName: builder.query({
      query: () => "/plant-name?$limit=50",
      providesTags: (result, error, arg) => [{ type: "PLANT_NAME", id: "LIST" }],
    }),
    createPlant: builder.mutation({
      query: (payload) => ({
        url: "/plant-name",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [{ type: "PLANT_NAME", id: "LIST" }],
    }),
    updatePlant: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/plant-name/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: [{ type: "PLANT_NAME", id: "LIST" }],
    }),
    getPlantProductType: builder.query({
      query: () => "/plant-product-type?$limit=50",
      providesTags: (result, error, arg) => [{ type: "PLANT_PRODUCT_TYPE", id: "LIST" }],
    }),
    createPlantProductType: builder.mutation({
      query: (payload) => ({
        url: "/plant-product-type",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [{ type: "PLANT_PRODUCT_TYPE", id: "LIST" }],
    }),
    updatePlantProductType: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/plant-product-type/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: [{ type: "PLANT_PRODUCT_TYPE", id: "LIST" }],
    }),
  }),
});

export const {
  useDeleteProductRequestMutation,
  useGetProductActivePagingQuery,
  useGetProductTemplateQueryQuery,
  useGetProductsTemplateQuery,
  useGetProductsActiveQuery,
  useCreateProductsActiveMutation,
  useCreateProductsRequestMutation,
  useGetProductRequestQuery,
  useUpdateStateMutation,
  useGetProductsByPodQuery,
  useCreateProductsTemplateMutation,
  useUpdateProductsTemplateMutation,
  useApproveProductRequestMutation,
  useRejectProductRequestMutation,
  useGetProductNewRequestsActiveQuery,
  useGetHarvestStateQuery,
  useAssignToBotanistMutation,
  useGetPlantCatgeoryQuery,
  useCreatePlantCatgeoryMutation,
  useUpdatePlantCatgeoryMutation,
  useGetPlantNameQuery,
  useCreatePlantMutation,
  useUpdatePlantMutation,
  useGetPlantProductTypeQuery,
  useCreatePlantProductTypeMutation,
  useUpdatePlantProductTypeMutation,
} = productsApiSlice;

export const selectProductResult = productsApiSlice.endpoints.getProductsTemplate.select();

const selectProductsData = createSelector(
  selectProductResult,
  (podsResult) => podsResult.data // normalized state object with ids & entities
);

export const { selectAll: selectAllProducts, selectById: selectProductById } = productAdapter.getSelectors(
  (state) => selectProductsData(state) ?? initialState
);

// Product Active
export const selectProductActivwResult = productsApiSlice.endpoints.getProductsActive.select();

const selectProductsActiveData = createSelector(
  selectProductActivwResult,
  (podsResult) => podsResult.data // normalized state object with ids & entities
);

export const { selectAll: selectAllProductsActive, selectById: selectProductActiveById } = productAdapter.getSelectors(
  (state) => selectProductsActiveData(state) ?? initialState
);

// Product New Request
export const selectProducNewRequestResult = productsApiSlice.endpoints.getProductNewRequestsActive.select();

const selectProductsNewRequestData = createSelector(
  selectProducNewRequestResult,
  (podsResult) => podsResult.data // normalized state object with ids & entities
);

export const { selectAll: selectAllProductRequests, selectById: selectProductActiveNewRequestById } =
  productAdapter.getSelectors((state) => selectProductsNewRequestData(state) ?? initialState);

// Harvest States
export const selectHarvestStateResult = productsApiSlice.endpoints.getHarvestState.select();

const selectHarvestStateData = createSelector(
  selectHarvestStateResult,
  (result) => result.data // normalized state object with ids & entities
);

export const { selectAll: selectAllHarvestStates, selectById: selectHarvestStateById } = productAdapter.getSelectors(
  (state) => selectHarvestStateData(state) ?? initialState
);
