import { useState } from 'react';
import Modal from '../modal/modal.component';
import Button from '../button/button.component';
import Dropdown from '../dropdown/dropdown.component';
import {
  WrapperForm,
  WrapperFoto,
  WrapperInput,
  WrapperTextInput,
  WrapperContainerForm,
  WrapperButton,
  WrapperButtonEdit,
} from "./styles";
import { useAddUserMutation } from "../../store/user/user.slice";
import Spinner from "../loader/spinner.component";
import ModalConfirm from "../modal/modal-confirm.component";
import { CameraIcon, PencilIcon } from "@heroicons/react/solid";
import getBase64 from "../../utils/base64";

const FormModal = ({ isOpen, handleClose }) => {
  let inputRef;
  const [addNewUser, { isLoading }] = useAddUserMutation();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    description: "",
    typeModal: "",
    typeConfirm: "",
    isError: false,
  });

  const [payload, setPayload] = useState({
    email: "",
    password: "",
    role: "",
    name: "",
    nik: "",
    address: "",
    phoneNum: "",
    avatar: "",
  });

  const optionRoles = [
    {
      id: "admin",
      label: "Admin",
      value: "admin",
    },
    {
      id: "botanist",
      label: "Botanist",
      value: "botanist",
    },
    {
      id: "greener",
      label: "Greener",
      value: "greener",
    },
    {
      id: "guest",
      label: "Guest",
      value: "guest",
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newPayload = { ...payload, role: payload.role?.id };
      console.log("🚀 ~ file: add-modal.component.jsx:76 ~ handleSubmit ~ payload", newPayload);
      const response = await addNewUser(newPayload).unwrap();
      console.log("🚀 ~ file: add-modal.component.jsx:75 ~ handleSubmit ~ response", response);

      setModalData({
        title: "New User Added",
        description: "You’ve successfully added new user.",
        typeModal: "",
        typeConfirm: "success",
        isError: false,
      });

      setPayload({
        email: "",
        password: "",
        role: "",
        name: "",
        nik: "",
        address: "",
        phoneNum: "",
        avatar: "",
      });
      setOpenConfirm(true);
    } catch (err) {
      setModalData({
        title: "Failed",
        subTitle: "Failed create New Product",
        description: err.data.message,
        typeModal: "failed",
        typeConfirm: "failed",
        isError: true,
      });
      setTimeout(() => {
        setOpenConfirm(true);
      }, 500);
    }
  };

  const handleFileSelected = (e) => {
    const file = e.target.files && e.target.files[0];
    getBase64(file, (result) => {
      setPayload({ ...payload, avatar: result });
    });
  };

  return (
    <>
      <ModalConfirm
        isOpen={openConfirm}
        handleClose={() => {
          handleClose();
          setOpenConfirm(false);
        }}
        title={modalData.title}
        description={modalData.description}
        typeConfirm={modalData.typeConfirm}
        typeModal={modalData.type}
        isError={modalData.isError}
      />
      <Modal isOpen={isOpen} handleClose={handleClose} size="lg" title="Add User" type="success">
        <WrapperContainerForm onSubmit={handleSubmit}>
          <WrapperForm>
            <WrapperFoto className="relative bg-grey-400">
              {payload.avatar && <img src={payload.avatar} alt="img" className="h-full w-full object-contain" />}
              {!payload.avatar && <CameraIcon height={10} width={10} className="h-full w-full text-black" />}
              <WrapperButtonEdit
                className="absolute h-[30px] w-[30px] flex flex-col justify-center items-center rounded-full bg-black -bottom-0 -right-1 cursor-pointer"
                onClick={() => inputRef.click()}
              >
                <PencilIcon className="text-black" height={20} width={20} />
              </WrapperButtonEdit>
              <input
                ref={(refParam) => (inputRef = refParam)}
                type="file"
                onChange={handleFileSelected}
                hidden={true}
              />
              {/* <span>{payload.name !== "" && payload.name != null ? payload.name[0].toUpperCase() : ""}</span> */}
            </WrapperFoto>
            <WrapperInput>
              <WrapperTextInput>
                <span>Full Name</span>
                <input type="text" value={payload.name} placeholder="Input Name" name="name" onChange={handleChange} />
              </WrapperTextInput>
              <WrapperTextInput>
                <span>Role</span>
                <Dropdown
                  options={optionRoles}
                  selectedOption={payload.role}
                  handelChange={(role) => {
                    setPayload({ ...payload, role: role });
                  }}
                  placeHolder="Choose Role"
                />
              </WrapperTextInput>
              <WrapperTextInput>
                <span>E-mail</span>
                <input
                  type="email"
                  value={payload.email}
                  placeholder="Input Email"
                  name="email"
                  onChange={handleChange}
                />
              </WrapperTextInput>
              <WrapperTextInput>
                <span>NIK</span>
                <input
                  type="text"
                  value={payload.nik}
                  placeholder="Input National ID"
                  name="nik"
                  onChange={handleChange}
                />
              </WrapperTextInput>
              <WrapperTextInput>
                <span>Phone Number</span>
                <input
                  type="text"
                  value={payload.phoneNum}
                  placeholder="Input Phone Number"
                  name="phoneNum"
                  onChange={handleChange}
                />
              </WrapperTextInput>
              <WrapperTextInput>
                <span>Address</span>
                <input
                  type="text"
                  value={payload.address}
                  placeholder="Input Address"
                  name="address"
                  onChange={handleChange}
                />
              </WrapperTextInput>
              <WrapperTextInput>
                <span>Password</span>
                <input
                  type="password"
                  value={payload.password}
                  placeholder="Input Password"
                  name="password"
                  onChange={handleChange}
                />
              </WrapperTextInput>
            </WrapperInput>
          </WrapperForm>
          <WrapperButton>
            <Button variant="outlined" variants="dangerOutlined" size="lg" className="w-full" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="filled"
              variants="mainFilled"
              size="lg"
              className="w-full"
              type="submit"
              disabled={isLoading}
            >
              {isLoading && <Spinner />}
              Save
            </Button>
          </WrapperButton>
        </WrapperContainerForm>
      </Modal>
    </>
  );
};

export default FormModal;
