import { memo, useState, useEffect } from "react";
import {
  WrapperInput,
  WrapperInputText,
  WrapperFilter,
  WrapperTextInput,
} from "../styles";
import TabPage from "../tab-page";
import { SearchIcon } from "@heroicons/react/solid";
import Dropdown from "../../dropdown/dropdown.component";
import { useSelector } from "react-redux";
import { selectAllPodTypes } from "../../../store/pod-type/pod-type.slice";

const Filter = ({ filter, setFilter }) => {
  const podTypes = useSelector(selectAllPodTypes);
  const [optionPodTypes, setOptionPodTypes] = useState([]);

  useEffect(() => {
    const newOption = [{ id: "", label: "All" }];
    podTypes?.map(({ _id: id, label }) => newOption.push({ id, label }));
    setOptionPodTypes(newOption);
  }, [podTypes]);

  return (
    <WrapperFilter className="flex flex-col gap-2 md:flex-row md:justify-between items-center mt-2">
      <TabPage />
      <div className="flex flex-col md:flex-row gap-2">
        <WrapperTextInput className="md:w-56">
          <Dropdown
            options={optionPodTypes}
            selectedOption={filter?.podType}
            handelChange={(value) => setFilter({ ...filter, podType: value })}
            placeHolder="Choose Pod Type"
          />
        </WrapperTextInput>
        <WrapperInput>
          <WrapperInputText
            type="text"
            placeholder="Search..."
            onChange={(e) => setFilter({ ...filter, codeName: e.target.value })}
            value={filter.codeName}
          />
          <div className="absolute inset-y-0 right-2 flex items-center">
            <label htmlFor="currency" className="sr-only">
              Search
            </label>
            <SearchIcon height={25} width={25} className="main-color" />
          </div>
        </WrapperInput>
      </div>
    </WrapperFilter>
  );
};

export default Filter;
