import styled from 'styled-components';

export const WrapperInput = styled.div.attrs({
  className: 'relative rounded-md shadow-sm',
})``;

export const WrapperInputText = styled.input.attrs({
  className: 'block w-full',
})`
  padding: 10px 34px 10px 16px;
  width: 259px;
  height: 40px;
  background: #e2e8f0;
  border-radius: 8px;
`;

export const WrapperProductList = styled.div`
  grid-area: main;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));

  @media (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
`;

export const WrapperCardProductList = styled.div`
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const WrapperImage = styled.div`
  width: 100%;
  height: 123px;
  background: #cbd5e1;
  border-radius: 8px 8px 0px 0px;
`;

export const WrapperDescription = styled.div.attrs({ className: 'bg-white' })`
  display: flex;
  flex-direction: column;
  padding: 16px;
  text-decoration: none;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #43c667;
  }

  label {
    padding: 10px 0px;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #94a3b8;
  }
`;

export const WrapperDescriptionDtl = styled.div.attrs({
  className: 'bg-white',
})`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 1rem;
  text-decoration: none;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #43c667;
  }

  label {
    padding: 10px 0px;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #94a3b8;
  }
`;

export const WrapperPreset = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px 0px 0px;

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: none;
    color: #64748b;
  }

  p {
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #94a3b8;
  }

  .value-green {
    color: #43c667;
  }
`;

export const WrapperButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 56px;
  background: #43c667;
  border-radius: 0px 0px 8px 8px;

  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
`;

export const WrapperDetail = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapperImageDetail = styled.div`
  height: 120px;
  background: #cbd5e1;
`;

export const WrapperDescriptionDetail = styled.div.attrs({
  className: 'p-6 flex flex-col',
})`
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #94a3b8;
  }
`;

export const WrapperPresetDetail = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px 0px 0px;

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #94a3b8;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #94a3b8;
    color: #43c667;
  }
`;

export const WrapperImageCreate = styled.div`
  height: 176px;
  background: #cbd5e1;
`;

export const WrapperFormCreate = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px 4rem;
  background-color: #f8fafc;
`;

export const WrapperTextInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #94a3b8;
  }

  input {
    padding: 8px 25px 8px 20px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    width: 100%;
    color: #64748b;

    ::placeholder {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }
  }

  select {
    padding: 9px 25px 9px 20px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    width: 100%;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #64748b;
  }

  textarea {
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    padding: 12px 25px 8px 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #64748b;
  }
`;

export const WrapperButtonCreate = styled.div.attrs({
  className: 'flex flex-row w-100 justify-center items-center gap-2 pt-2 pb-4',
})``;

export const Description = styled.div.attrs({
  className: 'flex justify-between',
})`
  text-decoration: none;
  .label-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #94a3b8;
  }

  .value-desc {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #64748b;
  }

  .value-green {
    color: #43c667 !important;
  }
`;
