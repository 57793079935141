import { WrapperFilter } from "../styles";
import {
  WrapperInput,
  WrapperInputText,
  // WrapperTextInput,
} from "./product-active.styles";
import TabPage from "../tab-page";
import { SearchIcon } from "@heroicons/react/solid";
// import Dropdown from "../../dropdown/dropdown.component";

const Filter = () => {
  return (
    <WrapperFilter className="flex maxSM:flex-col maxSM:gap-3 md:justify-between items-center">
      <TabPage />
      <div className="flex flex-row justify-center items-center gap-2">
        {/* <WrapperTextInput>
          <Dropdown
            options={[]}
            selectedOption=""
            handelChange={() => {}}
            placeHolder="Choose Location"
          />
        </WrapperTextInput> */}
        <WrapperInput>
          <WrapperInputText type="text" placeholder="Search..." />
          <div className="absolute inset-y-0 right-2 flex items-center">
            <label htmlFor="currency" className="sr-only">
              search
            </label>
            <SearchIcon height={25} width={25} className="main-color" />
          </div>
        </WrapperInput>
      </div>
    </WrapperFilter>
  );
};

export default Filter;
