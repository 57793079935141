import { useState } from "react";
import { Switch } from "@headlessui/react";
import { useEffect } from "react";

export default function Toggle({ value, onChange }) {
  return (
    <Switch
      checked={!value}
      onChange={onChange}
      className={`${!value ? "bg-danger-color" : "bg-main-color"}
          relative inline-flex h-[15px] w-[28px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={`${!value ? "translate-x-3" : "translate-x-0"}
            pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </Switch>
  );
}
