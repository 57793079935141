import { Header, Status, WrapperCard } from './active-pod.styles';
import moment from 'moment';

const Card = ({ openDetail, pod }) => {
  return (
    <WrapperCard className="p-[16px] flex flex-col gap-2">
      <Header className="flex flex-col gap-2 h-[150px] w-full">
        <span>{pod.codeName}</span>
        <Status variant="greener" className="max-w-max">
          <p className="first-letter:capitalize">{pod?.podTypeLabel || '-'}</p>
        </Status>
        <hr className="text-grey-400" />
        <div className="flex flex-col justify-between h-full">
          <span className="description">
            Batch 060B21-GP-00-2-001- 00-001 is received from Pods GP-02-1-001
            to start processing
          </span>
          <span className="date">
            {moment(pod?.updatedAt).format('MMMM DD YYYY hh:mm')}
          </span>
        </div>
      </Header>
      <div className="h-[40px] flex flex-row justify-center items-center main-color cursor-pointer">
        <u>Details</u>
      </div>
    </WrapperCard>
  );
};

export default Card;
