import ReactApexChart from "react-apexcharts";

export default function RadialChart({ success }) {
  const rate = success ? success.toFixed(2) : 0;
  const data = {
    series: [rate],
    options: {
      chart: {
        height: 100,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "50%",
            background: "transparent",
          },
          track: {
            background: ["#B2F5C5", "#E1FFEA"],
          },
          dataLabels: {
            show: true,
            name: {
              show: true,
              fontSize: "14px",
              fontFamily: undefined,
              fontWeight: 600,
              color: "#ffffff",
              offsetY: 60,
            },
            value: {
              show: true,
              fontSize: "15px",
              fontFamily: undefined,
              fontWeight: 600,
              color: "#ffffff",
              offsetY: -10,
              formatter(val) {
                return `${val}%`;
              },
            },
          },
        },
      },
      fill: {
        colors: [
          function ({ value }) {
            if (value < 55) {
              return "#7E36AF";
            }
            if (value >= 55 && value < 80) {
              return "#164666";
            }
            return "#E1FFEA";
          },
        ],
      },
      // stroke: {
      //   lineCap: 'round',
      // },
      labels: ["Success Rate"],
    },
  };
  return (
    <div id="chart">
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="radialBar"
        height={150}
        width={100}
      />
    </div>
  );
}
