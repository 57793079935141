import Filter from "../../components/users/filter.component";
import ListCard from "../../components/users/list-card.component";
import { useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useGetUsersQuery } from "../../store/user/user.slice";
import FormAddModal from "../../components/users/add-modal.component";
import FormEditModal from "../../components/users/edit-modal.component";
import ManagePod from "../../components/users/manage-modal.component";

const IndexPage = () => {
  //! Reducer
  // const navigate = useNavigate();
  const { data, isLoading, isSuccess } = useGetUsersQuery();

  const [users, setUsers] = useState([]);

  //! State
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [filter, setFilter] = useState("");
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openEditModalAdd, setOpenEditModalAdd] = useState(false);
  const [openManagePod, setOpenManagePod] = useState(false);
  const [id, setId] = useState("");

  const onClickTab = (by) => {
    setFilter(by);
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      if (data) {
        setUsers(data.data);
      }
    }
  }, [isLoading, isSuccess, data]);

  useEffect(() => {
    const newFilteredMonster = users.filter((user) => {
      return user.role.toLocaleLowerCase().includes(filter);
    });

    setFilteredUsers(newFilteredMonster);
  }, [users, filter]);

  return (
    <>
      {openModalAdd && (
        <FormAddModal
          isOpen={openModalAdd}
          handleClose={() => setOpenModalAdd(false)}
          title="Add User"
        />
      )}

      {openEditModalAdd && (
        <FormEditModal
          isOpen={openEditModalAdd}
          handleClose={() => setOpenEditModalAdd(false)}
          title="Add User"
          id={id}
        />
      )}

      {openManagePod && (
        <ManagePod
          isOpen={openManagePod}
          handleClose={() => setOpenManagePod(false)}
          title="Add User"
          id={id}
        />
      )}

      <div className="flex flex-col w-100 gap-5 pb-10">
        <Filter
          onClickTab={onClickTab}
          filter={filter}
          addModal={() => setOpenModalAdd(true)}
        />
        {!isLoading && isSuccess && (
          <ListCard
            users={filteredUsers}
            onEdit={setId}
            openEdit={() => setOpenEditModalAdd(true)}
            openManage={() => setOpenManagePod(true)}
          />
        )}
      </div>
    </>
  );
};

export default IndexPage;
