import styled from "styled-components";

export const WrapperFilter = styled.div``;

export const WrapperTabPage = styled.div.attrs({
  className: "flex flex-row flex-wrap items-center gap-3",
})`
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-decoration: underline;
    color: #64748b;
    cursor: pointer;

    &.active {
      color: #43c667;
    }
  }
`;
