import { WrapperTabPage } from "./styles";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setTabProduct } from "../../store/layout/layout.slice";
import { memo } from "react";

const TabPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const tab = useSelector((state) => state.layout.tabProduct);
  const dispatch = useDispatch();

  const handleChange = (tab, url) => {
    dispatch(setTabProduct(tab));
    navigate(`/${url}`);
  };
  return (
    <WrapperTabPage className="maxSM:justify-center">
      <span
        className={`${location.pathname.includes("/products/plant-product-type") && "active"}`}
        onClick={() => handleChange("plant", "products/plant-product-type")}
      >
        Plant Product Type
      </span>
      <span
        className={`${location.pathname.includes("/products/list") && "active"}`}
        onClick={() => handleChange("list", "products/list")}
      >
        Product List
      </span>
      <span
        className={`${location.pathname.includes("/products/ordered") && "active"}`}
        onClick={() => handleChange("orderded", "products/ordered")}
      >
        Product Ordered
      </span>
      <span
        className={`${
          (location.pathname.includes("/products/active") || location.pathname.includes("/products/new-request")) &&
          "active"
        }`}
        onClick={() => handleChange("active", "products/active")}
      >
        Active Product
      </span>
    </WrapperTabPage>
  );
};

export default memo(TabPage);
