import { memo, useState, useEffect } from "react";
import Modal from "../../modal/modal.component";
import { WrapperTextInput } from "./active-pod.styles";
import Dropdown from "../../dropdown/dropdown.component";
import { useSelector } from "react-redux";
import { selectAllPodTypes } from "../../../store/pod-type/pod-type.slice";
import { selectAllPresetss } from "../../../store/presets/presets.slice";
import { useGetUsersQuery } from "../../../store/user/user.slice";
import Button from "../../button/button.component";
import ModalConfirm from "../../modal/modal-confirm.component";

const Create = ({ isOpen, handleClose }) => {
  const podTypes = useSelector(selectAllPodTypes);
  const presets = useSelector(selectAllPresetss);
  const { data: users } = useGetUsersQuery();
  const [optionPodTypes, setOptionPodTypes] = useState([]);
  const [optionPresets, setOptionPresets] = useState([]);
  const [optionUsers, setOptionUsers] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [modalData, setModalData] = useState({
    title: "GREENS Pod Added",
    description: "You’ve successfully add new GREENS Pod.",
    typeModal: "",
    typeConfirm: "success",
    isError: false,
  });

  useEffect(() => {
    const newOption = [];
    podTypes?.map(({ _id: id, label }) => newOption.push({ id, label }));
    setOptionPodTypes(newOption);
  }, [podTypes]);

  useEffect(() => {
    const newOption = [];
    presets?.map(({ _id: id, label }) => newOption.push({ id, label }));
    setOptionPresets(newOption);
  }, [presets]);

  useEffect(() => {
    const newOption = [];
    users?.data?.map(({ _id: id, name }) =>
      newOption.push({ id, label: name })
    );
    setOptionUsers(newOption);
  }, [users]);

  return (
    <>
      <ModalConfirm
        isOpen={openConfirm}
        handleClose={() => setOpenConfirm(false)}
        title={modalData.title}
        description={modalData.description}
        typeConfirm={modalData.typeConfirm}
        typeModal={modalData.type}
        isError={modalData.isError}
      />
      <Modal
        isOpen={isOpen}
        handleClose={handleClose}
        size="lg"
        type="success"
        title="Manage GREENS Pod"
        padding="p-0"
      >
        <div className="flex flex-col gap-3 bg-main-grey p-6 px-12">
          <WrapperTextInput>
            <span>GREENS Pod Type</span>
            <Dropdown
              options={optionPodTypes}
              selectedOption={""}
              handelChange={() => {}}
              placeHolder="Choose Pod Type"
            />
          </WrapperTextInput>
          <WrapperTextInput>
            <span>Presets</span>
            <Dropdown
              options={optionPresets}
              selectedOption={""}
              handelChange={() => {}}
              placeHolder="Choose Preset"
            />
          </WrapperTextInput>
          <WrapperTextInput>
            <span>Location</span>
            <Dropdown
              options={[]}
              selectedOption={""}
              handelChange={() => {}}
              placeHolder="Choose Location"
            />
          </WrapperTextInput>
          <WrapperTextInput>
            <span>Address Title</span>
            <div className="flex gap-5 w-full items-center">
              <input
                type="text"
                placeholder="Input Title and Pinpoin at the Map"
              />
              <Button
                size="lg"
                variant="filled"
                variants="mainFilled"
                type="button"
                className="w-1/5"
                onClick={() => {}}
              >
                Map
              </Button>
            </div>
          </WrapperTextInput>
          <WrapperTextInput>
            <span>Address Detail</span>
            <textarea
              defaultValue=""
              rows={6}
              placeholder="Input Address"
            ></textarea>
          </WrapperTextInput>
          <WrapperTextInput>
            <span>Managed by</span>
            <Dropdown
              options={optionUsers}
              selectedOption={""}
              handelChange={() => {}}
              placeHolder="Choose User"
            />
          </WrapperTextInput>
          <Button
            variants="mainFilled"
            size="lg"
            type="button"
            className="mt-14 mb-2"
            onClick={() => {
              handleClose();
              setOpenConfirm(true);
            }}
          >
            Save
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default memo(Create);
