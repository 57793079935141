import styled from 'styled-components';

export const WrapperList = styled.div`
  grid-area: main;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(489px, 1fr));

  @media (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
`;

export const WrapperCard = styled.div`
  height: 175px;
  background: #ffffff;
  border-radius: 8px;
  padding: 1.7rem 1.5rem;
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
`;

export const WrapperInitial = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  width: 68px;
  height: 68px;
  background: #43c667;
  border-radius: 50px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 40px;
  color: #ffffff;
`;

export const WrapperProfile = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const WrapperProfileDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5;

  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 32px;
    color: #64748b;
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #64748b;
  }
`;

export const WrapperAction = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;

  u {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #43c667;
    cursor: pointer;
  }
`;

export const WrapperContainerForm = styled.form.attrs({
  className: 'flex flex-col',
})``;

export const WrapperForm = styled.div.attrs({
  className: 'flex flex-row px-6',
})``;

export const WrapperFoto = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  height: 88px;
  width: 88px;
  min-width: 88px;
  min-height: 88px;
  margin-right: 3rem;

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
    /* identical to box height, or 143% */

    text-align: center;

    /* BW/White (0) */

    color: #ffffff;
  }
`;

export const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const WrapperTextInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #94a3b8;
  }

  input {
    padding: 8px 25px 8px 20px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    width: 100%;

    ::placeholder {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #64748b;
    }
  }

  select {
    padding: 9px 25px 9px 20px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    width: 100%;

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #64748b;
  }
`;

export const WrapperButton = styled.div.attrs({
  className: "flex flex-row w-100 justify-center items-center pt-14 mb-4 gap-5 px-6",
})``;

export const WrapperButtonEdit = styled.div`
  background-color: #b2f5c5;
`;
