import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/api";

const presetsAdapter = createEntityAdapter({
  selectId: (state) => state._id,
});

const initialState = presetsAdapter.getInitialState();

export const presetsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPresets: builder.query({
      query: () => "/pod-preset?$limit=100",
      transformResponse: (responseData) => {
        return presetsAdapter.setAll(initialState, responseData.data);
      },
      providesTags: (result, error, arg) => [
        { type: "Preset", id: "LIST" },
        // ...result.ids.map((id) => ({ type: 'Preset', id })),
      ],
    }),
    createPreset: builder.mutation({
      query: (payload) => ({
        url: `/pod-preset`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [{ type: "Preset", id: "LIST" }],
    }),
    updatePreset: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/pod-preset/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Preset", id: "LIST" },
        { type: "Preset", id: arg.id },
      ],
    }),
    deletePreset: builder.mutation({
      query: ({ id }) => ({
        url: `/pod-preset/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Preset", id: "LIST" },
        { type: "Preset", id: arg.id },
      ],
    }),
  }),
});

export const { useGetPresetsQuery, useCreatePresetMutation, useUpdatePresetMutation, useDeletePresetMutation } =
  presetsApiSlice;

export const selectPresetResult = presetsApiSlice.endpoints.getPresets.select();

const selectPresetssData = createSelector(
  selectPresetResult,
  (presetsResult) => presetsResult.data // normalized state object with ids & entities
);

export const { selectAll: selectAllPresetss, selectById: selectPresetById } = presetsAdapter.getSelectors(
  (state) => selectPresetssData(state) ?? initialState
);
