import Modal from "../../modal/modal.component";
import Button from "../../button/button.component";
import { useState, useEffect } from "react";
import { useCreatePlantMutation, useUpdatePlantMutation } from "../../../store/product/product.slice";
import Spinner from "../../loader/spinner.component";
import { WrapperFormCreate, WrapperTextInput } from "./styles";

const CreatePlantCategory = ({ isOpen, handleClose, setModalData, setOpenConfirm, category }) => {
  const [payload, setPayload] = useState({ text: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [saveCategory] = useCreatePlantMutation();
  const [updateCategory] = useUpdatePlantMutation();

  const onSaveClicked = async () => {
    setIsLoading(true);
    try {
      if (!category) {
        console.log("Create Plant with Payload : ", payload);
        console.log(`Create Plant URL POST : ${process.env.REACT_APP_API_BASE_URL}/plant-name`);
        const response = await saveCategory(payload).unwrap();
        console.log("Create Plant Response : ", response);

        setModalData({
          title: "New plant added",
          subTitle: "New plant added",
          description: "You’ve successfully added new plant.",
          typeModal: "success",
          typeConfirm: "success",
          isError: false,
        });
      } else {
        console.log("Edit Plant with Payload : ", payload);
        console.log(`Edit Plant URL POST : ${process.env.REACT_APP_API_BASE_URL}/plant-plant/${category._id}`);
        const response = await updateCategory({ id: category._id, payload }).unwrap();
        console.log("Edit Plant Response : ", response);

        setModalData({
          title: "Plant edited",
          subTitle: "Plant edited",
          description: "You’ve successfully edit the plant.",
          typeModal: "success",
          typeConfirm: "success",
          isError: false,
        });
      }

      setTimeout(() => {
        setOpenConfirm(true);
        setIsLoading(false);
        handleClose();
      }, 700);
    } catch (err) {
      console.log("Plant  Response : ", err);

      setModalData({
        title: "Failed",
        subTitle: "Failed to save plant",
        description: err.data.message,
        typeModal: "failed",
        typeConfirm: "failed",
        isError: true,
      });
      setTimeout(() => {
        setOpenConfirm(true);
        setIsLoading(false);
        handleClose();
      }, 500);
    }
  };

  useEffect(() => {
    if (category) {
      setPayload({ text: category.text });
    }
  }, [category]);

  return (
    <>
      <Modal isOpen={isOpen} handleClose={handleClose} size="md" type="success" padding="p-0" title="Create Plant">
        <WrapperFormCreate className="justify-between">
          <WrapperTextInput>
            <span>Plant name</span>
            <input
              type="text"
              value={payload.text}
              placeholder="Input plant name"
              onChange={(e) => setPayload({ ...payload, text: e.target.value })}
            />
          </WrapperTextInput>
          <div className="flex flex-row w-100 justify-center items-center gap-2 pt-2 pb-4">
            <Button
              variant="filled"
              variants="mainFilled"
              size="lg"
              className="w-full"
              type="button"
              disabled={isLoading}
              onClick={() => {
                onSaveClicked();
              }}
            >
              {isLoading && <Spinner />}
              Save
            </Button>
          </div>
        </WrapperFormCreate>
      </Modal>
    </>
  );
};

export default CreatePlantCategory;
