/* eslint-disable react-hooks/exhaustive-deps */
import Filter from "../../components/products/product-active/filter.component";
import ProductList from "../../components/products/product-active/products-list.component";
import TabsButton from "../../components/products/product-active/tabs-button.component";
import { useGetProductActivePagingQuery } from "../../store/product/product.slice";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { HARVEST_STATE } from "../../helper/harvestState";

const ProductListPage = () => {
  const now = new Date().getTime();
  const day = 3600 * 24 * 1000;
  const timestamp_ms_3days_ago = now - 3 * day;

  const [searchParams] = useSearchParams();
  const [param, setParam] = useState({
    limit: 10,
    skip: 0,
    total: 0,
    hasMore: false,
  });

  let queryString = searchParams.get("harvestState") ? `&harvestState=${searchParams.get("harvestState")}` : "";
  const queryStringHarvested =
    searchParams.get("harvestState") && searchParams.get("harvestState") === HARVEST_STATE["HARVESTED"]
      ? `${queryString}&updatedAt[$gte]=${timestamp_ms_3days_ago}`
      : queryString;

  const [query, setQuery] = useState(`?$limit=${param.limit}&$skip=${param.skip}${queryStringHarvested}`);
  const { data } = useGetProductActivePagingQuery(query, {
    refetchOnMountOrArgChange: true,
  });
  const [products, setProducts] = useState([]);

  const fetchMoreData = () => {
    setQuery(`?$limit=${param.limit}&$skip=${param.skip + 10}${queryStringHarvested}`);
    setParam({ ...param, skip: param.skip + 10 });
  };

  useEffect(() => {
    setProducts([]);
    setQuery(`?$limit=10&$skip=0${queryStringHarvested}`);
    setParam({
      limit: 10,
      skip: 0,
      total: 0,
      hasMore: true,
    });
  }, [queryString]);

  const removeProductAfterAction = (product) => {
    const newProduct = products.filter((obj) => obj._id !== product._id);
    setProducts(newProduct);
  };

  useEffect(() => {
    if (data) {
      const newProducts = [];
      data?.data?.map((obj) => newProducts.push(obj));
      const concatProduct = [...products, ...newProducts];
      const unique = [...new Map(concatProduct.map((item) => [item["_id"], item])).values()];
      setParam({ ...param, total: data.total, hasMore: unique.length < data.total });
      setProducts(unique);
    }
  }, [data]);

  return (
    <>
      <div className="flex flex-col w-100 gap-5 pb-10">
        <Filter />
        <hr className="text-grey-300" />
        <TabsButton />
        <ProductList
          products={products}
          fetchMoreData={fetchMoreData}
          param={param}
          removeProductAfterAction={removeProductAfterAction}
        />
      </div>
    </>
  );
};

export default ProductListPage;
