import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Layout from "./components/layout/layout.component";
import Dashboard from "./pages/index";
import Login from "./pages/auth/login";
import Users from "./pages/user/index";
import ProductList from "./pages/product/product-list";
import ProductActive from "./pages/product/active-product";
import ProductHistory from "./pages/product/product-history";
import ProductOrdered from "./pages/product/product-ordered";
import ProductNewRequest from "./pages/product/product-new-request";
import PlantProductType from "./pages/product/plant-product-type";
import ManagePreset from "./pages/pod/manage-presets";
import ActivePod from "./pages/pod/active-pod";
import InActivePod from "./pages/pod/inactive-pod";
import ActivityPod from "./pages/pod/activity-pod";
import Settings from "./pages/settings/index";
import { presetsApiSlice } from "./store/presets/presets.slice";
import { podTypesApiSlice } from "./store/pod-type/pod-type.slice";
import { usersApiSlice } from "./store/user/user.slice";
import { podsApiSlice } from "./store/pod/pod.slice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { productsApiSlice } from "./store/product/product.slice";
import AOS from "aos";
import "aos/dist/aos.css";
import Wrapper from "./layout/Wrapper/Wrapper";
import Location from "./pages/location";

const App = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    if (user) {
      dispatch(presetsApiSlice.endpoints.getPresets.initiate());
      dispatch(podsApiSlice.endpoints.getPods.initiate());
      dispatch(podTypesApiSlice.endpoints.getPodTypes.initiate());
      dispatch(usersApiSlice.endpoints.getUsers.initiate());
      dispatch(podsApiSlice.endpoints.getPodsByUserId.initiate());
      dispatch(productsApiSlice.endpoints.getProductsTemplate.initiate());
      dispatch(productsApiSlice.endpoints.getHarvestState.initiate());
    }
  }, [dispatch, user]);

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/location" element={<Location />} />
        <Route path="/" element={<Wrapper />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="products">
            <Route path="list" element={<ProductList />} />
            <Route path="active" element={<ProductActive />} />
            <Route path="history" element={<ProductHistory />} />
            <Route path="ordered" element={<ProductOrdered />} />
            <Route path="new-request" element={<ProductNewRequest />} />
            <Route path="plant-product-type" element={<PlantProductType />} />
          </Route>
          <Route path="pod">
            <Route path="manage-presets" element={<ManagePreset />} />
            <Route path="active-pods" element={<ActivePod />} />
            <Route path="inactive-pods" element={<InActivePod />} />
            <Route path="activity-pods" element={<ActivityPod />} />
          </Route>
          <Route path="settings" element={<Settings />} />
        </Route>
        <Route path="*" element={<div>Page not found</div>} />
      </Routes>
    </Router>
  );
};

export default App;
