import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../lib/axios";
// import { parseCookies, setCookie, destroyCookie } from "nookies";
import Cookies from "universal-cookie";

// Get user from localStorage
// const { "greensGarden.User": user } = parseCookies();
const cookies = new Cookies();
const user = cookies.get("greensGarden.User");

const initialState = {
  user: user ? user : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Login user
export const login = createAsyncThunk(
  "auth/login",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post("/authentication", payload);
      if (data.accessToken) {
        const userLogin = JSON.stringify(data.user);
        cookies.set("greensGarden.User", userLogin, {
          maxAge: 30 * 24 * 60 * 60,
          path: "/",
        });
        cookies.set("greensGarden.Token", data.accessToken, {
          maxAge: 30 * 24 * 60 * 60,
          path: "/",
        });
      }
      return data.user;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  cookies.remove("greensGarden.User", {
    path: "/",
  });
  cookies.remove("greensGarden.Token", {
    path: "/",
  });
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
      });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
