import { useState, useEffect } from "react";
import { Address, Header, Notif, Status, Tab, TabButton, WrapperCard } from "./active-pod.styles";
import { useSelector } from "react-redux";
import { selectAllUserPods, useUpdatePodMutation } from "../../../store/pod/pod.slice";
import { setBanner } from "../../../helper/setBanner";
import Toogle from "../../button/toggle.component";
import CameraSvg from "../../../assets/img/camera.svg";
import LocationSvg from "../../../assets/img/location-info.svg";
import TabSvg from "../../../assets/img/tab.svg";
import TabOffSvg from "../../../assets/img/tab-off.svg";

import Environmnet from "./environment.component";
import Product from "./product.component";
import SimpleDateTime from "react-simple-timestamp-to-date";

const Card = ({ openDetail, pod }) => {
  const [updatePod] = useUpdatePodMutation();
  const [tab, setTab] = useState(true);
  const users = useSelector(selectAllUserPods);
  const [userPod, setUserPod] = useState("");

  useEffect(() => {
    if (users.length > 0) {
      const findUser = users.find((obj) => obj.pod?.codeName === pod.codeName);
      if (findUser) {
        setUserPod(findUser?.user?.name || "");
      }
    }
  }, [pod, users]);

  const handleVisible = async () => {
    try {
      await updatePod({
        id: pod._id,
        payload: {
          visible: !pod?.visible,
        },
      }).unwrap();
    } catch (error) {}
  };

  return (
    <WrapperCard>
      <Header>
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-1 ">
            <div className="flex flex-row gap-2 items-center">
              <span>{pod?.codeName || "-"}</span>
              <Status variant="greener">
                <p className="first-letter:capitalize">{pod?.podTypeLabel || "-"}</p>
              </Status>
              <Toogle value={pod?.visible} onChange={() => handleVisible()} />
            </div>
            <span className={`heartbeat ${pod?.visible ? "main-color" : "danger-color"}`}>
              <SimpleDateTime dateFormat="DMY" dateSeparator="-" timeSeparator=":">
                {pod?.heartbeatTimestamp}
              </SimpleDateTime>
              {/* {Moment(pod?.heartbeatTimestamp).format('DD MMMM YYYY hh:mm:ss')} */}
            </span>
          </div>

          <img src={CameraSvg} alt="icon" className="cursor-pointer" />
        </div>
      </Header>
      <hr className="text-grey-300" />
      <Address className="flex justify-between h-100">
        <div className="flex flex-col gap-1 w-4/5">
          <div className="flex flex-row gap-1">
            <span className="value-orange">Garden</span>
            <span className="">-</span>
            <span className="">{userPod}</span>
          </div>
          <span className="value-address pr-4">{pod?.address || "-"}</span>
        </div>
        <div className="flex flex-col justify-center items-center h-100">
          <a href={`https://maps.google.com?q=${pod?.coordinate}`} target="_blank" rel="noopener noreferrer">
            <img src={LocationSvg} alt="icon" className="cursor-pointer" height={20} width={20} />
          </a>
        </div>
      </Address>
      <Notif
        // color="yellow"
        className={`flex flex-row justify-center items-center bg-banner-${setBanner(pod.indicator)}`}
      >
        <span></span>
        {/* <span>Batches are in proccess.</span> */}
      </Notif>
      <Tab className="flex flex-row justify-center items-center">
        <TabButton className="h-[32px] w-[291px] flex flex-row justify-center items-center p-[4px] gap-1">
          <div
            className={`flex flex-row ${
              tab ? "bg-white" : "bg-transparent"
            } rounded-[20px] w-[148px] justify-center items-center py-[4px] px-[8px] h-full gap-2 cursor-pointer`}
            onClick={() => setTab(!tab)}
          >
            <img src={tab ? TabSvg : TabOffSvg} alt="icon" />
            <span>Environment</span>
          </div>
          <div
            className={`flex flex-row  ${
              !tab ? "bg-white" : "bg-transparent"
            } rounded-[20px] w-[148px] justify-center items-center py-[4px] px-[8px] h-full gap-2 cursor-pointer`}
            onClick={() => setTab(!tab)}
          >
            <img src={!tab ? TabSvg : TabOffSvg} alt="icon" />
            <span>Products</span>
          </div>
        </TabButton>
      </Tab>
      {tab && <Environmnet openDetail={openDetail} pod={pod} />}
      {!tab && <Product pod={pod} openDetail={openDetail} />}
    </WrapperCard>
  );
};

export default Card;
