import ModalCreate from "./create.component";
import Button from "../../button/button.component";
import { useState, memo } from "react";
import { Link } from "react-router-dom";

const TabsButton = () => {
  // const [tab, setTab] = useState("");
  const [openCreate, setOpenCreate] = useState(false);
  // const tabList = [
  //   {
  //     id: "garden",
  //     label: "Garden",
  //   },
  //   {
  //     id: "outlet",
  //     label: "Outlet",
  //   },
  //   {
  //     id: "processing",
  //     label: "Processing",
  //   },
  //   {
  //     id: "harvest",
  //     label: "Harvest",
  //   },
  //   {
  //     id: "overripe",
  //     label: "Overripe",
  //   },
  //   {
  //     id: "failed",
  //     label: "Failed",
  //   },
  //   {
  //     id: "inactive",
  //     label: "Inactive",
  //   },
  //   {
  //     id: "",
  //     label: "Clear All",
  //   },
  // ];

  return (
    <>
      <ModalCreate isOpen={openCreate} handleClose={() => setOpenCreate(false)} />
      <div className="flex justify-end w-100">
        <div className="flex w-100 gap-2">
          <Link to="/location">
            <Button variant="outlined" variants="mainFilled" size="md" className="flex justify-center items-center">
              Garden Locations
            </Button>
          </Link>
          <Button
            variant="outlined"
            variants="mainFilled"
            size="md"
            className="flex justify-center items-center"
            onClick={() => setOpenCreate(true)}
          >
            Add New Pod
          </Button>
        </div>
      </div>
    </>
  );
};

export default memo(TabsButton);
