import { useState, memo } from "react";
import Card from "./card.component";
import ModalDetail from "./detail.component";
import ModalForm from "./new-request/form.component";
import ModalConfirm from "./new-request/confirm.component";
import ModalGarmination from "./garmination/detail.component";
import ModalGarminationConfirm from "./garmination/confirm.component";
import ModalGarminationChoice from "./garmination/choice.component";
import ModalGarminationSeedling from "./garmination/seedling.component";
import ModalGarminationEnRoute from "./garmination/enroute.component";
import ModalSeedlingDetail from "./seedling/detail.component ";
import ModalSeedlingConfirm from "./seedling/confirm.component";
import ModalEnrouteDetail from "./enroute/detail.component ";
import ModalEnrouteScan from "./enroute/scan.component";
import ModalProcessingDetail from "./processing/detail.component ";
import ModalProcessingForm from "./processing/form.component";
import ModalHarvestDetail from "./harvest/detail.component ";
import ModalHarvestConfirm from "./harvest/confirm.component";
import ModalHarvestForm from "./harvest/form.component";
import ModalOverripeDetail from "./overripe/detail.component ";
import ModalWastedDetail from "./wasted/detail.component ";
import ModalWastedForm from "./wasted/form.component";
import { WrapperProductList } from "./product-active.styles";
import InfiniteScroll from "react-infinite-scroll-component";
import Bounce from "../../loader/bounce.component";

const ListProduct = ({ products, fetchMoreData, param, removeProductAfterAction }) => {
  const [id, setId] = useState("");
  const [openDetail, setOpenDetail] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [typeModal, setTypeModal] = useState("newrequest-confirm");
  const [modalData, setModalData] = useState({});
  const [product, setProduct] = useState(null);

  return (
    <>
      <ModalDetail isOpen={openDetail} handleClose={() => setOpenDetail(false)} id={id} product={product} />

      {typeModal === "newrequest" && (
        <ModalForm
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          setTypeModal={() => setTypeModal("confirm-success")}
        />
      )}

      {typeModal === "confirm-success" && (
        <ModalConfirm
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          modalData={modalData}
          product={product}
        />
      )}

      {typeModal === "confirm-failed" && (
        <ModalConfirm
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          type="failed"
          modalData={modalData}
          product={product}
        />
      )}

      {typeModal === "germinating" && (
        <ModalGarmination
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          setTypeModal={() => setTypeModal("germinating-confirm")}
          product={product}
        />
      )}

      {typeModal === "germinating-confirm" && (
        <ModalGarminationConfirm
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          setTypeModal={setTypeModal}
          setModalData={setModalData}
          product={product}
        />
      )}

      {typeModal === "germinating-choice" && (
        <ModalGarminationChoice
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          setTypeModal={setTypeModal}
          product={product}
        />
      )}

      {typeModal === "germinating-seedling" && (
        <ModalGarminationSeedling
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          setTypeModal={setTypeModal}
          setModalData={setModalData}
          product={product}
          removeProductAfterAction={() => removeProductAfterAction(product)}
        />
      )}

      {typeModal === "germinating-enroute" && (
        <ModalGarminationEnRoute
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          setTypeModal={setTypeModal}
          setModalData={setModalData}
          product={product}
          removeProductAfterAction={() => removeProductAfterAction(product)}
        />
      )}

      {typeModal === "seedling" && (
        <ModalSeedlingDetail
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          setTypeModal={setTypeModal}
          product={product}
        />
      )}

      {typeModal === "seedling-confirm" && (
        <ModalSeedlingConfirm
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          setTypeModal={setTypeModal}
          setModalData={setModalData}
          product={product}
        />
      )}

      {typeModal === "enroute" && (
        <ModalEnrouteDetail
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          setTypeModal={setTypeModal}
          product={product}
        />
      )}

      {typeModal === "enroute-scan" && (
        <ModalEnrouteScan
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          setTypeModal={setTypeModal}
          product={product}
          setModalData={setModalData}
          removeProductAfterAction={() => removeProductAfterAction(product)}
        />
      )}

      {(typeModal === "processing" || typeModal === "") && (
        <ModalProcessingDetail
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          setTypeModal={setTypeModal}
          product={product}
        />
      )}

      {typeModal === "processing-harvest" && (
        <ModalProcessingForm
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          setTypeModal={setTypeModal}
          setModalData={setModalData}
          product={product}
          removeProductAfterAction={() => removeProductAfterAction(product)}
        />
      )}

      {typeModal === "harvested" && (
        <ModalHarvestDetail
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          setTypeModal={setTypeModal}
          product={product}
        />
      )}

      {typeModal === "harvested-confirm" && (
        <ModalHarvestConfirm
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          setTypeModal={setTypeModal}
          product={product}
          // handlePost={onUpdateStateProductClicked}
        />
      )}

      {typeModal === "harvested-form" && (
        <ModalHarvestForm
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          setTypeModal={setTypeModal}
          setModalData={setModalData}
          product={product}
          removeProductAfterAction={() => removeProductAfterAction(product)}
        />
      )}

      {typeModal === "overriped" && (
        <ModalOverripeDetail
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          setTypeModal={setTypeModal}
          product={product}
        />
      )}

      {typeModal === "wasted" && (
        <ModalWastedDetail
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          setTypeModal={setTypeModal}
          setModalData={setModalData}
          product={product}
        />
      )}

      {typeModal === "wasted-form" && (
        <ModalWastedForm
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          setTypeModal={setTypeModal}
          setModalData={setModalData}
          product={product}
          removeProductAfterAction={() => removeProductAfterAction(product)}
        />
      )}
      <em className="text-total-data">
        Showing {products.length} out of {param.total}
      </em>
      <InfiniteScroll
        dataLength={products.length}
        next={fetchMoreData}
        hasMore={param.hasMore}
        loader={
          <div className="flex flex-row w-100 justify-center items-center mt-10">
            <Bounce />
          </div>
        }
        scrollableTarget="scrollableDiv"
      >
        <WrapperProductList>
          {products?.map((product, index) => (
            <Card
              key={index}
              product={product}
              openDetail={() => {
                setProduct(product);
                setOpenDetail(true);
              }}
              openEdit={() => {
                setProduct(product);
                setOpenEdit(true);
              }}
              setTypeModal={setTypeModal}
              setId={setId}
            />
          ))}
        </WrapperProductList>
      </InfiniteScroll>
    </>
  );
};

export default memo(ListProduct);
