import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../api/api";

const dashboardsAdapter = createEntityAdapter({
  selectId: (state) => state.activePods,
});

const initialState = dashboardsAdapter.getInitialState();

export const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllData: builder.query({
      query: () => `/garden-summary`,
      transformResponse: (responseData) => {
        return dashboardsAdapter.setOne(initialState, responseData);
      },
      providesTags: (result, error, arg) => [
        { type: "Dashboard", id: "LIST" },
        ...result.ids.map((id) => ({ type: "Dashboard", id })),
      ],
    }),
  }),
});

export const { useGetAllDataQuery } = dashboardApiSlice;

export const selectAllResult = dashboardApiSlice.endpoints.getAllData.select();

const selectAllData = createSelector(
  selectAllResult,
  (allResult) => allResult.data // normalized state object with ids & entities
);

export const { selectAll, selectById } = dashboardsAdapter.getSelectors(
  (state) => selectAllData(state) ?? initialState
);
