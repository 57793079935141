import { WrapperChartDonut } from "./dashboard.styles";

const CardCounting = ({ icon, value, title }) => {
  return (
    <WrapperChartDonut className="rounded-[10px] h-[160px] p-3 flex flex-col gap-3">
      <img src={icon} alt="icon.png" width={25} height={25} />
      <div className="flex flex-col">
        <h3 className="m-0 p-0">{value}</h3>
        <small className="batch">
          <b>batch</b>
        </small>
      </div>
      <small className="h-[32px] title">
        <b>{title}</b>
      </small>
    </WrapperChartDonut>
  );
};

export default CardCounting;
