import Card from './card.component';
import { WrapperList } from './styles';

const ListCard = ({ users, onEdit, openEdit, openManage }) => {
  return (
    <WrapperList>
      {users.map((user, index) => (
        <Card
          user={user}
          key={index}
          onEdit={onEdit}
          openEdit={openEdit}
          openManage={openManage}
        />
      ))}
    </WrapperList>
  );
};
export default ListCard;
