import Modal from "../../../modal/modal.component";
import Button from "../../../button/button.component";
import { useSelector } from "react-redux";
import { selectProductActiveById } from "../../../../store/product/product.slice";
import { WrapperConfirm, WrapperConfirmButton, WrappertConfirmImage } from "./harvest.styles";

const Confirm = ({ image, title, description, handleClose, isOpen, setTypeModal, id }) => {
  const product = useSelector((state) => selectProductActiveById(state, id));
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} type="success" size="md" padding="p-0" title="Test">
      <div className="flex flex-col w-100 rounded-b-[18px] overflow-hidden">
        <WrapperConfirm>
          <WrappertConfirmImage />
          <span>{product?.productName}</span>
          <p className="px-4">Batch {product?.codeBatch}</p>
          <p>Harvest status</p>
        </WrapperConfirm>
        <WrapperConfirmButton>
          <Button
            variants="dangerOutlined"
            size="lg"
            type="button"
            onClick={() => setTypeModal("confirm-failed")}
            className="w-full"
          >
            Failed
          </Button>
          <Button
            variants="mainFilled"
            size="lg"
            type="button"
            onClick={() => setTypeModal("harvested-form")}
            className="w-full"
          >
            Success
          </Button>
        </WrapperConfirmButton>
      </div>
    </Modal>
  );
};

export default Confirm;
