import Modal from "../../../modal/modal.component";
import Button from "../../../button/button.component";
import { WrapperConfirm, WrapperConfirmButton, WrappertConfirmImage } from "./garmination.styles";
import { useSelector } from "react-redux";
import { selectProductActiveById } from "../../../../store/product/product.slice";

const Confirm = ({ image, title, description, handleClose, isOpen, setTypeModal, setModalData, id, product }) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      type="success"
      size="md"
      padding="p-0"
      title={product?.productName}
    >
      <div className="flex flex-col w-100 rounded-b-[18px] overflow-hidden">
        <WrapperConfirm>
          <WrappertConfirmImage className="relative">
            {product?.imageLink && <img src={product?.imageLink} alt="img" className="h-full w-full object-contain" />}
          </WrappertConfirmImage>
          <span>{product?.productName}</span>
          <p className="px-4">Batch {product?.codeBatch}</p>
          <p>Garminating process status</p>
        </WrapperConfirm>
        <WrapperConfirmButton>
          <Button
            variants="dangerOutlined"
            size="lg"
            type="button"
            onClick={() => {
              setTypeModal("wasted-form");
              setModalData({
                description: `Garminating process is failed, please start over`,
              });
            }}
            className="w-full"
          >
            Failed
          </Button>
          <Button
            variants="mainFilled"
            size="lg"
            type="button"
            onClick={() => setTypeModal("germinating-choice")}
            className="w-full"
          >
            Success
          </Button>
        </WrapperConfirmButton>
      </div>
    </Modal>
  );
};

export default Confirm;
