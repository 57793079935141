import Modal from "../../../modal/modal.component";
import Button from "../../../button/button.component";
import { WrapperConfirm, WrapperConfirmButton, WrappertConfirmImage } from "./garmination.styles";

const Choice = ({ image, title, description, handleClose, id, isOpen, setTypeModal, product }) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      type="success"
      size="md"
      padding="p-0"
      title={product?.productName}
    >
      <div className="flex flex-col w-100 rounded-b-[18px] overflow-hidden">
        <WrapperConfirm>
          <WrappertConfirmImage className="relative">
            {product?.imageLink && <img src={product?.imageLink} alt="img" className="h-full w-full object-contain" />}
          </WrappertConfirmImage>
          <span>{product?.productName}</span>
          <p>{product?.codeBatch}</p>
          <p className="px-14">Garminating process is done please choose the next step.</p>
        </WrapperConfirm>
        <WrapperConfirmButton>
          <Button
            variants="mainOutlined"
            size="lg"
            type="button"
            onClick={() => setTypeModal("germinating-seedling")}
            className="w-full"
          >
            Seedling
          </Button>
          <Button
            variants="mainFilled"
            size="lg"
            type="button"
            onClick={() => setTypeModal("germinating-enroute")}
            className="w-full"
          >
            En Route
          </Button>
        </WrapperConfirmButton>
      </div>
    </Modal>
  );
};

export default Choice;
