import Filter from "../../components/pod/manage-presets/filter.component";
import ButtonTabs from "../../components/pod/manage-presets/tabs-button.component";
import ListPresets from "../../components/pod/manage-presets/list-presets.component";
import { selectAllPresetss } from "../../store/presets/presets.slice";
import { useSelector } from "react-redux";

const ManagePodPage = () => {
  const presets = useSelector(selectAllPresetss);
  return (
    <div className="flex flex-col w-100 gap-5 pb-10">
      <Filter />
      <hr className="text-grey-300" />
      <ButtonTabs />
      <ListPresets presets={presets} />
    </div>
  );
};

export default ManagePodPage;
