import Modal from "./modal.component";
import { WrapperConfirm, WrapperIcons, WrapperButtonConfirm } from "./styles";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import Button from "../button/button.component";
import Spinner from "../loader/spinner.component";

const ModalConfirm = ({
  isOpen,
  handleClose,
  title,
  description,
  typeConfirm,
  typeModal,
  isError,
  withAction,
  handleSave,
  isLoading,
  titleButton = "",
}) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} size="sm" type={typeModal} title={title}>
      <WrapperConfirm>
        {!withAction && (
          <>
            <WrapperIcons type={typeConfirm} className="mt-3 mb-2">
              {typeConfirm === "success" && <CheckIcon height={40} width={40} className="main-color" />}
              {typeConfirm === "failed" && <XIcon height={40} width={40} className="danger-color" />}
            </WrapperIcons>

            <span className="my-4">{title}</span>
          </>
        )}

        <p className={`${withAction ? "mt-14 mb-3 px-10" : "mb-14 px-7"}`}>{description}</p>
        {withAction && (
          <WrapperButtonConfirm className="w-full px-5">
            <Button
              variant="outlined"
              variants={`${typeConfirm === "success" ? "mainOutlined" : "dangerOutlined"}`}
              size="lg"
              className="w-full"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="filled"
              variants={`${typeConfirm === "success" ? "mainFilled" : "dangerFilled"}`}
              size="lg"
              className="w-full"
              type="button"
              disabled={isLoading}
              onClick={handleSave}
            >
              {isLoading && <Spinner />}
              {title === "Edit Product" ? "Save" : titleButton ? titleButton : "Delete"}
            </Button>
          </WrapperButtonConfirm>
        )}
      </WrapperConfirm>
    </Modal>
  );
};

export default ModalConfirm;
