import { WrapperListPod } from "../styles";
import Card from "./card.component";
import ModalDetail from "./details.component";
import { useState } from "react";
import ModalEdit from "./create.component";
import InfiniteScroll from "react-infinite-scroller";

const ListPods = ({ pods, setFilter, filter }) => {
  const [id, setId] = useState("");
  const [pod, setPod] = useState({});
  const [openDetail, setOpenDetail] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const loadMore = () => {
    setHasMore(true);
    setFilter({ ...filter, skip: skip + 10 });
    setSkip(setSkip);
    setHasMore(false);
  };

  return (
    <>
      {openDetail && (
        <ModalDetail
          isOpen={openDetail}
          handleClose={() => setOpenDetail(false)}
          id={id}
          openEdit={() => {
            setOpenDetail(false);
            setOpenEdit(true);
          }}
        />
      )}

      {openEdit && (
        <ModalEdit
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          pod={pod}
        />
      )}

      {/* <InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        hasMore={hasMore}
        loader={
          <div
            className="w-100 flex flex-row justify-center items-center"
            key={0}
          >
            <div className="loader-spin"></div>
          </div>
        }
      > */}
      <WrapperListPod>
        {pods?.map((pod) => (
          <Card
            key={pod?._id}
            openDetail={() => {
              setId(pod?._id);
              setOpenDetail(true);
              setPod(pod);
            }}
            pod={pod}
          />
        ))}
      </WrapperListPod>
      {/* </InfiniteScroll> */}
    </>
  );
};

export default ListPods;
