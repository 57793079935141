import styled from "styled-components";

export const WrapperTextInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #64748b;
  }

  input {
    padding: 12px 0px 12px 10.5px;
    /* background: #e2e8f0; */
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    width: 100%;
    color: #64748b;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    &::placeholder {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }
  }

  button {
    padding: 9px 40px 9px 20px;
    background: #e2e8f0;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    width: 100%;
    height: 40px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #64748b;
  }

  textarea {
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    padding: 12px 25px 8px 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #64748b;
  }
`;

export const WrapperTable = styled.table`
  .span {
    color: #64748b;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
`;
