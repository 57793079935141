import { memo, useState, useEffect } from "react";
import Modal from "../../modal/modal.component";
import { WrapperTextInput } from "./active-pod.styles";
import Dropdown from "../../dropdown/dropdown.component";
import AutoComplete from "../../dropdown/autocomplete.component";
import { useSelector } from "react-redux";
import { selectAllPodTypes } from "../../../store/pod-type/pod-type.slice";
import { selectAllPresetss } from "../../../store/presets/presets.slice";
import { useGetUsersQuery } from "../../../store/user/user.slice";
import { useUpdatePodMutation, useCreatePodMutation } from "../../../store/pod/pod.slice";
import Button from "../../button/button.component";
import ModalConfirm from "../../modal/modal-confirm.component";
import Spinner from "../../loader/spinner.component";
// import { useDebounce } from "../../../helper/useDebounce";

const Create = ({ isOpen, handleClose, pod = null }) => {
  const [updatePod] = useUpdatePodMutation();
  const [createPod] = useCreatePodMutation();
  const { data: users } = useGetUsersQuery();

  const podTypes = useSelector(selectAllPodTypes);
  const presets = useSelector(selectAllPresetss);

  const [optionPodTypes, setOptionPodTypes] = useState([]);
  const [optionPresets, setOptionPresets] = useState([]);
  const [optionUsers, setOptionUsers] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [query, setQuery] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const [modalData, setModalData] = useState({
    title: "GREENS Pod Added",
    description: "You’ve successfully add new GREENS Pod.",
    typeModal: "",
    typeConfirm: "success",
    isError: false,
  });
  const [payload, setPayload] = useState({
    codeName: "",
    podType: "",
    preset: "",
    coordinate: "",
    address: "",
    lat: "",
    lng: "",
    mapLink: "",
    assignedUserId: "",
  });

  const handleCreate = async () => {
    setIsFetching(true);
    try {
      const newPayload = {
        ...payload,
        preset: payload.preset.id,
        podType: payload.podType.id,
        coordinate: `${payload.lat}, ${payload.lng}`,
        assignedUserId: payload.assignedUserId.id,
      };

      await createPod(newPayload).unwrap();

      setModalData({
        title: "GREENS Pod Created",
        description: "You’ve successfully create GREENS Pod.",
        typeModal: "",
        typeConfirm: "success",
      });

      setTimeout(() => {
        setIsFetching(false);
        setOpenConfirm(true);
      }, 1000);
    } catch (error) {
      setModalData({
        title: error?.data?.name,
        description: error?.data?.message,
        typeModal: "failed",
        typeConfirm: "failed",
        isError: true,
      });
      setOpenConfirm(true);
    }
  };

  const handleEdit = async () => {
    setIsFetching(true);
    try {
      const newPayload = {
        ...payload,
        preset: payload.preset.id,
        podType: payload.podType.id,
        coordinate: `${payload.lat}, ${payload.lng}`,
        assignedUserId: payload.assignedUserId.id,
      };

      await updatePod({
        id: pod._id,
        payload: newPayload,
      }).unwrap();

      setModalData({
        title: "GREENS Pod Edited",
        description: "You’ve successfully edit GREENS Pod.",
        typeModal: "",
        typeConfirm: "success",
      });

      setTimeout(() => {
        setIsFetching(false);
        setOpenConfirm(true);
      }, 1000);
    } catch (error) {
      setModalData({
        title: error?.data?.name,
        description: error?.data?.message,
        typeModal: "failed",
        typeConfirm: "failed",
        isError: true,
      });
      setOpenConfirm(true);
    }
  };

  useEffect(() => {
    const newOption = [];
    podTypes?.map(({ _id: id, label }) => newOption.push({ id, label }));
    setOptionPodTypes(newOption);
  }, [podTypes]);

  useEffect(() => {
    if (pod) {
      const findPreset = presets.find((obj) => obj._id === pod.preset);
      const findPodType = podTypes.find((obj) => obj._id === pod.podType);

      setPayload({
        codeName: pod.codeName,
        address: pod.address,
        preset: findPreset
          ? { id: findPreset._id, label: findPreset.label }
          : { id: presets[0]._id, label: presets[0].label },
        coordinate: pod.coordinate,
        podType: findPodType
          ? { id: findPodType._id, label: findPodType.label }
          : { id: podTypes[0]._id, label: podTypes[0].label },
        lat: pod.coordinate?.split(", ")[0],
        lng: pod.coordinate?.split(", ")[1],
        mapLink: pod.mapLink ? pod.mapLink : "",
        assignedUserId: pod.assignedUsers
          ? pod.assignedUsers.length
            ? {
                id: pod.assignedUsers[0].userId,
                name: pod.assignedUsers[0].user?.name,
              }
            : ""
          : "",
      });
    }
  }, [pod, presets, podTypes]);

  useEffect(() => {
    const newOption = [];
    presets?.map(({ _id: id, label }) => newOption.push({ id, label }));
    setOptionPresets(newOption);
  }, [presets]);

  useEffect(() => {
    const newOption = [];
    users?.data?.map(({ _id: id, name }) => newOption.push({ id, name }));
    setOptionUsers(newOption);
  }, [users]);

  return (
    <>
      <ModalConfirm
        isOpen={openConfirm}
        handleClose={() => {
          handleClose();
          setOpenConfirm(false);
        }}
        title={modalData.title}
        description={modalData.description}
        typeConfirm={modalData.typeConfirm}
        typeModal={modalData.type}
        isError={modalData.isError}
      />
      <Modal
        isOpen={isOpen}
        handleClose={handleClose}
        size="lg"
        type="success"
        title={pod ? `Manage Pod ${pod.codeName}` : "Manage GREENS Pod"}
        padding="p-0"
      >
        <div className="flex flex-col gap-3 bg-main-grey p-6 px-12 rounded-b-[18px] ">
          <WrapperTextInput>
            <span>Code Name</span>
            <input
              type="text"
              placeholder="Input Code Name"
              value={payload.codeName}
              onChange={(e) => setPayload({ ...payload, codeName: e.target.value })}
              disabled={pod?._id ? true : false}
            />
          </WrapperTextInput>
          <WrapperTextInput>
            <span>GREENS Pod Type</span>
            <Dropdown
              options={optionPodTypes}
              selectedOption={payload.podType}
              handelChange={(val) => setPayload({ ...payload, podType: val })}
              placeHolder="Choose Pod Type"
            />
          </WrapperTextInput>
          <WrapperTextInput>
            <span>Presets</span>
            <Dropdown
              options={optionPresets}
              selectedOption={payload.preset}
              handelChange={(val) => setPayload({ ...payload, preset: val })}
              placeHolder="Choose Preset"
            />
          </WrapperTextInput>
          <div className="flex flex-row w-100 gap-3">
            <WrapperTextInput>
              <span>Latitude</span>
              <input
                type="text"
                placeholder="Input Latitude"
                value={payload.lat}
                onChange={(e) => setPayload({ ...payload, lat: e.target.value })}
              />
            </WrapperTextInput>
            <WrapperTextInput>
              <span>Longitude</span>
              <input
                type="text"
                placeholder="Input Longitude"
                value={payload.lng}
                onChange={(e) => setPayload({ ...payload, lng: e.target.value })}
              />
            </WrapperTextInput>
          </div>

          <WrapperTextInput>
            <span>Address Title</span>
            <div className="flex gap-5 w-full items-center">
              <input
                type="text"
                placeholder="Input Title and Pinpoin at the Map"
                value={payload.mapLink}
                onChange={(e) => setPayload({ ...payload, mapLink: e.target.value })}
              />
              <a
                href={
                  pod !== null
                    ? pod.mapLink
                      ? pod.mapLink
                      : `https://maps.google.com?q=${pod?.coordinate}`
                    : "https://www.google.co.id/maps/"
                }
                target="_blank"
                rel="noreferrer noopener"
                className="w-100"
              >
                <Button
                  size="lg"
                  variant="filled"
                  variants="mainFilled"
                  type="button"
                  className="w-100"
                  onClick={() => {}}
                >
                  Map
                </Button>
              </a>
            </div>
          </WrapperTextInput>
          <WrapperTextInput>
            <span>Address Detail</span>
            <textarea
              defaultValue={payload.address}
              rows={6}
              placeholder="Input Address"
              onChange={(e) => setPayload({ ...payload, address: e.target.value })}
            ></textarea>
          </WrapperTextInput>
          <WrapperTextInput>
            <span>Managed by</span>
            <AutoComplete
              options={optionUsers}
              selected={payload.assignedUserId}
              onChange={(val) => {
                setPayload({ ...payload, assignedUserId: val });
              }}
              query={query}
              onChangeQuery={setQuery}
            />
          </WrapperTextInput>
          <Button
            variants="mainFilled"
            size="lg"
            type="button"
            className="mt-14 mb-2"
            onClick={() => {
              if (pod === null) {
                handleCreate();
              } else {
                handleEdit();
              }
            }}
          >
            {isFetching && <Spinner />}
            Save
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default memo(Create);
