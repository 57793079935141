import Button from "../../button/button.component";
import { WrapperTable } from "./styles";
import { useGetPlantNameQuery } from "../../../store/product/product.slice";
import { useEffect, useState } from "react";
import ModalCreate from "./create-plant-name.component";
import ModalConfirm from "../../modal/modal-confirm.component";

const PlantName = () => {
  const { data } = useGetPlantNameQuery();
  const [list, setList] = useState([]);
  const [openCreate, setOpenCreate] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [category, setCategory] = useState(false);

  useEffect(() => {
    if (data) {
      const newList = [];
      data.data.map((obj) => newList.push(obj));
      setList(newList);
    }
  }, [data]);

  return (
    <>
      {openCreate && (
        <ModalCreate
          isOpen={openCreate}
          handleClose={() => setOpenCreate(false)}
          category={category}
          setModalData={setModalData}
          setOpenConfirm={setOpenConfirm}
        />
      )}

      {openConfirm && (
        <ModalConfirm
          isOpen={openConfirm}
          handleClose={() => {
            setOpenConfirm(false);
          }}
          title={modalData.title}
          description={modalData.description}
          typeConfirm={modalData.typeConfirm}
          typeModal={modalData.type}
          isError={modalData.isError}
        />
      )}

      <div className="flex flex-col gap-3 pl-10 w-full">
        <div className="flex justify-end">
          <Button
            variant="outlined"
            variants="mainFilled"
            size="md"
            className="flex justify-center items-center whitespace-nowrap"
            onClick={() => {
              setCategory(null);
              setOpenCreate(true);
            }}
          >
            Create Plant
          </Button>
        </div>
        <WrapperTable className="w-full h-96 overflow-auto">
          <table className="table-fixed border-spacing-2 border-separate overflow-scroll w-full">
            <thead>
              <tr>
                <th className="span w-8" align="left">
                  #
                </th>
                <th className="span" align="left">
                  Plant Name
                </th>
                <th className="w-12">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {list.map((obj, index) => {
                return (
                  <tr key={obj._id}>
                    <td className="code-name">{index + 1}</td>
                    <td className="code-name">{obj.text}</td>
                    <td>
                      <Button
                        variants="mainOutlined"
                        size="sm"
                        type="button"
                        onClick={() => {
                          setCategory(obj);
                          setOpenCreate(true);
                        }}
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </WrapperTable>
      </div>
    </>
  );
};

export default PlantName;
