import { useState } from 'react';
import Card from './card.component';
import ModalDetail from './detail.component';
import { WrapperProductList } from './product-history.styles';

const ListProduct = ({ products }) => {
  const [id, setId] = useState('');
  const [openDetail, setOpenDetail] = useState(false);
  return (
    <>
      <ModalDetail
        isOpen={openDetail}
        handleClose={() => setOpenDetail(false)}
        id={id}
      />
      <WrapperProductList>
        <Card openDetail={() => setOpenDetail(true)} />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        {/* {products?.map((product) => (
          <CardList
            key={product._id}
            product={product}
            openDetail={() => setOpenDetail(true)}
            openEdit={() => setOpenEdit(true)}
            setIdDetail={setId}
          />
        ))} */}
      </WrapperProductList>
    </>
  );
};

export default ListProduct;
