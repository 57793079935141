import Filter from "../../components/pod/active-pod/filter.component";
import ButtonTabs from "../../components/pod/active-pod/tabs-button.component";
import ListPods from "../../components/pod/active-pod/list-pods.component";
import { useGetPodsQuery } from "../../store/pod/pod.slice";
import { useEffect, useState } from "react";
import { useDebounce } from "../../helper/useDebounce";
import Bounce from "../../components/loader/bounce.component";

const ManagePodPage = () => {
  const [pods, setPods] = useState([]);
  const [queryString, setQueryString] = useState("?$limit=100&visible[$ne]=true");
  const debounceQueryString = useDebounce(queryString, 1000);
  const { data, isFetching, isLoading, isSuccess } = useGetPodsQuery(debounceQueryString);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [filter, setFilter] = useState({
    podType: "",
    codeName: "",
    skip: 0,
  });

  useEffect(() => {
    let query = "?$limit=100&visible[$ne]=true";
    if (filter.codeName) {
      query = `${query}&codeName=${filter.codeName}`;
    }

    if (filter.podType && filter.podType.id) {
      query = `${query}&podType=${filter.podType.id}`;
    }

    if (filter.skip) {
      query = `${query}&$skip=${filter.skip}`;
    }

    setQueryString(query);
    setIsLoadingFilter(true);
  }, [filter]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      if (data) {
        setPods(data.data);
        setIsLoadingFilter(false);
      }
    }
  }, [data, isLoading, isSuccess]);

  return (
    <div className="flex flex-col w-100 gap-5">
      <Filter filter={filter} setFilter={setFilter} />
      <hr className="text-grey-300" />
      <ButtonTabs />
      {((isLoading && isFetching) || isLoadingFilter) && (
        <div className="flex flex-row w-100 justify-center items-center">
          <Bounce />
        </div>
      )}

      {!isLoading && isSuccess && !isLoadingFilter && <ListPods pods={pods} setFilter={setFilter} filter={filter} />}
    </div>
  );
};

export default ManagePodPage;
