import ReactApexChart from "react-apexcharts";

export default function AreaChart() {
  const data = {
    series: [
      {
        name: "Pods",
        data: [1000, 1500, 1300, 1700, 1000, 1007, 1900],
      },
    ],
    options: {
      chart: {
        width: "100%",
        type: "area",
        toolbar: {
          show: false,
        },
      },
      colors: ["#43C667"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "string",
        categories: ["8H", "24H", "1W", "1M", "3M", "6M", "1Y", "All"],
      },
      yaxis: {
        min: 0,
        max: 2000,
        tickAmount: 2,
      },
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  };
  return (
    <div id="chart">
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="area"
        height={200}
        width={450}
      />
    </div>
  );
}
