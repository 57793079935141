import { WrapperInput, WrapperInputText } from './product-history.styles';
import { WrapperFilter } from '../styles';
import TabPage from '../tab-page';
import { SearchIcon } from '@heroicons/react/solid';

const Filter = () => {
  return (
    <WrapperFilter>
      <TabPage />
      <WrapperInput>
        <WrapperInputText type="text" placeholder="Search..." />
        <div className="absolute inset-y-0 right-2 flex items-center">
          <label htmlFor="currency" className="sr-only">
            Currency
          </label>
          <SearchIcon height={25} width={25} className="main-color" />
        </div>
      </WrapperInput>
    </WrapperFilter>
  );
};

export default Filter;
