import { WrapperTabPage } from "./styles";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTabPod } from "../../store/layout/layout.slice";
import { memo } from "react";

const TabPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // const tab = useSelector((state) => state.layout.tabPod);
  const dispatch = useDispatch();

  const handleChange = (tab, url) => {
    dispatch(setTabPod(tab));
    navigate(`${url}`);
  };
  return (
    <WrapperTabPage>
      <span
        className={`${
          location.pathname.includes("manage-presets") && "active"
        }`}
        onClick={() => handleChange("manage-presets", "/pod/manage-presets")}
      >
        Manage Presets
      </span>
      <span
        className={`${
          location.pathname.includes("/pod/active-pods") && "active"
        }`}
        onClick={() => handleChange("active-pods", "/pod/active-pods")}
      >
        Active Pods
      </span>
      <span
        className={`${
          location.pathname.includes("/pod/inactive-pods") && "active"
        }`}
        onClick={() => handleChange("inactive-pods", "/pod/inactive-pods")}
      >
        Inactive Pods
      </span>
      {/* <span
        className={`${location.pathname.includes("activity-pods") && "active"}`}
        onClick={() => handleChange("activity-pods", "/pod/activity-pods")}
      >
        Pod Activity
      </span> */}
    </WrapperTabPage>
  );
};

export default memo(TabPage);
