import ReactApexChart from "react-apexcharts";

export default function DonutChart({ total }) {
  const data = {
    series: [
      total?.microGREENS ?? 0,
      total?.babyGREENS ?? 0,
      total?.others ?? 0,
    ],
    options: {
      labels: ["MicroGREENS", "BabyGREENS", "Other Product"],
      chart: {
        width: 380,
        type: "donut",
      },
      dataLabels: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
      legend: {
        // show: false,
        position: "bottom",
        offsetY: 0,
        height: 20,
        fontSize: "11px",
        fontWeight: 500,
      },
      colors: ["#43C667", "#89E2A2", "#B2F5C5"],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: "14px",
                offsetY: 30,
                formatter: () => "Total Product",
              },
              value: {
                show: true,
                fontSize: "18px",
                fontFamily: "Inter",
                fontWeight: 700,
                color: "#43c667",
                offsetY: -10,
              },
              total: {
                show: true,
                showAlways: true,
                color: "#64748B",
                fontFamily: "Inter",
                fontWeight: 700,
                fontSize: "12px",
                // formatter: () => {
                //   const sumTotal =
                //     total?.microGREENS ??
                //     0 + total?.babyGREENS ??
                //     0 + total?.others ??
                //     0;
                //   console.log(
                //     "🚀 ~ file: DonutChart.jsx ~ line 69 ~ DonutChart ~ sumTotal",
                //     total?.microGREENS
                //   );
                //   return `${sumTotal}pots`;
                // },
              },
            },
          },
        },
      },
    },
  };
  return (
    <div className="chart-wrap">
      <div id="chart">
        <ReactApexChart
          options={data.options}
          series={data.series}
          type="donut"
          width={310}
          height={250}
        />
      </div>
    </div>
  );
}
