import { Status, Span } from "./dashboard.styles";

const CardNotification = () => {
  return (
    <div className="bg-white rounded-[10px] px-[15px] py-[14px] flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <Span>GP-02-1-001</Span>
        <Status variant="greener">
          <p className="first-letter:capitalize">Greener</p>
        </Status>
      </div>
      <hr className="text-grey-300" />
      <Span className="description">
        Batch 060B21-GP-00-3-001-00-001 is ready for harvest.
      </Span>
      <Span className="date">September 2nd, 2021 -09:00</Span>
    </div>
  );
};

export default CardNotification;
