import {
  WrapperButton,
  WrapperCardProductList,
  WrapperDescription,
  WrapperImage,
  WrapperPreset,
  WrapperStatus,
} from "./product-active.styles";
import { useState, useEffect, memo } from "react";

const CardList = ({ product, openDetail, openEdit, setId, setTypeModal }) => {
  const [status, setStatus] = useState({
    label: "",
    color: "",
    labelButton: "",
  });

  const statusLabel = (state) => {
    switch (state) {
      case "germinating":
        setStatus({
          label: "Garminating",
          color: "#43C667",
          labelButton: "Prepare",
        });
        break;
      case "seedling":
        setStatus({
          label: "Seedling",
          color: "#43C667",
          labelButton: "Deliver",
        });
        break;
      case "processing":
        setStatus({
          label: "Processing",
          color: "#43C667",
          labelButton: "Harvest",
        });
        break;
      case "":
        setStatus({
          label: "Processing",
          color: "#43C667",
          labelButton: "Harvest",
        });
        break;
      case "harvested":
        setStatus({
          label: "Harvest",
          color: "#43C667",
          labelButton: "Detail",
        });
        break;
      case "overriped":
        setStatus({
          label: "Overripe",
          color: "#FFA24D",
          labelButton: "Harvest",
        });
        break;
      case "wasted":
        setStatus({
          label: "Wasted",
          color: "#E94141",
          labelButton: "Detail",
        });
        break;
      case "enroute":
        setStatus({
          label: "En Route",
          color: "#43C667",
          labelButton: "Arrive",
        });
        break;

      default:
        setStatus({
          label: "New Request",
          color: "#43C667",
          labelButton: "Prepare",
        });
        break;
    }
  };

  const handleEdit = () => {
    setTypeModal(product?.harvestStateLabel || "");
    setId(product?._id);
    openEdit();
  };

  useEffect(() => {
    statusLabel(product?.harvestStateLabel || "");
  }, [product]);

  return (
    <WrapperCardProductList>
      <WrapperImage className="relative">
        {product?.imageLink && <img src={product?.imageLink} alt="img" className="h-full w-full object-contain" />}
      </WrapperImage>
      <WrapperDescription color={status.color}>
        <span
          onClick={() => {
            setId(product?._id);
            openDetail();
          }}
          className="h-10"
        >
          {product?.productName || "-"}
        </span>
        <label className="text-grey-300">{product?.blockchainData?.productCodeName || "-"}</label>
        <hr className="text-grey-300" />
        <WrapperPreset>
          <span>{product?.pod?.presetLabel || "-"}</span>
          <p>Environment</p>
        </WrapperPreset>
        <WrapperStatus color={status.color}>
          <span>{status.label || ""}</span>
          <p>status</p>
        </WrapperStatus>
      </WrapperDescription>
      <WrapperButton onClick={handleEdit} color={status.color}>
        {status.labelButton || ""}
      </WrapperButton>
    </WrapperCardProductList>
  );
};

export default memo(CardList);
