import { WrapperChartDonut } from "./dashboard.styles";
import RadialChart from "./chart/RadialChart";

const CardSuccessRate = ({ data }) => {
  return (
    <WrapperChartDonut className="rounded-[10px] h-[160px] p-3 flex flex-col gap-3 success-rate justify-center items-center">
      <RadialChart success={data} />
      <small className="h-[32px] title">
        <b>&nbsp;</b>
      </small>
    </WrapperChartDonut>
  );
};

export default CardSuccessRate;
