import {
  WrapperButton,
  WrapperCardProductList,
  WrapperDescription,
  WrapperImage,
  WrapperPreset,
} from "./poduct-list.styles";
import { memo } from "react";

const CardList = ({ product, openDetail, openEdit, setIdDetail }) => {
  return (
    <WrapperCardProductList>
      <WrapperImage className="relative">
        {product?.imageLink && <img src={product?.imageLink} alt="img" className="h-full w-full object-contain" />}
      </WrapperImage>
      <WrapperDescription>
        <span
          onClick={() => {
            setIdDetail(product._id);
            openDetail();
          }}
          className="h-10"
        >
          {product.productName}
        </span>
        <label className="text-grey-300">-</label>
        <hr className="text-grey-300" />
        <WrapperPreset>
          <span>{product.presetLabel}</span>
          <p>Environment</p>
        </WrapperPreset>
      </WrapperDescription>
      <WrapperButton
        onClick={() => {
          setIdDetail(product._id);
          openEdit();
        }}
      >
        Edit
      </WrapperButton>
    </WrapperCardProductList>
  );
};

export default memo(CardList);
