const ListPod = ({ data }) => {
  return (
    <table className="table-fixed border-spacing-2 border-separate overflow-scroll">
      <thead>
        <tr>
          <th className="span w-48" align="left">
            Pod List
          </th>
          <th className="span w-28" align="left">
            Status
          </th>
          <th className="w-6">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {data?.podList?.length <= 0 && (
          <tr>
            <td colSpan={3}>
              <em>No data</em>
            </td>
          </tr>
        )}
        {data?.podList?.map((pod) => (
          <tr key={pod._id}>
            <td className="code-name">{pod.codeName}</td>
            {pod.active && <td className="active">Active</td>}
            {!pod.active && <td className="inactive">Inactive</td>}

            <td>
              <img
                src="/img/table-right-arrow.svg"
                alt="action"
                className="cursor-pointer"
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ListPod;
