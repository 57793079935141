import ReactApexChart from "react-apexcharts";

export default function ColumnChart({ pod }) {
  const data = {
    options: {
      chart: {
        id: "apexchart-example",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: ["Water", "C02", "Acid", "Nutrient"],
        labels: {
          style: {
            colors: "#64748B",
            fontSize: "10px",
          },
        },
        position: "top",
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 2,
        opposite: true,
        labels: {
          formatter: (value) => value?.toFixed(0) + "%",
          style: {
            colors: "#64748B",
            fontSize: "10px",
          },
        },
      },
      colors: "#43c667",
      plotOptions: {
        bar: {
          columnWidth: "15%",
          borderRadius: 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
    },
    series: [
      {
        name: "Value",
        data: [
          pod?.snapshot?.water_temp || 0,
          pod?.snapshot?.air_co2 || 0,
          pod?.snapshot?.water_acid || 0,
          pod?.snapshot?.water_nutrient || 0,
        ],
      },
    ],
  };
  return (
    <ReactApexChart
      options={data.options}
      series={data.series}
      type="bar"
      height={105}
    />
  );
}
