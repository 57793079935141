import Filter from "../../components/products/product-list/filter-product-list.component";
import ProductList from "../../components/products/product-list/list-product-list.component";
import ModalCreate from "../../components/products/product-list/create.component";
import Button from "../../components/button/button.component";
import { useState } from "react";
import ModalConfirm from "../../components/modal/modal-confirm.component";

const ProductListPage = () => {
  const [openCreate, setOpenCreate] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const [modalData, setModalData] = useState({
    title: "",
    description: "",
    typeModal: "",
    typeConfirm: "",
    isError: false,
  });

  return (
    <>
      {openCreate && (
        <ModalCreate
          isOpen={openCreate}
          handleClose={() => setOpenCreate(false)}
          modalData={modalData}
          setModalData={setModalData}
          setOpenConfirm={setOpenConfirm}
        />
      )}

      {openConfirm && (
        <ModalConfirm
          isOpen={openConfirm}
          handleClose={() => setOpenConfirm(false)}
          title={modalData.title}
          description={modalData.description}
          typeModal={modalData.type}
          typeConfirm={modalData.typeConfirm}
          isError={modalData.isError}
        />
      )}

      <div className="flex flex-col w-100 gap-5 pb-10">
        <Filter />
        <hr className="text-grey-300" />
        <Button variants="mainFilled" size="md" type="button" className="w-40" onClick={() => setOpenCreate(true)}>
          Add New Product
        </Button>
        <ProductList />
      </div>
    </>
  );
};

export default ProductListPage;
