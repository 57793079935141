import { memo } from "react";
import Modal from "../../../modal/modal.component";
import { useUpdateStateMutation } from "../../../../store/product/product.slice";
import { WrapperButton, WrapperDetail, WrapperForm, WrapperImageDetail, WrapperTextInput } from "./processing.styles";
import { useState } from "react";
import Button from "../../../button/button.component";
import { HARVEST_STATE } from "../../../../helper/harvestState";
import Spinner from "../../../loader/spinner.component";

const Form = ({ isOpen = false, handleClose, id, setTypeModal, setModalData, product, removeProductAfterAction }) => {
  const [payload, setPayload] = useState({
    harvestState: "",
    harvestWeight: "",
    successPercentage: "",
    notes: "",
  });
  const [updateHarvested, { isLoading }] = useUpdateStateMutation();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  // const canPost = [payload.harvestWeight, payload.successPercentage].every(Boolean) && !isLoading;
  const handleSubmit = async () => {
    try {
      const newPayload = { ...payload, harvestState: HARVEST_STATE["HARVESTED"] };
      console.log("Set Product to Force Harvest with Payload : ", newPayload);
      console.log(`Set Product to Force Harvest URL PATCH : ${process.env.REACT_APP_API_BASE_URL}/product/${id}`);
      const response = await updateHarvested({
        id,
        initialPost: newPayload,
      }).unwrap();
      console.log("Set Product to Force Harvest Response : ", response);
      setModalData({
        description: `Batch ${product?.codeBatch} Harvested.`,
      });
      removeProductAfterAction();
      setTypeModal("confirm-success");
    } catch (err) {
      setModalData({
        description: err.data.message,
      });
      setTypeModal("confirm-failed");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      size="md"
      type="success"
      padding="p-0"
      title={product?.productName}
    >
      <WrapperDetail className="rounded-b-[18px] overflow-hidden">
        <WrapperImageDetail className="relative">
          {product?.imageLink && <img src={product?.imageLink} alt="img" className="h-full w-full object-contain" />}
        </WrapperImageDetail>
        <WrapperForm>
          <WrapperTextInput>
            <input
              type="number"
              value={payload.harvestWeight}
              placeholder="Harvest Weight"
              name="harvestWeight"
              onChange={handleChange}
            />
          </WrapperTextInput>
          <WrapperTextInput>
            <input
              type="number"
              value={payload.successPercentage}
              placeholder="Success Rate"
              name="successPercentage"
              onChange={handleChange}
            />
          </WrapperTextInput>
          <WrapperTextInput>
            <textarea
              defaultValue=""
              rows={8}
              placeholder="Notes"
              onChange={(e) => setPayload({ ...payload, notes: e.target.value })}
            />
          </WrapperTextInput>
        </WrapperForm>
        <WrapperButton className="mt-20 px-24">
          <Button
            variant="filled"
            variants="mainFilled"
            size="lg"
            className="w-full"
            type="button"
            // disabled={isLoading}
            onClick={handleSubmit}
          >
            {isLoading && <Spinner />}
            Force Harvest
          </Button>
        </WrapperButton>
      </WrapperDetail>
    </Modal>
  );
};

export default memo(Form);
