import { Status, WrapperCard, WrapperStatus, WrapperTime } from "../styles";
import Button from "../../button/button.component";
import SunDefault from "../../../assets/img/sun-default.svg";

const Card = ({ preset, openEdit, openDelete }) => {
  return (
    <WrapperCard className="p-6">
      <div className="flex justify-between items-center">
        <WrapperStatus className="flex flex-row gap-2">
          <span>{preset?.label || "-"}</span>
          {/* <Status variant="greener">Greener</Status> */}
        </WrapperStatus>
        <div className="flex flex-row gap-2">
          <Button variants="dangerOutlined" size="sm" type="button" onClick={openDelete}>
            Delete
          </Button>
          <Button variants="mainOutlined" size="sm" type="button" onClick={openEdit}>
            Edit
          </Button>
        </div>
      </div>
      <div className="flex flex-row mt-6 w-full">
        <div className="flex flex-col gap-2 overflow-auto max-h-28 mr-7 min-w-max">
          {preset?.configuration?.lights_timer?.map((timer, index) => (
            <WrapperTime key={index + 1} className="flex flex-row gap-1 value-index items-center justify-center">
              <img src={SunDefault} alt="sun" />
              <span className="mr-2">{index + 1}th:</span>
              <span className="value-green">
                {timer[0]?.start} - {timer[0]?.end}
              </span>
            </WrapperTime>
          ))}
        </div>
        <div className="flex flex-col justify-between w-full gap-3">
          <div className="flex justify-between items-center">
            <WrapperTime className="flex flex-col gap-1 value-index">
              <span className="mr-2 ">Air Condition</span>
              <span className="value-green">{preset.configuration?.air_temp[0]?.light_on ?? 0}°C</span>
            </WrapperTime>
            <WrapperTime className="flex flex-col justify-end gap-1 value-index">
              <span className="mr-2">Water Condition</span>
              <span className="value-green">{preset.configuration?.air_temp[0]?.light_off ?? 0}°C</span>
            </WrapperTime>
          </div>
          <hr className="text-grey-300 w-100" />
          <WrapperTime className="flex justify-between gap-1 value-index">
            <WrapperTime className="flex flex-col gap-1 value-index">
              <WrapperTime className="flex flex-row gap-1 value-index">
                <span className="mr-2">Water</span>
                <span className="value-green">{preset.configuration?.air_humidity ?? 0}%</span>
              </WrapperTime>
              <WrapperTime className="flex flex-row gap-1 value-index">
                <span className="mr-2">Acid</span>
                <span className="value-green">{preset?.configuration?.water_acid || "-"} Ph</span>
              </WrapperTime>
            </WrapperTime>
            <WrapperTime className="flex flex-col gap-1 value-index">
              <WrapperTime className="flex flex-row justify-between gap-1 value-index">
                <span className="mr-2">CO2</span>
                <span className="value-green">{preset?.configuration?.air_co2?.light_on || 0} PPM</span>
              </WrapperTime>
              <WrapperTime className="flex flex-row  justify-between gap-1 value-index">
                <span className="mr-2">Nutrient</span>
                <span className="value-green">{preset?.configuration?.water_nutrient || "-"} PPM</span>
              </WrapperTime>
            </WrapperTime>
          </WrapperTime>
        </div>
      </div>
    </WrapperCard>
  );
};

export default Card;
