import Modal from "../../modal/modal.component";
import {
  WrapperDescriptionDetail,
  WrapperDetail,
  WrapperImageDetail,
  WrapperPresetDetail,
  WrapperButtonCreate,
} from "./poduct-list.styles";
import Button from "../../button/button.component";

const Detail = ({ isOpen, handleClose, id, setModalData, setOpenConfirm, product }) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      size="md"
      type="success"
      padding="p-0"
      title={product?.productTemplate?.productName}
    >
      <input className="hidden" type="text" value="" onChange={() => {}} />
      <WrapperDetail>
        <WrapperImageDetail className="relative">
          {product?.productTemplate?.imageLink && (
            <img src={product?.productTemplate?.imageLink} alt="img" className="h-full w-full object-contain" />
          )}
        </WrapperImageDetail>
        <WrapperDescriptionDetail>
          <WrapperPresetDetail>
            <span>Product Name</span>
            <p>{product?.productTemplate?.productName ?? "--"}</p>
          </WrapperPresetDetail>
          <WrapperPresetDetail>
            <span>Description</span>
            <p>{product?.productTemplate?.productDescription ?? "--"}</p>
          </WrapperPresetDetail>
          <WrapperPresetDetail>
            <span>Environment</span>
            <p>{product?.productTemplate.presetLabel ?? "--"}</p>
          </WrapperPresetDetail>
          <WrapperButtonCreate className="mt-20">
            <Button
              variant="outlined"
              variants="dangerOutlined"
              size="lg"
              className="w-full"
              onClick={() => {
                setModalData({
                  title: "Reject Product Request",
                  description: "Are you sure, you want to reject this product?  ",
                  typeModal: "failed",
                  typeConfirm: "failed",
                  isError: false,
                  isApprove: false,
                });
                setOpenConfirm(true);
                handleClose();
              }}
            >
              Reject
            </Button>
            <Button
              variant="filled"
              variants="mainFilled"
              size="lg"
              className="w-full"
              type="button"
              // disabled={isLoading}
              onClick={() => {
                setModalData({
                  title: "Approve Product Request",
                  description: "Are you sure, you want to approve this product request? ",
                  typeModal: "success",
                  typeConfirm: "success",
                  isError: false,
                  isApprove: true,
                });
                setOpenConfirm(true);
                handleClose();
              }}
            >
              Approve
            </Button>
          </WrapperButtonCreate>
        </WrapperDescriptionDetail>
      </WrapperDetail>
    </Modal>
  );
};

export default Detail;
