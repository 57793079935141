import Filter from "../../components/products/product-active/filter.component";
import Tab from "../../components/products/plant-product-type/tab";
import { useState } from "react";
import PlantCategory from "../../components/products/plant-product-type/plant-category";
import PlantName from "../../components/products/plant-product-type/plant-name";
import PlantProductType from "../../components/products/plant-product-type/plant-product-type";

const ProductListPage = () => {
  const [tab, setTab] = useState("plant-name");
  return (
    <>
      <div className="flex flex-col w-100 gap-5 pb-10">
        <Filter />
        <hr className="text-grey-300" />
        <div className="flex flex-row w-full gap-10 h-full divide-x divide-grey-400 ">
          <Tab tab={tab} handleClick={setTab} />
          {tab === "plant-category" && <PlantCategory />}
          {tab === "plant-name" && <PlantName />}
          {tab === "plant-product-type" && <PlantProductType />}
        </div>
      </div>
    </>
  );
};

export default ProductListPage;
