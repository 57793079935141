import { memo } from "react";
import Modal from "../../../modal/modal.component";
import Spinner from "../../../loader/spinner.component";
import { useUpdateStateMutation } from "../../../../store/product/product.slice";
import { WrapperButton, WrapperDetail, WrapperForm, WrapperImageDetail, WrapperTextInput } from "./wasted.styles";
import { useState } from "react";
import Button from "../../../button/button.component";
import { HARVEST_STATE } from "../../../../helper/harvestState";

const Form = ({ isOpen = false, handleClose, id, setTypeModal, setModalData, product, removeProductAfterAction }) => {
  const [updateState, { isLoading }] = useUpdateStateMutation();
  const [notes, setNotes] = useState("");

  const handleChange = (e) => {
    const { value } = e.target;
    setNotes(value);
  };

  const handleWaste = async () => {
    try {
      console.log("Set Product to Wasted with Payload : ", {
        notes: notes,
        harvestState: HARVEST_STATE["WASTED"],
      });
      console.log(`Set Product to Wasted URL PATCH : ${process.env.REACT_APP_API_BASE_URL}/product/${id}`);
      const response = await updateState({
        id,
        initialPost: { notes: notes, harvestState: HARVEST_STATE["WASTED"] },
      }).unwrap();
      console.log("Set Product to Wasted Response : ", response);
      removeProductAfterAction();
      setTypeModal("confirm-failed");
    } catch (err) {
      setModalData({
        description: err.data.message,
      });
      setTypeModal("confirm-failed");
    }
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} size="sm" type="failed" padding="p-0" title={product?.productName}>
      <WrapperDetail className="rounded-b-[18px] overflow-hidden">
        <WrapperImageDetail className="relative">
          {product?.imageLink && <img src={product?.imageLink} alt="img" className="h-full w-full object-contain" />}
        </WrapperImageDetail>
        <WrapperForm>
          <WrapperTextInput>
            <textarea defaultValue="" rows={8} placeholder="Notes" onChange={handleChange} name="notes" />
          </WrapperTextInput>
        </WrapperForm>
        <WrapperButton className="mt-20 px-20">
          <Button
            variant="filled"
            variants="dangerFilled"
            size="lg"
            className="w-full"
            type="button"
            disabled={isLoading}
            onClick={handleWaste}
          >
            {isLoading && <Spinner />}
            Waste
          </Button>
        </WrapperButton>
      </WrapperDetail>
    </Modal>
  );
};

export default memo(Form);
