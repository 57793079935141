import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";

export const apiSlice = createApi({
  reducerPath: "api", // optional
  baseQuery: customFetchBase,
  tagTypes: [
    "User",
    "Pod",
    "Pod Type",
    "Preset",
    "Dashboard",
    "PRODUCT_LIST",
    "PRODUCT_REQUEST",
    "PRODUCT_NEW_REQUEST",
    "PRODUCT_ACTIVE",
    "HARVEST_STATE",
    "PLANT_CATEGORY",
    "PLANT_NAME",
    "PLANT_PRODUCT_TYPE",
  ],
  endpoints: (builder) => ({}),
});
