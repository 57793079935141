import { ButtonDetails } from "./active-pod.styles";
import CardProduct from "./card-product.component";
import { useGetProductsByPodQuery } from "../../../store/product/product.slice";
import Spinner from "../../loader/spinner.component";
import { useState, useEffect } from "react";
import Bounce from "../../loader/bounce.component";

const Product = ({ pod, openDetail }) => {
  const { data, isSuccess, isLoading } = useGetProductsByPodQuery(pod?._id);
  const [product, setProducts] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      let newProduct = [];
      const { entities, ids } = data;
      ids.map((id) => {
        newProduct.push(entities[id]);
      });
      setProducts(newProduct);
    }
  }, [data, isSuccess]);
  return (
    <>
      {isLoading && (
        <div className="h-[245px] bg-main-grey-200 p-[16px] flex flex-col justify-center items-center">
          {isLoading && <Bounce />}
        </div>
      )}

      {!isLoading && product.length > 0 && (
        <div className="h-[245px] bg-main-grey-200 p-[16px] flex flex-col gap-2 overflow-auto">
          {product.map((product, index) => (
            <CardProduct key={index} product={product} />
          ))}
        </div>
      )}

      {!isLoading && product.length <= 0 && (
        <div className="h-[245px] bg-main-grey-200 p-[16px] flex flex-col justify-center items-center">
          <span className="small-span">Product not found</span>
        </div>
      )}

      <ButtonDetails
        className="flex flex-row justify-center items-center h-[56px] bg-main-color cursor-pointer"
        onClick={openDetail}
      >
        See Details
      </ButtonDetails>
    </>
  );
};

export default Product;
