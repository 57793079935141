import { ButtonDetails, EnvironmentContent, LineFilled, Tank } from "./active-pod.styles";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import SunOn from "../../../assets/img/sun.svg";
import SunOff from "../../../assets/img/sun-off.svg";
import WaterOn from "../../../assets/img/water.svg";
import WaterOff from "../../../assets/img/water-off.svg";
import { useState } from "react";

const Environmnet = ({ openDetail, pod }) => {
  const [expand, setExpand] = useState(true);

  // const HandleOpening = () => {
  //   setExpand(!expand);
  //   // setHeight(!expand ? `${contentElement.current.scrollHeight}px` : '0px');
  // };

  return (
    <>
      <Tank className="px-[16px] flex justify-between pt-6 items-center">
        <span>Tank</span>
        <LineFilled>
          <li
            data-year="Water"
            data-text=""
            className={pod.indicator ? `${pod.indicator.air_humidity}-indicator` : ""}
          ></li>
          <li data-year="CO2" data-text="" className={pod.indicator ? `${pod.indicator.air_co2}-indicator` : ""}></li>
          <li
            data-year="Acid"
            data-text=""
            className={pod.indicator ? `${pod.indicator.water_acid}-indicator` : ""}
          ></li>
          <li
            data-year="Nutrient"
            data-text=""
            className={pod.indicator ? `${pod.indicator.water_nutrient}-indicator` : ""}
          ></li>
          <li
            data-year="Water Lvl"
            data-text=""
            className={pod.indicator ? `${pod.indicator.water_level}-indicator` : ""}
          ></li>
        </LineFilled>

        {/* {expand && (
          <ChevronUpIcon
            height={25}
            width={25}
            className="main-color cursor-pointer"
            onClick={HandleOpening}
          />
        )}
        {!expand && (
          <ChevronDownIcon
            height={25}
            width={25}
            className="main-color cursor-pointer"
            onClick={HandleOpening}
          />
        )} */}
      </Tank>
      {/* <div
        ref={contentElement}
        style={{ height: height }}
        className={`${
          !expand && 'overflow-hidden'
        } transition-all duration-500`}
      > */}
      <EnvironmentContent>
        <div className="bg-white h-[44px] flex justify-between items-center p-[16px] rounded-[8px]">
          <span>Life Support</span>
          <img src={pod?.snapshot?.life_support_lamp ? SunOn : SunOff} height={25} width={25} alt="sun" />
          <img src={pod?.snapshot?.life_support_pump ? WaterOn : WaterOff} height={20} width={20} alt="sun" />
        </div>
        <div className="flex flex-row mt-[16px] justify-between items-center">
          <div className="bg-white h-[76px] w-[145px] flex flex-col justify-center items-center rounded-[8px]">
            <span>Air Condition</span>
            <label className="font-normal text-[23px] leading-[28px] main-color">
              {pod?.snapshot?.air_temp ? pod?.snapshot?.air_temp.toFixed(2) : "0"}° C
            </label>
          </div>
          <div className="bg-white h-[76px] w-[145px] flex flex-col justify-center items-center rounded-[8px]">
            <span>Water Condition</span>
            <label className="font-normal text-[23px] leading-[28px] main-color">
              {pod?.snapshot?.water_temp ? pod?.snapshot?.water_temp.toFixed(2) : "0"}° C
            </label>
          </div>
        </div>
      </EnvironmentContent>
      <ButtonDetails
        className="flex flex-row justify-center items-center h-[56px] bg-main-color cursor-pointer"
        onClick={openDetail}
      >
        Details
      </ButtonDetails>
      {/* </div> */}
    </>
  );
};

export default Environmnet;
