import Tab from "../../components/settings/tab";
import Profile from "../../components/settings/profile";
import { useState } from "react";
import Security from "../../components/settings/security";

const IndexPage = () => {
  const [tab, setTab] = useState(false);
  return (
    <div className="flex flex-row w-full gap-10 h-full divide-x divide-grey-400 ">
      <Tab tab={tab} handleClick={setTab} />
      {!tab && <Profile />}
      {/* {tab && <Security />} */}
    </div>
  );
};

export default IndexPage;
