import { memo } from "react";
import Modal from "../../../modal/modal.component";
import Dropdown from "../../../dropdown/dropdown.component";
import { useCreateProductsActiveMutation, useAssignToBotanistMutation } from "../../../../store/product/product.slice";
import { useGetPodsQuery } from "../../../../store/pod/pod.slice";
import { WrapperButton, WrapperDetail, WrapperForm, WrapperImageDetail, WrapperTextInput } from "./new-request.styles";
import { useState, useEffect } from "react";
import Button from "../../../button/button.component";
import Spinner from "../../../loader/spinner.component";

const Form = ({ isOpen = false, handleClose, id, setTypeModal, setModalData, product }) => {
  const podsResponse = useGetPodsQuery(`?$limit=50&visible=true`);
  const [newRequest, { isLoading }] = useCreateProductsActiveMutation();
  const [assignToBotanis] = useAssignToBotanistMutation();
  const [podSelected, setPodSelected] = useState({});
  const [optionGreensPod, setOptionGreensPod] = useState([]);
  const [payload, setPayload] = useState({
    seedVolume: "",
    totalTray: "",
    seedBatch: "",
    seedSource: "",
    seedExpDate: "",
    estHarvestDate: "",
    podId: "",
    productTemplateUserId: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const onStartGarminationClicked = async () => {
    try {
      // Assign To Botanist
      // const payloadBotanist = {
      //   userId: product?.userId,
      //   productTemplateId: product?.productTemplateId,
      // };
      // console.log("Create PRODUCT TEMPLATE ASSIGN TO BOTANIST with Payload : ", payloadBotanist);
      // console.log(
      //   `Create PRODUCT TEMPLATE ASSIGN TO BOTANIST URL POST : ${process.env.REACT_APP_API_BASE_URL}/product-template-user`
      // );
      // const productTemplateUser = await assignToBotanis(payloadBotanist).unwrap();
      // console.log("Create PRODUCT TEMPLATE ASSIGN TO BOTANIST Response : ", productTemplateUser);
      // End Assign To Botanist

      const newPayload = { ...payload, productTemplateUserId: product?._id };
      console.log("Create PRODUCT with Payload : ", newPayload);
      console.log(`Create PRODUCT URL POST : ${process.env.REACT_APP_API_BASE_URL}/product`);
      const response = await newRequest(newPayload).unwrap();
      console.log("Create PRODUCT  Response : ", response);

      setModalData({
        title: product?.productTemplate?.productName,
        description: `Batch Start Garminating.`,
        typeModal: "succes",
        typeConfirm: "success",
        isError: false,
      });
      setTypeModal("confirm-success");
      setPayload({
        seedVolume: 0,
        totalTray: 0,
        seedBatch: "",
        seedSource: "",
        seedExpDate: "",
        estHarvestDate: "",
        podId: "",
        productTemplateUserId: "",
      });
    } catch (err) {
      setModalData({
        title: product?.productTemplate?.productName,
        description: err?.data?.message,
        typeModal: "failed",
        typeConfirm: "failed",
        isError: true,
      });
      setTypeModal("confirm-failed");
    }
  };

  useEffect(() => {
    let data = podsResponse;
    if (data.data) {
      let arrayPods = [];
      data.data.data?.map((pod) => {
        arrayPods.push({ id: pod._id, label: `${pod.codeName} - ${pod.podTypeLabel}` });
      });
      setOptionGreensPod(arrayPods);
    }
  }, [podsResponse]);

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      size="md"
      type="success"
      padding="p-0"
      title={product?.productTemplate?.productName}
    >
      <WrapperDetail>
        <WrapperImageDetail className="relative">
          {product?.productTemplate?.imageLink && (
            <img src={product?.productTemplate?.imageLink} alt="img" className="h-full w-full object-contain" />
          )}
        </WrapperImageDetail>
        <WrapperForm>
          <WrapperTextInput>
            <input
              type="text"
              value={payload.seedVolume}
              placeholder="Input Seed Weight (Gram)"
              name="seedVolume"
              onChange={handleChange}
            />
          </WrapperTextInput>
          <WrapperTextInput>
            <input
              type="text"
              value={payload.totalTray}
              placeholder="Quantity (Pot)"
              name="totalTray"
              onChange={handleChange}
            />
          </WrapperTextInput>
          <WrapperTextInput>
            <input
              type="text"
              value={payload.seedBatch}
              placeholder="Seed Batch"
              name="seedBatch"
              onChange={handleChange}
            />
          </WrapperTextInput>
          <WrapperTextInput>
            <input
              type="text"
              value={payload.seedSource}
              placeholder="Seed Source"
              name="seedSource"
              onChange={handleChange}
            />
          </WrapperTextInput>
          <WrapperTextInput>
            <input
              type="text"
              value={payload.seedExpDate}
              placeholder="Seed Exp. Date"
              name="seedExpDate"
              onChange={handleChange}
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
            />
          </WrapperTextInput>
          <WrapperTextInput>
            <input
              type="text"
              value={payload.estHarvestDate}
              placeholder="Seed Est. Harvest Date"
              name="estHarvestDate"
              onChange={handleChange}
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
            />
          </WrapperTextInput>
          <WrapperTextInput>
            <Dropdown
              options={optionGreensPod}
              placeHolder="Choose GREENS Pod"
              selectedOption={optionGreensPod.filter((opt) => opt.id === payload.podId)[0]}
              handelChange={(e) => {
                setPodSelected(e);
                setPayload({ ...payload, podId: e.id });
              }}
            />
          </WrapperTextInput>
        </WrapperForm>
        <WrapperButton className="px-20">
          <Button
            variant="filled"
            variants="mainFilled"
            size="lg"
            className="w-full whitespace-nowrap"
            type="button"
            // disabled={isLoading}
            onClick={onStartGarminationClicked}
          >
            {isLoading && <Spinner />}
            Start Garmination
          </Button>
        </WrapperButton>
      </WrapperDetail>
    </Modal>
  );
};

export default memo(Form);
