import styled from 'styled-components';

export const ButtonTab = styled.div`
  width: 311px;
  height: 67px;
  padding: 23px;
  background: ${(props) =>
    props.variants === 'active' ? '#e2e8f0' : '#F8FAFC'};
  border-radius: 10px;

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #64748b;
  }
`;

export const WrapperContent = styled.div`
  background: #f8fafc;
  border-radius: 10px;

  .title-menu {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #64748b;
  }

  label {
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #94a3b8;
  }

  input {
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    padding: 12px 20px 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #64748b;
  }
`;

export const WrapperImage = styled.div`
  width: 88px;
  height: 88px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #43c667;
`;

export const WrapperButton = styled.div`
  background: ${(props) =>
    props.variant === 'active' ? '#e2e8f0' : 'transparent'};
  border-radius: 10px;
  border: 1px solid #e2e8f0;
  padding: 16px 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #64748b;
  width: 210px;
`;

export const WrapperInput = styled.div.attrs({
  className: 'relative rounded-md shadow-sm',
})``;

export const WrapperInputText = styled.input.attrs({
  className: 'block w-full',
})`
  padding: 10px 34px 10px 16px;
  height: 40px;
  background: #e2e8f0;
  border-radius: 8px;
`;
