import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { WrapperHeader } from "./styles";

const ModalDialog = ({ isOpen, handleClose, title, children, size, type = "", padding = "p-6" }) => {
  let refDiv = useRef(null);
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog initialFocus={refDiv} as="div" className="fixed inset-0 overflow-y-auto" onClose={handleClose}>
        <div className="min-h-screen px-4 text-center py-3">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 " style={{ backgroundColor: "rgba(137, 226, 162, 0.3)" }} />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={`inline-block w-full 
              ${size === "2xl" && "max-w-2xl"} 
              ${size === "xl" && "max-w-xl"} 
              ${size === "lg" && "max-w-lg"} 
              ${size === "md" && "max-w-md"} 
              ${size === "sm" && "max-w-sm"} 
              ${
                size === "" && "max-w-md"
              } text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl`}
              ref={refDiv}
            >
              <WrapperHeader type={type}>
                <span>{title}</span>
                <XIcon
                  height={20}
                  width={20}
                  className={`${type === "" ? "text-grey-700" : "text-white"} cursor-pointer`}
                  onClick={handleClose}
                />
              </WrapperHeader>
              <div className={padding}>{children}</div>
            </div>
          </Transition.Child>
        </div>
        <button className="opacity-0 absolute bottom-0"></button>
      </Dialog>
    </Transition>
  );
};

export default ModalDialog;
