import Modal from "../../modal/modal.component";
import ModalConfirm from "../../modal/modal-confirm.component";
import { useSelector } from "react-redux";
import { selectProductById } from "../../../store/product/product.slice";
import {
  WrapperButtonCreate,
  WrapperButtonEdit,
  WrapperDetail,
  WrapperFormCreate,
  WrapperImageCreate,
  WrapperTextInput,
} from "./poduct-list.styles";
import Button from "../../button/button.component";
import { selectAllPresetss } from "../../../store/presets/presets.slice";
import Dropdown from "../../dropdown/dropdown.component";
import { useState, useEffect, memo } from "react";
import { useUpdateProductsTemplateMutation, useGetPlantProductTypeQuery } from "../../../store/product/product.slice";
import { PencilIcon } from "@heroicons/react/solid";
import getBase64 from "../../../utils/base64";

const Edit = ({ isOpen, handleClose, id, modalData, setModalData, setOpenConfirm }) => {
  const [isLoading, setIsLoading] = useState(false);
  const product = useSelector((state) => selectProductById(state, id));
  const presets = useSelector(selectAllPresetss);
  const [optionPresets, setOptionPresets] = useState([]);
  const [openNotif, setopenNotif] = useState(false);
  const [updateProductTemplate] = useUpdateProductsTemplateMutation();
  const { data: dataProductTypes } = useGetPlantProductTypeQuery();
  const [optionCategories, setOptionCategories] = useState([]);
  let inputRef;

  const [payload, setPayload] = useState({
    productName: product?.productName,
    productDescription: product?.productName,
    productType: product?.productType,
    imageLink: product?.imageLink,
    preset: product?.preset,
  });

  const onSaveConfirm = async () => {
    try {
      setIsLoading(true);

      console.log("Create Product Template with Payload : ", payload);
      console.log(`Edit Product Template URL PATCH : ${process.env.REACT_APP_API_BASE_URL}/product-template/${id}`);
      const response = await updateProductTemplate({ id, payload }).unwrap();
      console.log("Edit Product Template Response : ", response);

      setModalData({
        title: "Product Updated",
        subTitle: "Product Updated",
        description: "You’ve successfully update this product information.",
        typeModal: "",
        typeConfirm: "success",
        isError: false,
      });
      setopenNotif(false);
      setTimeout(() => {
        setOpenConfirm();
        setIsLoading(false);
        handleClose();
      }, 700);
    } catch (err) {
      console.log("Edit Product Template Response : ", err);
      setopenNotif(false);
      setModalData({
        title: "Failed Update Product",
        subTitle: "Failed Update Product",
        description: err.message,
        typeModal: "failed",
        typeConfirm: "failed",
        isError: false,
      });

      setTimeout(() => {
        setOpenConfirm();
        setIsLoading(false);
        handleClose();
      }, 700);
    }
  };

  const onDeleteConfirm = () => {};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const handleDescription = (e) => {
    setPayload({ ...payload, productDescription: e.target.value });
  };

  const handleFileSelected = (e) => {
    const file = e.target.files && e.target.files[0];
    getBase64(file, (result) => {
      setPayload({ ...payload, imageLink: result });
    });
  };

  useEffect(() => {
    setPayload({
      productName: product?.productName,
      productDescription: product?.productDescription,
      productType: product?.productType,
      imageLink: product?.imageLink,
      preset: product?.preset,
    });
  }, [product]);

  useEffect(() => {
    const arrayPods = [];
    presets?.map(({ _id, label }) => arrayPods.push({ id: _id, label: label }));
    setOptionPresets(arrayPods);
  }, [presets]);

  useEffect(() => {
    if (dataProductTypes) {
      const newOptionProductTypes = [];
      dataProductTypes.data.map((obj) => newOptionProductTypes.push({ id: obj.productType, label: obj.label }));
      setOptionCategories(newOptionProductTypes);
    }
  }, [dataProductTypes]);

  return (
    <>
      <ModalConfirm
        isOpen={openNotif}
        handleClose={() => setopenNotif(false)}
        title={modalData.title}
        description={modalData.description}
        typeModal={modalData.typeModal}
        typeConfirm={modalData.typeConfirm}
        isError={modalData.isError}
        handleSave={onSaveConfirm}
        withAction={true}
        isLoading={isLoading}
      />
      <Modal
        isOpen={isOpen}
        handleClose={handleClose}
        size="lg"
        type="success"
        padding="p-0"
        title="Product Information"
      >
        <WrapperDetail>
          <WrapperImageCreate className="relative">
            {payload.imageLink && <img src={payload.imageLink} alt="img" className="h-full w-full object-contain" />}
            <WrapperButtonEdit
              className="absolute h-[37px] w-[37px] flex flex-col justify-center items-center rounded-full bg-black -bottom-4 right-7 cursor-pointer"
              onClick={() => inputRef.click()}
            >
              <PencilIcon className="text-black" height={20} width={20} />
            </WrapperButtonEdit>
          </WrapperImageCreate>
          <WrapperFormCreate>
            <input ref={(refParam) => (inputRef = refParam)} type="file" onChange={handleFileSelected} hidden={true} />
            <WrapperTextInput>
              <span>Product Name</span>
              <input
                type="text"
                value={payload.productName}
                placeholder="Input Product Name"
                name="productName"
                onChange={handleChange}
              />
            </WrapperTextInput>
            <WrapperTextInput>
              <span>Choose Cetegory</span>
              <Dropdown
                options={optionCategories}
                selectedOption={optionCategories.filter((opt) => opt.id === payload.productType)[0]}
                handelChange={(e) => {
                  // setSelectedCategory(e);
                  setPayload({ ...payload, productType: e.id });
                }}
                placeHolder="Input Category"
              />
            </WrapperTextInput>
            <WrapperTextInput>
              <span>Description</span>
              <textarea
                defaultValue={payload.productDescription}
                rows={8}
                placeholder="Input Description"
                onChange={handleDescription}
              />
            </WrapperTextInput>
            <WrapperTextInput>
              <span>Choose Environment</span>
              <Dropdown
                options={optionPresets}
                selectedOption={
                  optionPresets.filter((opt) => {
                    return opt.id === payload.preset;
                  })[0]
                }
                handelChange={(e) => {
                  setPayload({ ...payload, preset: e.id });
                }}
                placeHolder="Choose Environment"
              />
            </WrapperTextInput>
            <WrapperButtonCreate>
              <Button
                variant="outlined"
                variants="dangerOutlined"
                size="lg"
                className="w-full"
                onClick={() => {
                  onDeleteConfirm();
                  setModalData({
                    title: "Delete Product",
                    description: "Are you sure, you want to delete this product?  ",
                    typeModal: "dangerOutlined",
                    typeConfirm: "dangerOutlined",
                    isError: false,
                  });
                  setopenNotif(true);
                }}
              >
                Delete
              </Button>
              <Button
                variant="filled"
                variants="mainFilled"
                size="lg"
                className="w-full"
                type="button"
                // disabled={isLoading}
                onClick={() => {
                  setModalData({
                    title: "Edit Product",
                    description: "Are you sure, you want to update this product information? ",
                    typeModal: "success",
                    typeConfirm: "success",
                    isError: false,
                  });
                  setopenNotif(true);
                }}
              >
                {/* {isLoading && <Spinner />} */}
                Save
              </Button>
            </WrapperButtonCreate>
          </WrapperFormCreate>
        </WrapperDetail>
      </Modal>
    </>
  );
};

export default memo(Edit);
