import { useState, useEffect } from "react";
import CardList from "./card-product-list.component";
import ModalDetail from "./detail.component";
import ModalEdit from "./edit.component";
import { WrapperProductList } from "./poduct-list.styles";
import ModalConfirm from "../../modal/modal-confirm.component";
import {
  useApproveProductRequestMutation,
  useGetProductRequestQuery,
  useRejectProductRequestMutation,
} from "../../../store/product/product.slice";
import InfiniteScroll from "react-infinite-scroll-component";
import Bounce from "../../loader/bounce.component";

const ListProduct = () => {
  const [param, setParam] = useState({
    limit: 10,
    skip: 0,
    total: 0,
    hasMore: true,
  });
  const [query, setQuery] = useState(`?approved[$ne]=true&$limit=${param.limit}&$skip=${param.skip}`);
  const { data } = useGetProductRequestQuery(query, {
    refetchOnMountOrArgChange: true,
  });
  const [approve] = useApproveProductRequestMutation();
  const [reject] = useRejectProductRequestMutation();
  const [id, setId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openNotif, setOpenNotif] = useState(false);
  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState([]);

  const [modalData, setModalData] = useState({
    title: "",
    description: "",
    typeModal: "",
    typeConfirm: "",
    isError: false,
    isApprove: true,
  });

  useEffect(() => {
    if (data) {
      const newProducts = [];
      data?.data?.map((obj) => newProducts.push(obj));
      const concatProduct = [...products, ...newProducts];
      const unique = [...new Map(concatProduct.map((item) => [item["_id"], item])).values()];
      setParam({ ...param, total: data.total, hasMore: unique.length < data.total });
      setProducts(unique);
    }
  }, [data]);

  const fetchMoreData = () => {
    setTimeout(() => {
      setQuery(`?approved[$ne]=true&$limit=${param.limit}&$skip=${param.skip + 10}`);
      setParam({ ...param, skip: param.skip + 10 });
    }, 500);
  };

  const removeProductAfterAction = () => {
    const newProduct = products.filter((obj) => obj._id !== product._id);
    setProducts(newProduct);
  };

  const onSaveConfirm = async () => {
    try {
      setIsLoading(true);

      if (modalData.isApprove) {
        console.log("Approve Product Request with Payload : ", {
          approved: true,
        });
        console.log(
          `Approve Product Request URL PATCH : ${process.env.REACT_APP_API_BASE_URL}/product-template-request/${id}`
        );
        const response = await approve({
          id,
          payload: { approved: true },
        }).unwrap();
        console.log("Approve Product Request Response : ", response);

        setModalData({
          title: "Product Request Approved",
          subTitle: "Approved",
          description: "You’ve successfully approve this product request.",
          typeModal: "",
          typeConfirm: "success",
          isError: false,
        });
      } else {
        console.log("Reject Product Request with ID : ", id);
        console.log(
          `Reject Product Request URL PATCH : ${process.env.REACT_APP_API_BASE_URL}/product-template-request/${id}`
        );
        const response = await reject({
          id,
        }).unwrap();
        console.log("Reject Product Request Response : ", response);

        setModalData({
          title: "Product Request Rejected",
          subTitle: "Rejected",
          description: "You’ve successfully reject this product request.",
          typeModal: "",
          typeConfirm: "success",
          isError: false,
        });
      }

      // setTimeout(() => {
      removeProductAfterAction();
      setOpenConfirm(false);
      setOpenNotif(true);
      setIsLoading(false);
      // }, 700);
    } catch (err) {
      console.log("Product Request Response : ", err);
      setModalData({
        title: "Failed",
        subTitle: "Error",
        description: err.message,
        typeModal: "failed",
        typeConfirm: "failed",
        isError: false,
      });

      setTimeout(() => {
        setOpenConfirm(false);
        setOpenNotif(true);
        setIsLoading(false);
      }, 700);
    }
  };

  return (
    <>
      {openDetail && (
        <ModalDetail
          isOpen={openDetail}
          handleClose={() => setOpenDetail(false)}
          id={id}
          setOpenConfirm={setOpenConfirm}
          setModalData={setModalData}
          product={product}
        />
      )}

      {openEdit && (
        <ModalEdit
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          modalData={modalData}
          setModalData={setModalData}
          setOpenConfirm={() => setOpenConfirm(true)}
          product={product}
        />
      )}

      {openConfirm && (
        <ModalConfirm
          isOpen={openConfirm}
          handleClose={() => setOpenConfirm(false)}
          title={modalData.title}
          description={modalData.description}
          typeModal={modalData.typeModal}
          typeConfirm={modalData.typeConfirm}
          isError={modalData.isError}
          handleSave={onSaveConfirm}
          withAction={true}
          isLoading={isLoading}
          titleButton={modalData.isApprove ? "Approve" : "Reject"}
        />
      )}

      {openNotif && (
        <ModalConfirm
          isOpen={openNotif}
          handleClose={() => setOpenNotif(false)}
          title={modalData.title}
          description={modalData.description}
          typeModal={modalData.type}
          typeConfirm={modalData.typeConfirm}
          isError={modalData.isError}
        />
      )}
      <em className="text-total-data">
        Showing {products.length} out of {param.total}
      </em>
      <InfiniteScroll
        dataLength={products.length}
        next={fetchMoreData}
        hasMore={param.hasMore}
        loader={
          <div className="flex flex-row w-100 justify-center items-center mt-10">
            <Bounce />
          </div>
        }
        scrollableTarget="scrollableDiv"
      >
        <WrapperProductList>
          {products?.map((product, index) => (
            <CardList
              key={index}
              product={product}
              openDetail={() => {
                setProduct(product);
                setOpenDetail(true);
              }}
              openEdit={() => {
                setProduct(product);
                setOpenEdit(true);
              }}
              setIdDetail={setId}
            />
          ))}
        </WrapperProductList>
      </InfiniteScroll>
    </>
  );
};

export default ListProduct;
