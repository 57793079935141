import styled from "styled-components";

export const ButtonTab = styled.div`
  width: 311px;
  height: 67px;
  padding: 23px;
  background: ${(props) => (props.variants === "active" ? "#e2e8f0" : "#F8FAFC")};
  border-radius: 10px;

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #64748b;
  }
`;

export const Table = styled.table`
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #64748b;
  }
`;

export const WrapperContent = styled.div`
  background: #f8fafc;
  border-radius: 10px;

  .title-menu {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #64748b;
  }

  label {
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #94a3b8;
  }

  input {
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    padding: 12px 20px 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #64748b;
  }
`;

export const WrapperImage = styled.div`
  width: 88px;
  height: 88px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #43c667;
`;

export const WrapperButton = styled.div`
  background: ${(props) => (props.variant === "active" ? "#e2e8f0" : "transparent")};
  border-radius: 10px;
  border: 1px solid #e2e8f0;
  padding: 16px 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #64748b;
  width: 210px;
`;

export const WrapperTable = styled.div`
  background-color: #f8fafc;
  padding: 22px 22px;
  border-radius: 10px;

  .span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #94a3b8;
  }

  .code-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #64748b;
  }

  .active {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #43c667;
  }

  .inactive {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #e94141;
  }
`;

export const WrapperFormCreate = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px 2rem;
  background-color: #f8fafc;
  border-radius: 0px 0px 18px 18px;
  height: 360px;

  @media screen and (max-width: 767px) {
    padding: 20px 1.5rem;
  }
`;

export const WrapperTextInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #94a3b8;
  }

  input {
    padding: 8px 25px 8px 20px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    width: 100%;
    color: #64748b;

    ::placeholder {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 16px;
    }
  }

  select {
    padding: 9px 25px 9px 20px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    width: 100%;

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #64748b;
  }

  textarea {
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    padding: 12px 25px 8px 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #64748b;

    ::placeholder {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 16px;
    }
  }
`;
