import React from "react";
import { MainArea } from "./Main.styles";

const Main = ({ children }) => {
  return (
    <MainArea id="scrollableDiv" className="px-3 py-3">
      {children}
    </MainArea>
  );
};

export default Main;
