import styled from "styled-components";

export const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #f8fafc;
  border-radius: 0px 0px 18px 18px;
`;

export const WrapperTextInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #94a3b8;
  }

  input {
    padding: 8px 25px 8px 20px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    width: 100%;

    ::placeholder {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #64748b;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .txt-qty {
    padding: 0px;
    text-align: center;
    width: 20%;
  }

  select {
    padding: 9px 25px 9px 20px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    width: 100%;

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #64748b;
  }
`;

export const WrapperButton = styled.div.attrs({
  className: "flex flex-row w-100 justify-center items-center pt-14 mb-4 gap-5 px-6",
})``;
