import Modal from "../../modal/modal.component";
import { WrapperTable } from "../styles";
import { WrapperTextInput } from "./preset.styles";
import Button from "../../button/button.component";
import Spinner from "../../loader/spinner.component";

const CreateRange = ({
  isOpen,
  handleClose,
  payload,
  setPayload,
  handleSubmit,
  isLoading,
}) => {
  console.log("🚀 ~ file: create-range.component.jsx:15 ~ payload", payload)
  return (
    <>
      <Modal
        isOpen={isOpen}
        handleClose={handleClose}
        size="xl"
        title="Create New Presets - Range"
        type="success"
      >
        <form className="flex flex-col justify-start gap-2">
          <WrapperTable className="border-separate border-spacing-2 py-0 my-0">
            <tbody>
              <tr>
                <td className="pr-3 span whitespace-nowrap w-[20%]">
                  Air CO2 Top
                </td>
                <td className="w-[30%]">
                  <WrapperTextInput>
                    <input
                      type="text"
                      value={payload.range.air_co2_top}
                      placeholder="0"
                      name="light_on"
                      onChange={(e) => {
                        setPayload({
                          ...payload,
                          range: {
                            ...payload.range,
                            air_co2_top: e.target.value,
                          },
                        });
                      }}
                    />
                  </WrapperTextInput>
                </td>
                <td className="px-3 span  whitespace-nowrap  w-[20%]">
                  Air CO2 Bottom
                </td>
                <td className="w-[30%]">
                  <WrapperTextInput>
                    <input
                      type="text"
                      value={payload.range.air_co2_bottom}
                      placeholder="0"
                      name="light_off"
                      onChange={(e) => {
                        setPayload({
                          ...payload,
                          range: {
                            ...payload.range,
                            air_co2_bottom: e.target.value,
                          },
                        });
                      }}
                    />
                  </WrapperTextInput>
                </td>
              </tr>
              <tr>
                <td className="pr-3 span whitespace-nowrap w-[20%]">
                  Air Temp Top
                </td>
                <td className="w-[30%]">
                  <WrapperTextInput>
                    <input
                      type="text"
                      value={payload.range.air_temp_top}
                      placeholder="0"
                      name="light_on"
                      onChange={(e) => {
                        setPayload({
                          ...payload,
                          range: {
                            ...payload.range,
                            air_temp_top: e.target.value,
                          },
                        });
                      }}
                    />
                  </WrapperTextInput>
                </td>
                <td className="px-3 span  whitespace-nowrap  w-[20%]">
                  Air Temp Bottom
                </td>
                <td className="w-[30%]">
                  <WrapperTextInput>
                    <input
                      type="text"
                      value={payload.range.air_temp_bottom}
                      placeholder="0"
                      name="light_off"
                      onChange={(e) => {
                        setPayload({
                          ...payload,
                          range: {
                            ...payload.range,
                            air_temp_bottom: e.target.value,
                          },
                        });
                      }}
                    />
                  </WrapperTextInput>
                </td>
              </tr>
              <tr>
                <td className="pr-3 span whitespace-nowrap w-[20%]">
                  Air Humidity Top
                </td>
                <td className="w-[30%]">
                  <WrapperTextInput>
                    <input
                      type="text"
                      value={payload.range.air_humidity_top}
                      placeholder="0"
                      name="light_on"
                      onChange={(e) => {
                        setPayload({
                          ...payload,
                          range: {
                            ...payload.range,
                            air_humidity_top: e.target.value,
                          },
                        });
                      }}
                    />
                  </WrapperTextInput>
                </td>
                <td className="px-3 span  whitespace-nowrap  w-[20%]">
                  Air Humidity Bottom
                </td>
                <td className="w-[30%]">
                  <WrapperTextInput>
                    <input
                      type="text"
                      value={payload.range.air_humidity_bottom}
                      placeholder="0"
                      name="light_off"
                      onChange={(e) => {
                        setPayload({
                          ...payload,
                          range: {
                            ...payload.range,
                            air_humidity_bottom: e.target.value,
                          },
                        });
                      }}
                    />
                  </WrapperTextInput>
                </td>
              </tr>
              <tr>
                <td className="pr-3 span whitespace-nowrap w-[20%]">
                  Air Acid Top
                </td>
                <td className="w-[30%]">
                  <WrapperTextInput>
                    <input
                      type="text"
                      value={payload.range.water_acid_top}
                      placeholder="0"
                      name="light_on"
                      onChange={(e) => {
                        setPayload({
                          ...payload,
                          range: {
                            ...payload.range,
                            water_acid_top: e.target.value,
                          },
                        });
                      }}
                    />
                  </WrapperTextInput>
                </td>
                <td className="px-3 span  whitespace-nowrap  w-[20%]">
                  Air Acid Bottom
                </td>
                <td className="w-[30%]">
                  <WrapperTextInput>
                    <input
                      type="text"
                      value={payload.range.water_acid_bottom}
                      placeholder="0"
                      name="light_off"
                      onChange={(e) => {
                        setPayload({
                          ...payload,
                          range: {
                            ...payload.range,
                            water_acid_bottom: e.target.value,
                          },
                        });
                      }}
                    />
                  </WrapperTextInput>
                </td>
              </tr>
              <tr>
                <td className="pr-3 span whitespace-nowrap w-[20%]">
                  Water Temp Top
                </td>
                <td className="w-[30%]">
                  <WrapperTextInput>
                    <input
                      type="text"
                      value={payload.range.water_temp_top}
                      placeholder="0"
                      name="light_on"
                      onChange={(e) => {
                        setPayload({
                          ...payload,
                          range: {
                            ...payload.range,
                            water_temp_top: e.target.value,
                          },
                        });
                      }}
                    />
                  </WrapperTextInput>
                </td>
                <td className="px-3 span  whitespace-nowrap  w-[20%]">
                  Water Temp Bottom
                </td>
                <td className="w-[30%]">
                  <WrapperTextInput>
                    <input
                      type="text"
                      value={payload.range.water_temp_bottom}
                      placeholder="0"
                      name="light_off"
                      onChange={(e) => {
                        setPayload({
                          ...payload,
                          range: {
                            ...payload.range,
                            water_temp_bottom: e.target.value,
                          },
                        });
                      }}
                    />
                  </WrapperTextInput>
                </td>
              </tr>
              <tr>
                <td className="pr-3 span whitespace-nowrap w-[20%]">
                  Water Nutrient Top
                </td>
                <td className="w-[30%]">
                  <WrapperTextInput>
                    <input
                      type="text"
                      value={payload.range.water_nutrient_top}
                      placeholder="0"
                      name="light_on"
                      onChange={(e) => {
                        setPayload({
                          ...payload,
                          range: {
                            ...payload.range,
                            water_nutrient_top: e.target.value,
                          },
                        });
                      }}
                    />
                  </WrapperTextInput>
                </td>
                <td className="px-3 span  whitespace-nowrap  w-[20%]">
                  Water Nutrient Bottom
                </td>
                <td className="w-[30%]">
                  <WrapperTextInput>
                    <input
                      type="text"
                      value={payload.range.water_nutrient_bottom}
                      placeholder="0"
                      name="light_off"
                      onChange={(e) => {
                        setPayload({
                          ...payload,
                          range: {
                            ...payload.range,
                            water_nutrient_bottom: e.target.value,
                          },
                        });
                      }}
                    />
                  </WrapperTextInput>
                </td>
              </tr>
            </tbody>
          </WrapperTable>

          <Button
            variants="mainFilled"
            size="lg"
            type="button"
            className="mt-10 mb-2"
            onClick={handleSubmit}
          >
            {isLoading && <Spinner />}
            Save
          </Button>
        </form>
      </Modal>
    </>
  );
};

export default CreateRange;
