import styled from "styled-components";

export const WrapperDetail = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapperImageDetail = styled.div`
  height: 120px;
  background: #cbd5e1;
`;

export const WrapperDescription = styled.div.attrs({
  className: "flex flex-col gap-4 p-6",
})`
  height: 392px;
`;

export const Description = styled.div.attrs({
  className: "flex justify-between",
})`
  .label-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #94a3b8;
  }

  .value-desc {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #64748b;
  }

  .value-orange {
    color: #ffa24d;
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  background: #ffffff;
`;

export const WrapperConfirm = styled.div.attrs({
  className: "bg-white flex flex-col justify-center items-center text-center gap-2",
})`
  height: 560px;

  span {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #43c667;
  }

  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #64748b;
  }
`;

export const WrappertConfirmImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 148.8px;
  height: 148.8px;
  background: #f1f5f9;
  border-radius: 100px;
`;

export const WrapperConfirmButton = styled.div.attrs({
  className: "px-14 pb-3",
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80px;
  background: #ffffff;
  gap: 1rem;
  width: 100%;
`;

export const WrapperForm = styled.form.attrs({ className: "p-6" })`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const WrapperTextInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #94a3b8;
  }

  input {
    padding: 8px 25px 8px 20px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    /* width: 100%; */
    color: #64748b;

    ::placeholder {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }
  }

  select {
    padding: 9px 25px 9px 20px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    width: 100%;

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #64748b;
  }

  textarea {
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    padding: 12px 25px 8px 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #64748b;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .txt-qty {
    padding: 0px;
    text-align: center;
    width: 20%;
  }
`;
