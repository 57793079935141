/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import CardList from "./card-product-list.component";
import ModalDetail from "./detail.component";
import ModalEdit from "./edit.component";
import { WrapperProductList } from "./poduct-list.styles";
import ModalConfirm from "../../modal/modal-confirm.component";
import InfiniteScroll from "react-infinite-scroll-component";
import { useGetProductTemplateQueryQuery } from "../../../store/product/product.slice";
import Bounce from "../../loader/bounce.component";

const ListProduct = () => {
  const [id, setId] = useState("");
  const [param, setParam] = useState({
    limit: 10,
    skip: 0,
    total: 0,
    hasMore: true,
  });
  const [query, setQuery] = useState(`?$limit=${param.limit}&$skip=${param.skip}`);
  const { data } = useGetProductTemplateQueryQuery(query, {
    refetchOnMountOrArgChange: true,
  });
  const [openDetail, setOpenDetail] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [products, setProduct] = useState([]);

  const [modalData, setModalData] = useState({
    title: "",
    description: "",
    typeModal: "",
    typeConfirm: "",
    isError: false,
  });

  useEffect(() => {
    if (data) {
      const newProducts = [];
      data?.data?.map((obj) => newProducts.push(obj));
      const concatProduct = [...products, ...newProducts];
      const unique = [...new Map(concatProduct.map((item) => [item["_id"], item])).values()];
      setParam({ ...param, total: data.total, hasMore: unique.length < data.total });
      setProduct(unique);
    }
  }, [data]);

  const fetchMoreData = () => {
    setTimeout(() => {
      setQuery(`?$limit=${param.limit}&$skip=${param.skip + 10}`);
      setParam({ ...param, skip: param.skip + 10 });
      
    }, 500);
  };

  return (
    <>
      {openDetail && <ModalDetail isOpen={openDetail} handleClose={() => setOpenDetail(false)} id={id} />}

      {openEdit && (
        <ModalEdit
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          modalData={modalData}
          setModalData={setModalData}
          setOpenConfirm={() => setOpenConfirm(true)}
        />
      )}

      {openConfirm && (
        <ModalConfirm
          isOpen={openConfirm}
          handleClose={() => setOpenConfirm(false)}
          title={modalData.title}
          description={modalData.description}
          typeModal={modalData.type}
          typeConfirm={modalData.typeConfirm}
          isError={modalData.isError}
        />
      )}
      <em className="text-total-data">
        Showing {products.length} out of {param.total}
      </em>
      <InfiniteScroll
        dataLength={products.length}
        next={fetchMoreData}
        hasMore={param.hasMore}
        loader={
          <div className="flex flex-row w-100 justify-center items-center mt-10">
            <Bounce />
          </div>
        }
        scrollableTarget="scrollableDiv"
      >
        <WrapperProductList>
          {products?.map((product, index) => (
            <CardList
              key={index}
              product={product}
              openDetail={() => setOpenDetail(true)}
              openEdit={() => setOpenEdit(true)}
              setIdDetail={setId}
            />
          ))}
        </WrapperProductList>
      </InfiniteScroll>
    </>
  );
};

export default ListProduct;
