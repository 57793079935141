import Modal from "../../modal/modal.component";
import { WrapperTextInput, WrapperTable } from "./preset.styles";
import Button from "../../button/button.component";
import { useState } from "react";
import produce from "immer";
import { useEffect } from "react";

const Create = ({ isOpen, handleClose, preset = null, payload, setPayload, openCreateTime }) => {
  const [airCO, setAirCO] = useState({
    light_off: "",
    light_on: "",
  });
  const [waterNutrientRatio, setWaterNutrientRatio] = useState({
    nutrient_a: 0,
    nutrient_b: 0,
    nutrient_n: 0,
  });

  const onNext = () => {
    setPayload({
      ...payload,
      water_nutrient_ratio: waterNutrientRatio,
      air_co2: airCO,
    });
    handleClose();
    openCreateTime();
  };

  const handleChange = (value, index, label) => {
    const nextState = produce(payload.air_temp, (draftState) => {
      payload.lights_timer.map((obj, idx) => {
        if (idx === index) {
          draftState[index][`${label}`] = value;
        }
      });
    });

    setPayload({ ...payload, air_temp: nextState });
  };

  useEffect(() => {
    if (preset) {
      const configuration = preset.configuration;
      setAirCO({
        light_on: configuration.air_co2?.light_on,
        light_off: configuration.air_co2?.light_off,
      });
      setWaterNutrientRatio({
        nutrient_a: configuration.water_nutrient_ratio?.nutrient_a,
        nutrient_b: configuration.water_nutrient_ratio?.nutrient_b,
        nutrient_n: configuration.water_nutrient_ratio?.nutrient_n,
      });

      const lightTimer = [];
      configuration.lights_timer?.map((timers) => {
        let newTimer = [];
        timers.map((obj) => newTimer.push(obj));
        lightTimer.push(newTimer);
      });

      setPayload({
        _id: preset._id,
        label: preset.label,
        air_temp: configuration.air_temp,
        air_humidity: configuration.air_humidity,
        air_co2: configuration.air_co2,
        water_acid: configuration.water_acid,
        water_nutrient: configuration.water_nutrient,
        water_nutrient_ratio: configuration.water_nutrient_ratio,
        lights_timer: lightTimer,
        range: {
          ...preset.range,
        },
      });
    }
  }, [preset, setPayload]);

  return (
    <>
      <Modal isOpen={isOpen} handleClose={handleClose} size="md" title="Create New Presets" type="success">
        <form className="flex flex-col gap-2">
          <WrapperTextInput>
            <input
              type="text"
              placeholder="Preset Name"
              name="label"
              value={payload.label}
              onChange={(e) => {
                setPayload({ ...payload, label: e.target.value });
              }}
            />
          </WrapperTextInput>
          <hr className="text-grey-300 my-1" />
          <WrapperTextInput className="py-0 my-0">
            <span>Air Temperature</span>
          </WrapperTextInput>
          <WrapperTable className="border-separate border-spacing-2 py-0 my-0">
            <tbody>
              {payload.air_temp.map(({ light_on, light_off }, index) => (
                <tr key={index}>
                  <td className="pr-3 span whitespace-nowrap w-[20%]">Light On</td>
                  <td className="w-[30%]">
                    <WrapperTextInput>
                      <input
                        type="text"
                        value={light_on}
                        placeholder="00°C"
                        name="light_on"
                        onChange={(e) => handleChange(e.target.value, index, "light_on")}
                      />
                    </WrapperTextInput>
                  </td>
                  <td className="px-3 span  whitespace-nowrap  w-[20%]">Light Off</td>
                  <td className="w-[30%]">
                    <WrapperTextInput>
                      <input
                        type="text"
                        value={light_off}
                        placeholder="00°C"
                        name="light_off"
                        onChange={(e) => handleChange(e.target.value, index, "light_off")}
                      />
                    </WrapperTextInput>
                  </td>
                </tr>
              ))}
            </tbody>
          </WrapperTable>
          <hr className="text-grey-300 my-1" />
          <WrapperTable className="border-separate border-spacing-2">
            <tbody>
              <tr>
                <td className="span w-[20%]">Humidity</td>
                <td className="w-[30%]">
                  <WrapperTextInput>
                    <input
                      type="text"
                      value={payload.air_humidity}
                      placeholder="00°C"
                      name="light_off"
                      className="number"
                      onChange={(e) => {
                        setPayload({
                          ...payload,
                          air_humidity: e.target.value,
                        });
                      }}
                    />
                  </WrapperTextInput>
                </td>
                <td className="span w-[20%] px-3">CO2</td>
                <td className="w-[30%]">
                  <WrapperTextInput>
                    <div className="flex flex-row gap-2">
                      <input
                        type="text"
                        placeholder="On"
                        name="light_off"
                        value={airCO.light_on}
                        onChange={(e) => {
                          setAirCO({ ...airCO, light_on: e.target.value });
                        }}
                      />
                      <input
                        type="text"
                        placeholder="Off"
                        name="light_off"
                        value={airCO.light_off}
                        onChange={(e) => {
                          setAirCO({ ...airCO, light_off: e.target.value });
                        }}
                        className="number"
                      />
                    </div>
                  </WrapperTextInput>
                </td>
              </tr>
            </tbody>
          </WrapperTable>
          <WrapperTable className="border-separate border-spacing-2">
            <tbody>
              <tr>
                <td className="span w-[20%]">Acidity</td>
                <td className="w-[30%]">
                  <WrapperTextInput>
                    <input
                      type="text"
                      value={payload.water_acid}
                      placeholder="00 pH"
                      name="water_acid"
                      className="number"
                      onChange={(e) => {
                        setPayload({ ...payload, water_acid: e.target.value });
                      }}
                    />
                  </WrapperTextInput>
                </td>
                <td className="span w-[20%] px-3">Nutrient</td>
                <td className="w-[30%]">
                  <WrapperTextInput>
                    <div className="flex flex-row gap-2">
                      <input
                        type="text"
                        placeholder="0000 PPM"
                        name="water_nutrient"
                        value={payload.water_nutrient}
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            water_nutrient: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </WrapperTextInput>
                </td>
              </tr>
            </tbody>
          </WrapperTable>

          <Button variants="mainFilled" size="lg" type="button" className="mt-10 mb-2" onClick={() => onNext()}>
            Next
          </Button>
        </form>
      </Modal>
    </>
  );
};

export default Create;
