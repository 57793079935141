import { useState, memo } from "react";
import ModalCreatePreset from "./create.component";
import Button from "../../button/button.component";
import ModalCreateTime from "./create-time.component";
import { useCreatePresetMutation } from "../../../store/presets/presets.slice";
import ModalRange from "./create-range.component";
import ModalConfirm from "../../modal/modal-confirm.component";

const TabsButton = () => {
  const [savePreset] = useCreatePresetMutation();
  const [openCreatePreset, setOpenCreatePreset] = useState(false);
  const [openCreateTime, setOpenCreateTime] = useState(false);
  const [openRange, setOpenRange] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [payload, setPayload] = useState({
    _id: "",
    label: "",
    air_temp: [{ light_on: "", light_off: "" }],
    air_humidity: "",
    air_co2: "",
    water_acid: "",
    water_nutrient: "",
    water_nutrient_ratio: "",
    lights_timer: [
      [
        {
          start: "00:00:00",
          end: "00:00:00",
        },
      ],
    ],
    range: {
      air_co2_top: "",
      air_co2_bottom: "",
      air_temp_top: "",
      air_temp_bottom: "",
      air_humidity_top: "",
      air_humidity_bottom: "",
      water_acid_top: "",
      water_acid_bottom: "",
      water_temp_top: "",
      water_temp_bottom: "",
      water_nutrient_top: "",
      water_nutrient_bottom: "",
    },
  });

  const [modalData, setModalData] = useState({
    title: "GREENS Pod Added",
    description: "You’ve successfully add new GREENS Pod.",
    typeModal: "",
    typeConfirm: "success",
    isError: false,
  });

  const tabList = [
    {
      id: "garminating",
      label: "Garmination",
    },
    {
      id: "seedling",
      label: "Seedling",
    },
    {
      id: "greener",
      label: "Greener",
    },
  ];

  const handleSubmit = async () => {
    setIsLoading(true);

    // const lights_timer = [];
    // payload.lights_timer?.map((time) => lights_timer.push([time]));

    const newPayload = {
      label: payload.label,
      configuration: {
        air_temp: [
          {
            light_on: payload.air_temp[0].light_on,
            light_off: payload.air_temp[0].light_off,
          },
        ],
        air_humidity: payload.air_humidity,
        air_co2: payload.air_co2,
        water_acid: payload.water_acid,
        water_nutrient: payload.water_nutrient,
        water_nutrient_ratio: payload.water_nutrient_ratio,
        lights_timer: payload.lights_timer,
      },
      range: {
        air_co2_top: payload.range.air_co2_top ? parseFloat(payload.range.air_co2_top) : null,
        air_co2_bottom: payload.range.air_co2_bottom ? parseFloat(payload.range.air_co2_bottom) : null,
        air_temp_top: payload.range.air_temp_top ? parseFloat(payload.range.air_temp_top) : null,
        air_temp_bottom: payload.range.air_temp_bottom ? parseFloat(payload.range.air_temp_bottom) : null,
        air_humidity_top: payload.range.air_humidity_top ? parseFloat(payload.range.air_humidity_top) : null,
        air_humidity_bottom: payload.range.air_humidity_bottom ? parseFloat(payload.range.air_humidity_bottom) : null,
        water_temp_top: payload.range.water_temp_top ? parseFloat(payload.range.water_temp_top) : null,
        water_temp_bottom: payload.range.water_temp_bottom ? parseFloat(payload.range.water_temp_bottom) : null,
        water_nutrient_top: payload.range.water_nutrient_top ? parseFloat(payload.range.water_nutrient_top) : null,
        water_nutrient_bottom: payload.range.water_nutrient_bottom
          ? parseFloat(payload.range.water_nutrient_bottom)
          : null,
        water_acid_top: payload.range.water_acid_top ? parseFloat(payload.range.water_acid_top) : null,
        water_acid_bottom: payload.range.water_acid_bottom ? parseFloat(payload.range.water_acid_bottom) : null,
      },
    };

    try {
      console.log(`Save Data Preset with Payload : `, newPayload);
      console.log(`Save Data Preset URL POST : ${process.env.REACT_APP_API_BASE_URL}/pod-preset`);
      const response = await savePreset(newPayload).unwrap();
      console.log(`Save Data Preset Response : `, response);

      setModalData({
        title: "Presets Added",
        description: "You’ve successfully added presets",
        typeModal: "",
        typeConfirm: "success",
      });

      setTimeout(() => {
        setOpenRange(false);
        setIsLoading(false);
        setOpenConfirm(true);
      }, 1000);
    } catch (error) {
      setModalData({
        title: error?.data?.name,
        description: error?.data?.message,
        typeModal: "failed",
        typeConfirm: "failed",
        isError: true,
      });
      setOpenRange(false);
      setOpenConfirm(true);
    }
  };

  return (
    <>
      {openCreatePreset && (
        <ModalCreatePreset
          isOpen={openCreatePreset}
          handleClose={() => setOpenCreatePreset(false)}
          payload={payload}
          setPayload={setPayload}
          openCreateTime={() => {
            setOpenCreatePreset(false);
            setOpenCreateTime(true);
          }}
        />
      )}

      {openCreateTime && (
        <ModalCreateTime
          isOpen={openCreateTime}
          handleClose={() => setOpenCreateTime(false)}
          payload={payload}
          setPayload={setPayload}
          openRange={() => {
            setOpenCreateTime(false);
            setOpenRange(true);
          }}
        />
      )}

      {openRange && (
        <ModalRange
          isOpen={openRange}
          handleClose={() => setOpenRange(false)}
          payload={payload}
          setPayload={setPayload}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
        />
      )}

      {openConfirm && (
        <ModalConfirm
          isOpen={openConfirm}
          handleClose={() => setOpenConfirm(false)}
          title={modalData.title}
          description={modalData.description}
          typeConfirm={modalData.typeConfirm}
          typeModal={modalData.type}
          isError={modalData.isError}
        />
      )}

      <div className="flex justify-between w-100">
        <div className="flex flex-row gap-3 ">
          {/* {tabList.map(({ id, label }) => (
            <Button
              key={id}
              variant="outlined"
              variants={tab === id ? `mainFilled` : 'mainOutlined'}
              size="md"
              onClick={() => setTab(id)}
            >
              {label}
            </Button>
          ))} */}
        </div>
        <div className="flex w-100">
          <Button
            variant="outlined"
            variants="mainFilled"
            size="md"
            className="flex justify-center items-center"
            onClick={() => setOpenCreatePreset(true)}
          >
            {/* <PlusIcon height={20} width={20} /> */}
            Add New Presets
          </Button>
        </div>
      </div>
    </>
  );
};

export default memo(TabsButton);
