/* eslint-disable array-callback-return */
import Modal from "../modal/modal.component";
import ModalConfirm from "../modal/modal-confirm.component";
import { WrapperTextInput } from "./styles";
import { useGetUserByIdQuery } from "../../store/user/user.slice";
import {
  useGetPodsQuery,
  useAssignUserPodMutation,
  useUnAssignUserPodMutation,
  useGetPodsByUserIdQuery,
} from "../../store/pod/pod.slice";
import Button from "../button/button.component";
import Autocomplete from "../dropdown/autocomplete.component";
import { useEffect, useState } from "react";

const ManagePod = ({ isOpen, handleClose, id }) => {
  const { data: pods, isLoading, isSuccess } = useGetPodsQuery("?$limit=100");
  const { data: userPods, isLoadingByUser, isSuccessByUser } = useGetPodsByUserIdQuery(`&userId=${id}`);
  const [assignPod] = useAssignUserPodMutation();
  const [deletAssignPod] = useUnAssignUserPodMutation();
  const { data: user } = useGetUserByIdQuery(id);
  const [optionsPods, setOptionPods] = useState([]);
  const [pod, setPod] = useState({ id: "", name: "" });
  const [query, setQuery] = useState("");
  const [selectedPods, setSelectedPod] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    description: "",
    typeModal: "",
    typeConfirm: "",
    isError: false,
  });

  const onAdd = async () => {
    let oldPods = [...selectedPods];
    oldPods.push(pod);

    try {
      const payload = {
        userId: id,
        podId: pod.id,
      };
      console.log(`Assign Pod with Payload : `, payload);
      console.log(`Assign Pod URL POST :  ${process.env.REACT_APP_API_BASE_URL}/user-pod`);
      const response = await assignPod(payload).unwrap();
      console.log(`Assign Pod URL Response : `, response);

      setModalData({
        title: "Updated",
        description: "You’ve successfully update pods under this user management.",
        typeConfirm: "success",
        isError: false,
      });

      setTimeout(() => {
        setSelectedPod(oldPods);
        setOpenConfirm(true);
      }, 1000);
    } catch (err) {
      console.log(`Assign Pod URL Response : `, err);
      setModalData({
        title: "Failed",
        description: err?.data?.message,
        typeConfirm: "failed",
        isError: true,
      });
      setTimeout(() => {
        setOpenConfirm(true);
      }, 1000);
    }
  };

  const onDelete = async (id) => {
    try {
      console.log(`Delete Assign Pod with Payload ID : `, id);
      console.log(`Delete Assign Pod URL DELETE :  ${process.env.REACT_APP_API_BASE_URL}/user-pod/${id}`);
      const response = await deletAssignPod(id).unwrap();
      console.log(`Delete Assign Pod URL Response : `, response);

      setModalData({
        title: "Updated",
        description: "You’ve successfully delete pod under this user management.",
        typeConfirm: "success",
        isError: false,
      });

      setTimeout(() => {
        const newSelectedPods = selectedPods.filter((obj) => obj.id !== id);
        setSelectedPod(newSelectedPods);
        setOpenConfirm(true);
      }, 1000);
    } catch (err) {
      console.log(`Delete Assign Pod URL Response : `, err);
      setModalData({
        title: "Errors",
        description: err?.data?.message,
        typeConfirm: "failed",
        isError: true,
      });
      setTimeout(() => {
        setOpenConfirm(true);
      }, 1000);
    }
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      let arrayPods = [];
      pods?.data?.map(({ _id, codeName }) => {
        arrayPods.push({ id: _id, name: codeName });
      });

      setOptionPods(arrayPods);
    }
  }, [isLoading, isSuccess, pods]);

  useEffect(() => {
    let pods = [];
    userPods?.ids?.map((id) => {
      pods.push({
        id: userPods?.entities[id]?._id,
        name: userPods?.entities[id]?.pod?.codeName,
      });
    });
    setSelectedPod(pods);
  }, [isLoadingByUser, isSuccessByUser, userPods]);

  return (
    <>
      <ModalConfirm
        isOpen={openConfirm}
        handleClose={() => setOpenConfirm(false)}
        title={modalData.title}
        description={modalData.description}
        typeConfirm={modalData.typeConfirm}
        typeModal={modalData.typeModal}
        isError={modalData.isError}
      />
      <Modal
        isOpen={isOpen}
        handleClose={handleClose}
        type="success"
        title={user?.name ? user?.name : user?.email}
        size="lg"
      >
        <WrapperTextInput>
          <span>GREENS Pod</span>
          <div className="flex gap-5 w-full items-center">
            <Autocomplete
              options={optionsPods}
              selected={pod}
              onChange={setPod}
              query={query}
              onChangeQuery={setQuery}
            />
            <Button size="lg" variant="filled" variants="mainFilled" type="button" className="w-1/5" onClick={onAdd}>
              Add
            </Button>
          </div>
        </WrapperTextInput>
        <WrapperTextInput className="mt-10">
          <span>Pods Management</span>
        </WrapperTextInput>
        {selectedPods &&
          selectedPods.map(({ id, name }, index) => (
            <WrapperTextInput className="py-2" key={index}>
              <div className="flex gap-5">
                <input type="text" value={name} disabled />
                <Button
                  size="lg"
                  variant="outlined"
                  variants="dangerOutlined"
                  type="button"
                  className="w-1/5"
                  onClick={() => onDelete(id)}
                >
                  Delete
                </Button>
              </div>
            </WrapperTextInput>
          ))}
        <div className="lex flex-row w-100 justify-center items-center pt-4 mb-4 gap-5">
          <Button
            variant="filled"
            variants="mainFilled"
            size="lg"
            className="w-full"
            type="button"
            onClick={() => {
              handleClose();
              setOpenConfirm(true);
              setModalData({
                title: "Updated",
                description: "You’ve successfully update pods under this user management.",
                typeModal: "",
                typeConfirm: "success",
                isError: false,
              });
            }}
          >
            Save
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ManagePod;
