import { ButtonTab } from "./styles";
import DashboardSvg from "../../assets/img/dashboard.svg";
import SecuritySVG from "../../assets/img/security.svg";
import { ChevronRightIcon } from "@heroicons/react/solid";

const Tab = ({ tab, handleClick }) => {
  return (
    <div className="flex flex-col gap-5">
      <ButtonTab
        variants={!tab ? "active" : "inactive"}
        className="flex flex-row justify-between items-center cursor-pointer"
        onClick={() => {}}
        // onClick={() => handleClick(!tab)}
      >
        <div className="flex flex-row gap-2  items-center">
          <img src={DashboardSvg} height={25} width={25} alt="icon" />
          <span className="title">Profile</span>
        </div>
        <ChevronRightIcon height={25} width={25} className="main-color" />
      </ButtonTab>
      {/* <ButtonTab
        variants={tab ? 'active' : 'inactive'}
        className="flex flex-row justify-between items-center cursor-pointer"
        onClick={() => handleClick(!tab)}
      >
        <div className="flex flex-row gap-2  items-center">
          <img src={SecuritySVG} height={25} width={25} alt="icon" />
          <span className="title">Security</span>
        </div>
        <ChevronRightIcon height={25} width={25} className="main-color" />
      </ButtonTab> */}
    </div>
  );
};

export default Tab;
