import Header from "../Header/Header";
import Main from "../Main/Main";
import { Body } from "./Wrapper.styles";
import React from "react";
import { Outlet } from "react-router-dom";
import AsideComponent from "../Aside/Aside.component";

const Wrapper = () => {
  return (
    <Body className="w-screen h-screen">
      <Header />
      <AsideComponent />
      <Main>
        <Outlet />
      </Main>
    </Body>
  );
};

export default Wrapper;
