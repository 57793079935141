import styled from "styled-components";

export const WrapperCard = styled.div`
  background: #ffffff;
  border-radius: 8px;
`;

export const Header = styled.div`
  height: 48px;
  padding: 5px 16px;

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #64748b;
  }

  .heartbeat {
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
  }
`;

export const Status = styled.div`
  border: 1px solid ${(props) => (props.variant === "greener" ? "#43C667" : "#ffa24d")};
  display: flex;
  padding: 2px 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.variant === "greener" ? "#43C667" : "#ffa24d")};
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  border-radius: 8px;
`;

export const Address = styled.div`
  height: 56px;
  padding: 8px 16px;

  span {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #64748b;
  }

  .value-orange {
    color: #d35107;
  }

  .value-address {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #94a3b8;
    overflow: hidden;
  }
`;

export const Notif = styled.div`
  height: 36px;
  /* background-color: ${(props) => (props.color === "yellow" ? "#FFE173" : "#E94141")}; */

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: ${(props) => (props.color === "yellow" ? "#64748B" : " #FFFFFF")};
  }
`;

export const Tab = styled.div`
  height: 48px;
`;

export const TabButton = styled.div`
  background: #f1f5f9;
  border-radius: 20px;

  span {
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #64748b;
  }
`;

export const Tank = styled.div`
  height: 55px;

  span {
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #64748b;
  }
`;

export const LineFilled = styled.ul`
  padding-left: 0.9rem;
  width: 250px;
  height: 20px;
  list-style: none;
  text-align: justify;
  margin: 1px auto;
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(45%, rgba(255, 255, 255, 0)),
    color-stop(51%, #cbd5e1),
    color-stop(57%, rgba(255, 255, 255, 0)),
    color-stop(100%, rgba(255, 255, 255, 0))
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 45%,
    #cbd5e1 51%,
    rgba(255, 255, 255, 0) 57%,
    rgba(255, 255, 255, 0) 100%
  );

  ::after {
    display: inline-block;
    content: "";
    width: 100%;
  }

  li {
    display: inline-block;
    width: 17px;
    height: 17px;
    background: #43c667;
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    color: #64748b;
    position: relative;
    border-radius: 50%;
    margin-right: 25px;
    border: 3px solid #f1f5f9;

    ::before {
      display: inline-block;
      content: attr(data-year);
      font-size: 11px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -20px;
    }
  }

  .RED-indicator {
    display: inline-block;
    width: 17px;
    height: 17px;
    background: #e94141;
    text-align: center;
    line-height: 1.2;
    position: relative;
    border-radius: 50%;
    margin-right: 25px;
    border: 3px solid #f1f5f9;
  }

  .GRAY-indicator {
    display: inline-block;
    width: 17px;
    height: 17px;
    background: #cbd5e1;
    text-align: center;
    line-height: 1.2;
    position: relative;
    border-radius: 50%;
    margin-right: 25px;
    border: 3px solid #f1f5f9;
  }

  .GREEN-indicator {
    display: inline-block;
    width: 17px;
    height: 17px;
    background: #43c667;
    text-align: center;
    line-height: 1.2;
    position: relative;
    border-radius: 50%;
    margin-right: 25px;
    border: 3px solid #f1f5f9;
  }

  .YELLOW-indicator {
    display: inline-block;
    width: 17px;
    height: 17px;
    background: #ffff00;
    text-align: center;
    line-height: 1.2;
    position: relative;
    border-radius: 50%;
    margin-right: 25px;
    border: 3px solid #f1f5f9;
  }
`;

export const EnvironmentContent = styled.div`
  height: 190px;
  padding: 16px 20.5px;
  background: #e2e8f0;

  span {
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #64748b;
  }
`;

export const ButtonDetails = styled.div`
  border-radius: 0px 0px 8px 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
`;

export const WrapperTextInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #94a3b8;
  }

  input {
    padding: 13px 25px 13px 20px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    width: 100%;

    /* ::placeholder { */
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #64748b;
    /* } */
  }

  select {
    padding: 9px 25px 9px 20px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    width: 100%;

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #64748b;
  }

  textarea {
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    padding: 12px 25px 8px 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #64748b;
  }
`;

export const EnvironmentContentDetail = styled.div`
  height: 389px;
  padding: 16px 20.5px;
  background: #e2e8f0;

  span {
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #64748b;
  }
`;

export const CardProduct = styled.div`
  min-height: 98px;
  max-height: 98px;
  border-radius: 8px;
`;

export const CardLeft = styled.div`
  background: #cbd5e1;
  /* width: 55px; */
  border-radius: 8px 0px 0px 8px;
`;

export const CardMiddle = styled.div`
  /* width: 179px; */
  background: #ffffff;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #64748b;
  }

  .code {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #94a3b8;
  }

  .date-since {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #94a3b8;
  }

  .date {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #43c667;
  }
`;

export const CardRight = styled.div`
  background: #43c667;
  border-radius: 0px 8px 8px 0px;

  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #ffffff;
`;
