import { WrapperList } from "../styles";
import Card from "./card.component";
import { useState } from "react";
import ModalEdit from "./create.component";
import ModalCreateTime from "./create-time.component";
import ModalRange from "./create-range.component";
import ModalConfirm from "../../modal/modal-confirm.component";
import { useUpdatePresetMutation, useDeletePresetMutation } from "../../../store/presets/presets.slice";

const ListPresets = ({ presets }) => {
  const [updatePreset] = useUpdatePresetMutation();
  const [deletePreset] = useDeletePresetMutation();
  const [presetDetail, setPresetDetail] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [openCreateTime, setOpenCreateTime] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openValidation, setOpenValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openRange, setOpenRange] = useState(false);
  const [payload, setPayload] = useState({
    _id: "",
    label: "",
    air_temp: [{ light_on: "", light_off: "" }],
    air_humidity: "",
    air_co2: "",
    water_acid: "",
    water_nutrient: "",
    water_nutrient_ratio: "",
    lights_timer: [
      [
        {
          start: "00:00:00",
          end: "00:00:00",
        },
      ],
    ],
    range: {
      air_co2_top: "",
      air_co2_bottom: "",
      air_temp_top: "",
      air_temp_bottom: "",
      air_humidity_top: "",
      air_humidity_bottom: "",
      water_acid_top: "",
      water_acid_bottom: "",
      water_temp_top: "",
      water_temp_bottom: "",
      water_nutrient_top: "",
      water_nutrient_bottom: "",
    },
  });

  const [modalData, setModalData] = useState({
    title: "GREENS Pod Added",
    description: "You’ve successfully add new GREENS Pod.",
    typeModal: "",
    typeConfirm: "success",
    isError: false,
  });

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      console.log(`Delete Data Preset ID ${presetDetail._id}`);
      console.log(
        `Delete Data Preset URL DELETE : ${process.env.REACT_APP_API_BASE_URL}/pod-preset/${presetDetail._id}`
      );
      const response = await deletePreset({
        id: presetDetail._id,
      }).unwrap();
      console.log(`Delete Data Preset Response : `, response);

      setTimeout(() => {
        setModalData({
          title: "Presets Deleted",
          description: "You’ve successfully deleted the preset",
          typeModal: "success",
          typeConfirm: "success",
        });
        setOpenValidation(false);
        setIsLoading(false);
        setOpenConfirm(true);
      }, 1000);
    } catch (error) {
      setOpenValidation(false);
      setModalData({
        title: error?.data?.name,
        description: error?.data?.message,
        typeModal: "failed",
        typeConfirm: "failed",
        isError: true,
      });
      setOpenConfirm(true);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    // const lights_timer = [];
    // payload.lights_timer?.map((time) => lights_timer.push([time]));

    const newPayload = {
      label: payload.label,
      configuration: {
        air_temp: [
          {
            light_on: payload.air_temp[0].light_on,
            light_off: payload.air_temp[0].light_off,
          },
        ],
        air_humidity: payload.air_humidity,
        air_co2: payload.air_co2,
        water_acid: payload.water_acid,
        water_nutrient: payload.water_nutrient,
        water_nutrient_ratio: payload.water_nutrient_ratio,
        lights_timer: payload.lights_timer,
      },
      range: {
        air_co2_top: payload.range.air_co2_top ? parseFloat(payload.range.air_co2_top) : null,
        air_co2_bottom: payload.range.air_co2_bottom ? parseFloat(payload.range.air_co2_bottom) : null,
        air_temp_top: payload.range.air_temp_top ? parseFloat(payload.range.air_temp_top) : null,
        air_temp_bottom: payload.range.air_temp_bottom ? parseFloat(payload.range.air_temp_bottom) : null,
        air_humidity_top: payload.range.air_humidity_top ? parseFloat(payload.range.air_humidity_top) : null,
        air_humidity_bottom: payload.range.air_humidity_bottom ? parseFloat(payload.range.air_humidity_bottom) : null,
        water_temp_top: payload.range.water_temp_top ? parseFloat(payload.range.water_temp_top) : null,
        water_temp_bottom: payload.range.water_temp_bottom ? parseFloat(payload.range.water_temp_bottom) : null,
        water_nutrient_top: payload.range.water_nutrient_top ? parseFloat(payload.range.water_nutrient_top) : null,
        water_nutrient_bottom: payload.range.water_nutrient_bottom
          ? parseFloat(payload.range.water_nutrient_bottom)
          : null,
        water_acid_top: payload.range.water_acid_top ? parseFloat(payload.range.water_acid_top) : null,
        water_acid_bottom: payload.range.water_acid_bottom ? parseFloat(payload.range.water_acid_bottom) : null,
      },
    };

    try {
      console.log(`Edit Data Preset ID ${payload._id} with Payload : `, newPayload);
      console.log(`Edit Data Preset URL PATCH : ${process.env.REACT_APP_API_BASE_URL}/pod-preset/${payload._id}`);
      const response = await updatePreset({
        id: payload._id,
        payload: newPayload,
      }).unwrap();
      console.log(`Edit Data Preset Response : `, response);

      setModalData({
        title: "Presets Edited",
        description: "You’ve successfully edit presets",
        typeModal: "",
        typeConfirm: "success",
      });

      setTimeout(() => {
        setOpenRange(false);
        setIsLoading(false);
        setOpenConfirm(true);
      }, 1000);
    } catch (error) {
      setModalData({
        title: error?.data?.name,
        description: error?.data?.message,
        typeModal: "failed",
        typeConfirm: "failed",
        isError: true,
      });
      setOpenRange(false);
      setOpenConfirm(true);
    }
  };

  return (
    <>
      {openEdit && (
        <ModalEdit
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          preset={presetDetail}
          payload={payload}
          setPayload={setPayload}
          openCreateTime={() => {
            setOpenEdit(false);
            setOpenCreateTime(true);
          }}
        />
      )}

      {openCreateTime && (
        <ModalCreateTime
          isOpen={openCreateTime}
          handleClose={() => setOpenCreateTime(false)}
          payload={payload}
          setPayload={setPayload}
          openRange={() => {
            setOpenCreateTime(false);
            setOpenRange(true);
          }}
        />
      )}

      {openRange && (
        <ModalRange
          isOpen={openRange}
          handleClose={() => setOpenRange(false)}
          payload={payload}
          setPayload={setPayload}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
        />
      )}

      {openConfirm && (
        <ModalConfirm
          isOpen={openConfirm}
          handleClose={() => setOpenConfirm(false)}
          title={modalData.title}
          description={modalData.description}
          typeConfirm={modalData.typeConfirm}
          typeModal={modalData.type}
          isError={modalData.isError}
        />
      )}

      {openValidation && (
        <ModalConfirm
          isOpen={openValidation}
          handleClose={() => setOpenValidation(false)}
          title={modalData.title}
          description={modalData.description}
          typeModal={modalData.typeModal}
          typeConfirm={modalData.typeConfirm}
          isError={modalData.isError}
          handleSave={handleDelete}
          withAction={true}
          isLoading={isLoading}
          titleButton="Delete"
        />
      )}

      <WrapperList>
        {presets?.map((preset) => (
          <Card
            key={preset._id}
            preset={preset}
            openEdit={() => {
              setOpenEdit(true);
              setPresetDetail(preset);
            }}
            openDelete={() => {
              setPresetDetail(preset);
              setOpenValidation(true);
              setModalData({
                title: "Delete Preset",
                description: `Are you sure, you want to delete this preset ${preset.label}?`,
                typeModal: "failed",
                typeConfirm: "failed",
                isError: false,
              });
            }}
          />
        ))}
      </WrapperList>
    </>
  );
};

export default ListPresets;
