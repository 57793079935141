import { WrapperLogin } from './styles';
import BrandLogo from '../../assets/img/brand-logo.svg';
import TextInput from '../input/text.component';
import Button from '../button/button.component';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/auth/auth.slice';
import { useState } from 'react';
import Spinner from '../loader/spinner.component';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isLoading, message } = useSelector(
    (state) => state.auth
  );
  const [payload, setPayload] = useState({
    strategy: 'local',
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(login(payload));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (user) {
      navigate('/pod/active-pods');
    }
  }, [navigate, user]);

  return (
    <WrapperLogin className="h-[492px] flex flex-col justify-center items-center">
      <img
        src={BrandLogo}
        className="img-fluid h-100 w-100 rounded-circle"
        alt="img"
        width={116}
        height={90}
      />
      <span>Welcome Back!</span>
      <form
        className="flex flex-col w-full px-10 gap-4 mt-10"
        onSubmit={handleSubmit}
      >
        <TextInput
          type="text"
          label="Email"
          value={payload.email}
          name="email"
          handleChange={handleChange}
          required={true}
        />
        <TextInput
          type="password"
          label="Password"
          value={payload.password}
          name="password"
          handleChange={handleChange}
          required={true}
        />
        <label className="error ">{message}</label>
        <Button
          variants="mainFilled"
          size="md"
          type="submit"
          className="mt-3"
          disabled={isLoading}
        >
          {isLoading && <Spinner />}
          Sign In
        </Button>
        <span className="forgot cursor-pointer mt-9">Forget Password?</span>
      </form>
    </WrapperLogin>
  );
};

export default Login;
