import styled from "styled-components";

export const WrapperInput = styled.div.attrs({
  className: "relative rounded-md shadow-sm",
})``;

export const WrapperInputText = styled.input.attrs({
  className: "block w-full",
})`
  padding: 10px 34px 10px 16px;
  height: 40px;
  border-radius: 8px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;

  :focus {
    outline: #43c667;
    border-color: #43c667;
  }
`;

export const Status = styled.div`
  border: 1px solid
    ${(props) => (props.variant === "greener" ? "#43C667" : "#ffa24d")};
  display: flex;
  padding: 2px 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.variant === "greener" ? "#43C667" : "#ffa24d")};
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  border-radius: 8px;
`;

export const Span = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #64748b;

  &.description {
    font-size: 11px;
  }

  &.date {
    font-size: 11px;
    color: #94a3b8;
  }
`;

export const WrapperChartDonut = styled.div`
  background: #f8fafc;
  border-radius: 10px;

  &.success-rate {
    background: #43c667;
  }

  .span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #64748b;
  }

  small {
    &.title {
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      color: #94a3b8;
    }

    &.batch {
      font-weight: 600;
      font-size: 11px;
      line-height: 16px;
      color: #64748b;
    }
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #64748b;
  }
`;

export const WrapperCardCounting = styled.div`
  grid-area: main;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));

  @media (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
`;

export const WrapperTable = styled.div`
  background-color: #f8fafc;
  padding: 22px 22px;
  border-radius: 10px;

  .span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #94a3b8;
  }

  .code-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #64748b;
  }

  .active {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #43c667;
  }

  .inactive {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #e94141;
  }
`;
