import Filter from '../../components/pod/activity-pod/filter.component';
import ButtonTabs from '../../components/pod/activity-pod/tabs-button.component';
import ListPods from '../../components/pod/activity-pod/list-pods.component';
import { selectAllPods, podsApiSlice } from '../../store/pod/pod.slice';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

const ManagePodPage = () => {
  const dispatch = useDispatch();
  const pods = useSelector(selectAllPods);

  useEffect(() => {
    dispatch(podsApiSlice.endpoints.getPods.initiate());
  }, [dispatch]);
  return (
    <div className="flex flex-col w-100 gap-5 pb-10">
      <Filter />
      <hr className="text-grey-300" />
      <ButtonTabs />
      <ListPods pods={pods} />
    </div>
  );
};

export default ManagePodPage;
