import ModalCreateOrder from "./create-order/create-order.component";
import Button from "../../button/button.component";
import { useState, memo, useEffect } from "react";
import { useNavigate, useLocation, createSearchParams, useSearchParams } from "react-router-dom";
import { selectAllHarvestStates } from "../../../store/product/product.slice";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";

const TabsButton = () => {
  const tableRef = useRef(null);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dataHarvestStates = useSelector(selectAllHarvestStates);
  const [harvestStates, setHarvestStates] = useState([]);

  const [tab, setTab] = useState("");
  const [openCreateOrder, setOpenCreateOrder] = useState(false);

  const handlePage = (harvestState) => {
    setTab(harvestState);
    navigate({
      pathname: "/products/active",
      search: createSearchParams({
        harvestState: harvestState ?? "",
      }).toString(),
    });
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "All Product",
    sheet: "Product",
  });

  const exportToExcel = () => {};

  const transformFilter = (title) => {
    switch (title) {
      case "germinating":
        return "Garminating";
      case "seedling":
        return "Seedling";
      case "processing":
        return "On Process";
      case "harvested":
        return "Harvest";
      case "overriped":
        return "Overripe";
      case "wasted":
        return "Waste";
      case "enroute":
        return "En Route";
      default:
        return "On Proccess";
    }
  };

  useEffect(() => {
    if (dataHarvestStates.length) {
      const newHarvestState = [];
      dataHarvestStates.map((obj) => {
        if (obj.label === "germinating") {
          newHarvestState.push({ ...obj, order: 1 });
        }
        if (obj.label === "seedling") {
          newHarvestState.push({ ...obj, order: 2 });
        }
        if (obj.label === "enroute") {
          newHarvestState.push({ ...obj, order: 3 });
        }
        if (obj.label === "processing") {
          newHarvestState.push({ ...obj, order: 4 });
        }
        if (obj.label === "harvested") {
          newHarvestState.push({ ...obj, order: 5 });
        }
        if (obj.label === "overriped") {
          newHarvestState.push({ ...obj, order: 6 });
        }
        if (obj.label === "wasted") {
          newHarvestState.push({ ...obj, order: 7 });
        }
      });
      newHarvestState.sort((a, b) => a.order - b.order);
      setHarvestStates(newHarvestState);
    }
  }, [dataHarvestStates]);

  useState(() => {
    if (searchParams.get("harvestState")) {
      setTab(searchParams.get("harvestState"));
    }
  }, []);

  return (
    <>
      <table className="hidden" ref={tableRef}>
        <tbody>
          <tr>
            <th>Name</th>
            <th>Batch</th>
            <th>Plant Date</th>
            <th>Seed Wight</th>
            <th>Quantity</th>
            <th>Status</th>
            <th>Harvest</th>
            <th>Note</th>
          </tr>
          <tr>
            <td>Edison</td>
            <td>Padilla</td>
            <td>20</td>
          </tr>
          <tr>
            <td>Alberto</td>
            <td>Lopez</td>
            <td>94</td>
          </tr>
        </tbody>
      </table>
      <ModalCreateOrder isOpen={openCreateOrder} handleClose={() => setOpenCreateOrder(false)} />
      <div className="flex maxSM:flex-col gap-3  md:justify-between w-100">
        <div className="flex flex-row flex-wrap gap-3 ">
          <Button
            variant="outlined"
            variants={location.pathname.includes("/products/new-request") && tab === "" ? `mainFilled` : "mainOutlined"}
            size="md"
            onClick={() => navigate(`/products/new-request`)}
            className="whitespace-nowrap"
          >
            New Request
          </Button>
          {harvestStates?.map(({ _id, label }) => (
            <Button
              key={_id}
              variant="outlined"
              variants={tab === _id ? `mainFilled` : "mainOutlined"}
              size="md"
              onClick={() => handlePage(_id)}
              className="whitespace-nowrap"
            >
              {transformFilter(label)}
            </Button>
          ))}
          <Button
            variant="outlined"
            variants={
              !location.pathname.includes("/products/new-request") && tab === "" ? `mainFilled` : "mainOutlined"
            }
            size="md"
            onClick={() => handlePage("")}
            className="whitespace-nowrap"
          >
            Clear All
          </Button>
          <Button variant="outlined" variants="mainFilled" size="md" onClick={onDownload} className="whitespace-nowrap">
            Export to Excel
          </Button>
        </div>
        <div className="flex w-100">
          <Button
            variant="outlined"
            variants="mainFilled"
            size="md"
            className="flex justify-center items-center whitespace-nowrap"
            onClick={() => setOpenCreateOrder(true)}
          >
            {/* <PlusIcon height={20} width={20} /> */}
            Create Order
          </Button>
        </div>
      </div>
    </>
  );
};

export default memo(TabsButton);
