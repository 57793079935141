import DonurtChart from "./chart/DonutChart";
import AreaChart from "./chart/AreaChart";
import {
  WrapperChartDonut,
  WrapperCardCounting,
  WrapperTable,
} from "./dashboard.styles";
import CardCounting from "./card-counting";
import CardSuccessRate from "./card-success-rate";
import ListPod from "./list-pod";
import ListBatch from "./list-batch";

const WrapperCounting = ({ data }) => {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row gap-3">
        <WrapperChartDonut className="min-w-min min-h-min p-[19px] flex flex-col gap-4">
          <span className="span">Active Product Allocation</span>
          <DonurtChart total={data?.category2totalTray} />
        </WrapperChartDonut>
        <WrapperChartDonut className="min-h-min p-[19px] flex flex-col gap-4">
          <span className="span">Pods Performance</span>
          <AreaChart />
        </WrapperChartDonut>
      </div>
      <WrapperCardCounting className="pr-3">
        <CardCounting
          icon="/img/dashboard-sun.svg"
          value={data?.activePods}
          title="Active Pods &nbsp;&nbsp;&nbsp;&nbsp;"
        />
        <CardCounting
          icon="/img/dashboard-water.svg"
          value={data?.productGerminating}
          title="Product Garminating"
        />
        <CardCounting
          icon="/img/dashboard-glass.svg"
          value={data?.productSeedling}
          title="Product Seedling"
        />
        <CardCounting
          icon="/img/dashboard-clock.svg"
          value={data?.productProcessing}
          title="Product on Process"
        />
        <CardCounting
          icon="/img/dashboard-success.svg"
          value={data?.productHarvested}
          title="Product Harvested"
        />
        <CardSuccessRate data={data?.successRate} />
      </WrapperCardCounting>
      <div className="flex flex-row gap-3 w-100 pr-3">
        <WrapperTable className="w-[40%] h-64 overflow-auto">
          <ListPod data={data} />
        </WrapperTable>
        <WrapperTable className="w-[60%] h-64 overflow-auto">
          <ListBatch data={data} />
        </WrapperTable>
      </div>
    </div>
  );
};

export default WrapperCounting;
