/* eslint-disable array-callback-return */
import Modal from "../../../modal/modal.component";
import ModalConfirm from "../../../modal/modal-confirm.component";
import { WrapperInput, WrapperTextInput } from "./create-order.styles";
import Button from "../../../button/button.component";
// import Autocomplete from '../../../dropdown/autocomplete.component';
import { selectAllProducts, useCreateProductsRequestMutation } from "../../../../store/product/product.slice";
import { useEffect } from "react";
import { useState } from "react";
import { useGetUsersQuery } from "../../../../store/user/user.slice";
import { useSelector } from "react-redux";
import Dropdown from "../../../dropdown/dropdown.component";
import Spinner from "../../../loader/spinner.component";
import produce from "immer";

const ManagePod = ({ isOpen, handleClose }) => {
  const [isLoading, setIsloading] = useState(false);
  const { data: users } = useGetUsersQuery();
  const user = useSelector((state) => state.auth.user);
  const products = useSelector(selectAllProducts);
  const [optionsPods, setOptionPods] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [optionsUsers, setOptionUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [pod, setPod] = useState({ id: "", name: "" });
  const [templateId, setTemplateId] = useState("");
  const [query, setQuery] = useState("");
  const [selectedPods, setSelectedPods] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [createOrder] = useCreateProductsRequestMutation();
  const [payload, setPayload] = useState({
    userId: user?._id,
    batches: [],
  });
  const [modalData, setModalData] = useState({
    title: "",
    description: "",
    typeModal: "",
    typeConfirm: "",
    isError: false,
  });

  const onRequestClicked = async () => {
    setIsloading(true);
    try {
      const batches = [];

      selectedPods.map((product) => {
        const template = {
          productTemplateId: product.id,
          // num: product.num,
        };
        batches.push(template);
      });
      const newPayload = {
        userId: payload.userId,
        batches,
      };

      console.log("Create Product Template Request with Payload : ", newPayload);
      console.log(
        `Create Product Template Request URL POST : ${process.env.REACT_APP_API_BASE_URL}/product-template-request-many`
      );
      const response = await createOrder(newPayload).unwrap();
      console.log("Create Product Template Request Response : ", response);

      setModalData({
        title: "Order Created",
        description: "You’ve successfully create new order.",
        typeModal: "",
        typeConfirm: "success",
        isError: false,
      });

      setTimeout(() => {
        setIsloading(false);
        handleClose();
        setOpenConfirm(true);
        setSelectedPods([]);
      }, 700);
    } catch (err) {
      setModalData({
        title: "Failed",
        description: err.data.message,
        typeModal: "failed",
        typeConfirm: "failed",
        isError: false,
      });

      setTimeout(() => {
        setSelectedPods([]);
        setIsloading(false);
        handleClose();
        setOpenConfirm(true);
      }, 700);
    }
  };

  const deleteSelectedProduct = (id) => {
    const newSelectedPod = [...selectedPods];
    const neList = newSelectedPod.filter((pods) => {
      return pods.id !== id;
    });
    setSelectedPods(neList);
  };

  const handleChange = (index, value) => {
    const nextState = produce(selectedPods, (draftState) => {
      selectedPods.map((obj, idx) => {
        if (idx === index) {
          draftState[index].num = parseInt(value);
        }
        return true;
      });
    });

    setSelectedPods(nextState);
  };

  useEffect(() => {
    let arrUser = [];
    if (users) {
      users.data.map(({ _id, name }) => {
        return arrUser.push({ id: _id, label: name });
      });
      setOptionUsers(arrUser);
    }
  }, [users]);

  useEffect(() => {
    if (!user) {
      window.location.href = "/login";
    }
  }, [user]);

  useEffect(() => {
    let arrProduct = [];
    products.map(({ _id, productName }) => {
      return arrProduct.push({ id: _id, label: productName });
    });

    setOptionPods(arrProduct);
  }, [products]);

  return (
    <>
      <ModalConfirm
        isOpen={openConfirm}
        handleClose={() => setOpenConfirm(false)}
        title={modalData.title}
        description={modalData.description}
        typeConfirm={modalData.typeConfirm}
        typeModal={modalData.typeModal}
        isError={modalData.isError}
      />
      <Modal isOpen={isOpen} handleClose={handleClose} type="success" title="Create Order" size="lg" padding="p-0">
        <WrapperInput className="px-10 py-6">
          <WrapperTextInput>
            <span>Choose User</span>
            <Dropdown
              options={optionsUsers}
              selectedOption={optionsUsers.filter((opt) => opt.id === payload.userId)[0]}
              handelChange={(e) => {
                setSelectedUser(e);
                setPayload({ ...payload, userId: e.id });
              }}
              placeHolder="Select Users"
            />
          </WrapperTextInput>
          <WrapperTextInput className="mt-4">
            <span>Choose Product</span>
            <div className="flex gap-5 w-full items-center">
              <Dropdown
                options={optionsPods}
                selectedOption={optionsPods.filter((opt) => opt.id === selectedProduct.id)[0]}
                handelChange={(e) => {
                  setSelectedProduct(e);
                }}
                placeHolder="Select Product"
              />
              <Button
                size="lg"
                variant="filled"
                variants="mainFilled"
                type="button"
                className="w-1/5"
                onClick={() => {
                  const newItems = [...selectedPods];
                  newItems.push({ ...selectedProduct, num: "" });
                  setSelectedPods(newItems);
                }}
              >
                Add
              </Button>
            </div>
          </WrapperTextInput>
          <WrapperTextInput className="mt-5">
            <span>Product Order</span>
          </WrapperTextInput>
          <div className="h-[250px] overflow-auto">
            {selectedPods &&
              selectedPods.map(({ id, label, num }, index) => (
                <WrapperTextInput className="py-2" key={index}>
                  <div className="flex gap-2 md:gap-5">
                    <input type="text" value={label} disabled onChange={() => {}} />
                    {/* <input
                      type="number"
                      value={num}
                      className="txt-qty"
                      placeholder="Qty"
                      onChange={(e) => {
                        handleChange(index, e.target.value);
                      }}
                      min="0"
                    /> */}
                    <Button
                      size="lg"
                      variant="outlined"
                      variants="dangerOutlined"
                      type="button"
                      className="w-1/5"
                      onClick={() => deleteSelectedProduct(id)}
                    >
                      Delete
                    </Button>
                  </div>
                </WrapperTextInput>
              ))}
          </div>
          <div className="lex flex-row w-100 justify-center items-center mt-4 mb-4 gap-5">
            <Button
              variant="filled"
              variants="mainFilled"
              size="lg"
              className="w-full"
              type="button"
              onClick={onRequestClicked}
            >
              {isLoading && <Spinner />}
              Request
            </Button>
          </div>
        </WrapperInput>
      </Modal>
    </>
  );
};

export default ManagePod;
