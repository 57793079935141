import Modal from '../../modal/modal.component';
import Button from '../../button/button.component';
import { useSelector } from 'react-redux';
// import { selectProductActiveById } from '../../../../store/product/product.slice';
import {
  Description,
  WrapperDescriptionDtl,
  WrapperDetail,
  WrapperImageDetail,
} from './product-history.styles';

const Detail = ({ isOpen, handleClose, id, setTypeModal }) => {
  // const product = useSelector((state) => selectProductActiveById(state, id));
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      size="md"
      type="success"
      padding="p-0"
      title="BabyGREENS - Kale "
    >
      <WrapperDetail>
        <WrapperImageDetail />
        <WrapperDescriptionDtl>
          <Description>
            <span className="label-desc">Batch</span>
            <span className="value-desc">060B21-GP-00-1-001-00-002</span>
          </Description>
          <Description>
            <span className="label-desc">Blockchain ID</span>
            <span className="value-desc value-green">XTajso28371</span>
          </Description>
          <Description>
            <span className="label-desc">Code LT</span>
            <span className="value-desc">060021-060121</span>
          </Description>
          <Description>
            <span className="label-desc">Pod</span>
            <span className="value-desc">GP-00-1-001-00-002</span>
          </Description>
          <Description>
            <span className="label-desc">Plant Date</span>
            <span className="value-desc">22 August 2021</span>
          </Description>
          <Description>
            <span className="label-desc">Seed Weight</span>
            <span className="value-desc">200 Gram</span>
          </Description>
          <Description>
            <span className="label-desc">Quantity</span>
            <span className="value-desc">100 Pot</span>
          </Description>
          <Description>
            <span className="label-desc">Seedling Date</span>
            <span className="value-desc">-</span>
          </Description>
          <Description>
            <span className="label-desc">Delivery Date</span>
            <span className="value-desc">25 August 2021</span>
          </Description>
          <Description>
            <span className="label-desc">Harvest Date</span>
            <span className="value-desc">30 August 2021</span>
          </Description>
          <Description>
            <span className="label-desc">Harvest Weight</span>
            <span className="value-desc">220 Gram</span>
          </Description>
          <Description>
            <span className="label-desc">Success Rate</span>
            <span className="value-desc">98%</span>
          </Description>
          <Description>
            <span className="label-desc">Notes</span>
            <span className="value-desc">-</span>
          </Description>
          <Description>
            <span className="label-desc">Status</span>
            <span className="value-desc value-green">Harvested</span>
          </Description>
          <Description>
            <span className="label-desc">Harvest By</span>
            <span className="value-desc value-green">John Doe</span>
          </Description>
        </WrapperDescriptionDtl>
      </WrapperDetail>
    </Modal>
  );
};

export default Detail;
