import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useGetPodsQuery } from "../store/pod/pod.slice";

const containerStyle = {
  width: "100vw",
  height: "100vh",
};

const center = {
  lat: -6.2,
  lng: 106.816666,
};

const Location = () => {
  const { data, isSuccess } = useGetPodsQuery("?visible=true&$limit=50");
  return (
    <LoadScript googleMapsApiKey="AIzaSyDYPXmw-SzEBDhadLIPa0MTDTJNqWuIG7U">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
        {isSuccess &&
          data.data?.map((obj, index) => {
            // console.log("🚀 ~ file: location.jsx:21 ~ Location ~ obj:", obj.coordinate);

            const lat = obj.coordinate ? obj.coordinate.split(", ")[0] : 0;
            const lng = obj.coordinate ? obj.coordinate.split(", ")[1] : 0;
            if (obj.coordinate) {
              return (
                <Marker
                  position={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
                  title={obj.address}
                  key={index}
                  cursor={obj.address}
                  icon={{
                    url: require("../assets/img/marker.svg").default,
                    scaledSize: new window.google.maps.Size(25, 35),
                  }}
                />
              );
            } else {
              return null;
            }
          })}
      </GoogleMap>
    </LoadScript>
  );
};

export default Location;
