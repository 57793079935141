import Filter from '../../components/products/product-history/filter.component';
import ListProduct from '../../components/products/product-history/list-product.component';

const ProductHistoryPage = () => {
  return (
    <div className="flex flex-col w-100 gap-5 pb-10">
      <Filter />
      <hr className="text-grey-300" />
      <ListProduct />
    </div>
  );
};

export default ProductHistoryPage;
