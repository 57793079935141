import Modal from "../../modal/modal.component";
import { WrapperTable, WrapperTextInput } from "../styles";
import Button from "../../button/button.component";
import SunDefault from "../../../assets/img/sun-default.svg";
import { PlusSmIcon, XIcon } from "@heroicons/react/solid";
import Toogle from "../../button/toggle.component";

const CreateTime = ({ isOpen, handleClose, payload, setPayload, openRange }) => {
  const addNewRow = () => {
    let newlist = [
      ...payload.lights_timer,
      [
        {
          start: "00:00:00",
          end: "00:00:00",
        },
      ],
    ];
    setPayload({ ...payload, lights_timer: newlist });
  };

  const addNewRowInArray = (index) => {
    let newlist = payload.lights_timer;
    let timers = newlist[index];
    const lightTimer = [
      ...timers,
      {
        start: "00:00:00",
        end: "00:00:00",
      },
    ];
    newlist[index] = lightTimer;
    setPayload({ ...payload, lights_timer: newlist });
  };

  const handleChange = (value, index, row, label) => {
    let newlist = [...payload.lights_timer];
    let timers = [...newlist[index]];
    const newObject = { ...timers[row], [label]: value };
    timers[row] = newObject;
    newlist[index] = timers;

    setPayload({ ...payload, lights_timer: newlist });
  };

  const handleOnOff = (value, index, row) => {
    let newlist = [...payload.lights_timer];
    let timers = [...newlist[index]];
    const newObject = { start: "00:00:00", end: value };
    timers[row] = newObject;
    newlist[index] = timers;

    setPayload({ ...payload, lights_timer: newlist });
  };

  const removeTimer = (index, row) => {
    const newList = [...payload.lights_timer];
    let timer = newList[index];
    timer.splice(row, 1);
    newList[index] = timer;

    if (timer.length <= 0) {
      newList.splice(index, 1);
    }

    if (row <= 0) {
      newList.splice(index, 1);
    }

    setPayload({
      ...payload,
      lights_timer: newList,
    });
  };

  return (
    <>
      <Modal isOpen={isOpen} handleClose={handleClose} size="2xl" title="Create New Presets" type="success">
        <form className="flex flex-col justify-start gap-2">
          <WrapperTable className="border-separate border-spacing-1">
            <tbody>
              {payload.lights_timer?.map((timer, index) => {
                return timer.map(({ start, end }, subIndex) => {
                  return (
                    <tr key={`${index}-${subIndex}`}>
                      <td className="pr-2 span">
                        <div className="flex flex-row gap-1">
                          {subIndex <= 0 ? (
                            <>
                              <img src={SunDefault} alt="sun" />
                              {index + 1}th:&nbsp;&nbsp;
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                      <td>
                        <WrapperTextInput>
                          <input
                            type="time"
                            value={start}
                            placeholder="Time"
                            name="time"
                            onChange={(e) => handleChange(e.target.value, index, subIndex, "start")}
                            step="1"
                            className=""
                          />
                        </WrapperTextInput>
                      </td>
                      <td className="span">to</td>
                      <td>
                        <WrapperTextInput>
                          <input
                            type="time"
                            value={end}
                            placeholder="Time"
                            name="time"
                            onChange={(e) => handleChange(e.target.value, index, subIndex, "end")}
                            step="1"
                          />
                        </WrapperTextInput>
                      </td>
                      <td>
                        {subIndex <= 0 && (
                          <PlusSmIcon
                            height={30}
                            width={30}
                            className="cursor-pointer main-color"
                            onClick={() => addNewRowInArray(index)}
                          />
                        )}
                      </td>
                      <td>
                        <div className="flex flex-row gap-1 justify-center items-center">
                          <Toogle
                            value={end === "00:00:00" ? false : true}
                            onChange={() => {
                              handleOnOff(end === "00:00:00" ? "23:59:59" : "00:00:00", index, subIndex);
                            }}
                          />
                          <span>{end === "00:00:00" ? "Off" : "On"}</span>
                        </div>
                      </td>

                      <td>
                        <XIcon
                          height={20}
                          width={20}
                          className="text-red-500 cursor-pointer"
                          onClick={() => removeTimer(index, subIndex)}
                        />
                      </td>
                    </tr>
                  );
                });
              })}
            </tbody>
          </WrapperTable>
          <div className="flex justify-end">
            <Button variants="mainFilled" size="md" type="button" className="" onClick={addNewRow}>
              Add New Row
            </Button>
          </div>

          <Button variants="mainFilled" size="lg" type="button" className="mt-10 mb-2" onClick={openRange}>
            {/* {isLoading && <Spinner />} */}
            Next
          </Button>
        </form>
      </Modal>
    </>
  );
};

export default CreateTime;
