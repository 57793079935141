import {
  CardProduct,
  CardLeft,
  CardMiddle,
  CardRight,
} from './active-pod.styles';
import moment from 'moment';

const CardProductComponent = ({ product }) => {
  return (
    <CardProduct className="flex flex-row">
      <CardLeft className="w-[20%]" />
      <CardMiddle className="w-[60%] flex flex-col justify-between p-2">
        <span className="title">{product?.productName}</span>
        <span className="code">{product?.codeBatch}</span>
        <span className="date-since">
          Since{' '}
          <span className="date">
            {moment(product?.plantDate).format('DD MMMM YYYY')}
          </span>
        </span>
      </CardMiddle>
      <CardRight className="w-[20%] flex flex-col justify-center items-center ">
        <p className="first-letter:capitalize">
          {product?.harvestStateLabel || 'Proccessing'}
        </p>
      </CardRight>
    </CardProduct>
  );
};

export default CardProductComponent;
