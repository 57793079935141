import Modal from "../../../modal/modal.component";
import Button from "../../../button/button.component";
import Moment from "moment";
import { Description, WrapperButton, WrapperDescription, WrapperDetail, WrapperImageDetail } from "./wasted.styles";
import { Textarea } from "@material-tailwind/react";

const Detail = ({ isOpen, handleClose, id, setTypeModal, modalData, product }) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} size="md" type="failed" padding="p-0" title={product?.productName}>
      <WrapperDetail className="rounded-b-[18px] overflow-hidden min-h-min">
        <WrapperImageDetail className="relative">
          {product?.imageLink && <img src={product?.imageLink} alt="img" className="h-full w-full object-contain" />}
        </WrapperImageDetail>
        <WrapperDescription>
          <Description>
            <span className="label-desc">Batch</span>
            <span className="value-desc">{product?.codeBatch}</span>
          </Description>
          <Description>
            <span className="label-desc">Code LT</span>
            <span className="value-desc">{product?.pod?.codeName}</span>
          </Description>
          <Description>
            <span className="label-desc">Plant Date</span>
            <span className="value-desc">{Moment(product?.plantDate).format("DD MMMM YYYY")}</span>
          </Description>
          <Description>
            <span className="label-desc">Seed Weight</span>
            <span className="value-desc">{product?.seedVolume} Gram</span>
          </Description>
          <Description>
            <span className="label-desc">Quantity</span>
            <span className="value-desc">{product?.totalTray} Pot</span>
          </Description>
          <Description>
            <span className="label-desc">Status</span>
            <span className="value-desc value-red capitalize">{product?.harvestStateLabel}</span>
          </Description>
          <Description>
            <span className="label-desc">Harvest</span>
            <span className="value-desc value-red">{Moment(product?.estHarvestDate).format("DD MMMM YYYY")}</span>
          </Description>
          <div className="flex flex-col gap-2 ">
            <span className="label-desc">Notes</span>
            <Textarea disabled>{product?.notes}</Textarea>
          </div>
        </WrapperDescription>
        {/* <WrapperButton>
          <Button
            variants="dangerFilled"
            size="lg"
            type="button"
            onClick={() => setTypeModal("wasted-form")}
            className="w-52"
          >
            Waste
          </Button>
        </WrapperButton> */}
      </WrapperDetail>
    </Modal>
  );
};

export default Detail;
