/* eslint-disable react-hooks/exhaustive-deps */
import { WrapperContent, WrapperImage } from "./styles";
import Button from "../button/button.component";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useUpdateUserMutation } from "../../store/user/user.slice";
import Spinner from "../loader/spinner.component";
import ModalConfirm from "../modal/modal-confirm.component";

const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

const Profile = () => {
  const [updateUser, { isLoading, isError, error }] = useUpdateUserMutation();
  const user = useSelector((state) => state.auth.user);
  const [readonly, setReadonly] = useState(true);
  const [inputRef, setInputFocus] = useFocus();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    description: "",
    typeConfirm: "success",
  });
  const [payload, setPayload] = useState({
    name: "",
    avatar: "",
    email: "",
    address: "",
    nik: "",
    phoneNum: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUser(user._id).unwrap();
      if (isError) {
        console.log(
          "🚀 ~ file: profile.jsx ~ line 50 ~ handleSubmit ~ isError",
          error
        );

        setModalData({
          title: "Failed",
          description: error,
          typeConfirm: "failed",
          typeModal: "failed",
        });
      }

      if (!isError) {
        setModalData({
          title: "Success",
          description: "Data successfully Edited",
          typeConfirm: "success",
          typeModal: "success",
        });
      }
      setOpenConfirm(true);
      setReadonly(true);
    } catch (e) {
      setModalData({
        title: "Ops Sorry!",
        description: e.data.message,
        typeConfirm: "failed",
      });
      setOpenConfirm(true);
      setReadonly(true);
    }
  };

  useEffect(() => {
    if (user) {
      setPayload({
        name: user?.name,
        avatar: user?.avatar,
        email: user?.phoneNum,
        address: user?.address,
        nik: user?.nik,
        phoneNum: user?.phoneNum,
      });
    }
  }, [user]);

  useEffect(() => {
    if (isError) {
      setPayload({
        name: user.name,
        avatar: user.avatar,
        email: user.phoneNum,
        address: user.address,
        nik: user.nik,
        phoneNum: user.phoneNum,
      });
    }
  }, [isError]);

  return (
    <>
      <ModalConfirm
        isOpen={openConfirm}
        handleClose={() => setOpenConfirm(false)}
        title={modalData.title}
        description={modalData.description}
        typeConfirm={modalData.typeConfirm}
      />
      <div className="pl-10 h-full w-full">
        <WrapperContent className="flex flex-col justify-center items-center p-[36px] gap-[16px]">
          <span className="title-menu">Profile</span>
          <WrapperImage className="relative">
            <img
              src={payload.avatar}
              className="h-full w-full object-fill rounded-full"
              alt="icon"
            />
          </WrapperImage>
          <form className="flex flex-col gap-[16px] w-full px-[64px]">
            <div className="flex flex-col gap-[8px]">
              <label>Full Name</label>
              <input
                type="text"
                value={payload.name}
                name="name"
                onChange={handleChange}
                placeholder="Full Name"
                readOnly={readonly}
                ref={inputRef}
                required
              />
            </div>
            <div className="flex flex-col gap-[8px]">
              <label>E-mail</label>
              <input
                type="text"
                value={payload.email}
                name="email"
                onChange={handleChange}
                placeholder="E-mail"
                readOnly={readonly}
                required
              />
            </div>
            <div className="flex flex-col gap-[8px]">
              <label>NIK</label>
              <input
                type="text"
                value={payload.nik}
                name="nik"
                onChange={handleChange}
                placeholder="NIK"
                readOnly={readonly}
                required
              />
            </div>
            <div className="flex flex-col gap-[8px]">
              <label>Phone Number</label>
              <input
                type="text"
                value={payload.phoneNum}
                name="phoneNum"
                onChange={handleChange}
                placeholder="Phone Number"
                readOnly={readonly}
                required
              />
            </div>
            <div className="flex flex-col gap-[8px]">
              <label>Address</label>
              <input
                type="text"
                value={payload.address}
                name="address"
                onChange={handleChange}
                placeholder="Address"
                readOnly={readonly}
                required
              />
            </div>
            {readonly && (
              <Button
                variant="outlined"
                variants="mainOutlined"
                size="md"
                onClick={() => {
                  setInputFocus();
                  setReadonly(false);
                }}
                type="button"
                className="mt-6"
              >
                Edit
              </Button>
            )}

            {!readonly && (
              <Button
                variant="outlined"
                variants="mainFilled"
                size="md"
                onClick={handleSubmit}
                type="submit"
                className="mt-6"
              >
                {isLoading && <Spinner />}
                Save
              </Button>
            )}
          </form>
        </WrapperContent>
      </div>
    </>
  );
};

export default Profile;
