import ModalCreate from './create.component';
import Button from '../../button/button.component';
import { useState, memo } from 'react';

const TabsButton = () => {
  const [tab, setTab] = useState('');
  const [openCreate, setOpenCreate] = useState(false);
  const tabList = [
    {
      id: 'processing',
      label: 'Processing',
    },
    {
      id: 'harvest',
      label: 'Harvest',
    },
    {
      id: 'overripe',
      label: 'Overripe',
    },
    {
      id: 'failed',
      label: 'Failed',
    },
    {
      id: 'inactive',
      label: 'Inactive',
    },
    {
      id: '',
      label: 'Clear All',
    },
  ];
  return (
    <>
      <ModalCreate
        isOpen={openCreate}
        handleClose={() => setOpenCreate(false)}
      />
      <div className="flex justify-between w-100">
        <div className="flex flex-row gap-3 ">
          {tabList.map(({ id, label }) => (
            <Button
              key={id}
              variant="outlined"
              variants={tab === id ? `mainFilled` : 'mainOutlined'}
              size="md"
              onClick={() => setTab(id)}
            >
              {label}
            </Button>
          ))}
        </div>
      </div>
    </>
  );
};

export default memo(TabsButton);
