import { ButtonTab } from "./styles";
import { ChevronRightIcon } from "@heroicons/react/solid";

const Tab = ({ tab, handleClick }) => {
  return (
    <div className="flex flex-col gap-5">
      <ButtonTab
        variants={tab === "plant-name" ? "active" : "inactive"}
        className="flex flex-row justify-between items-center cursor-pointer"
        onClick={() => handleClick("plant-name")}
      >
        <div className="flex flex-row gap-2  items-center">
          <span className="title">Plant Name</span>
        </div>
        <ChevronRightIcon height={25} width={25} className="main-color" />
      </ButtonTab>
      <ButtonTab
        variants={tab === "plant-category" ? "active" : "inactive"}
        className="flex flex-row justify-between items-center cursor-pointer"
        onClick={() => handleClick("plant-category")}
      >
        <div className="flex flex-row gap-2 items-center">
          <span className="title">Plant Category</span>
        </div>
        <ChevronRightIcon height={25} width={25} className="main-color" />
      </ButtonTab>
      <ButtonTab
        variants={tab === "plant-product-type" ? "active" : "inactive"}
        className="flex flex-row justify-between items-center cursor-pointer"
        onClick={() => handleClick("plant-product-type")}
      >
        <div className="flex flex-row gap-2 items-center">
          <span className="title">Plant Product Type</span>
        </div>
        <ChevronRightIcon height={25} width={25} className="main-color" />
      </ButtonTab>
    </div>
  );
};

export default Tab;
