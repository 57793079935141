import { WrapperInput, WrapperInputText } from "./dashboard.styles";
import { useState } from "react";
import { SearchIcon } from "@heroicons/react/solid";
import CardNotification from "./card-notification";

const ListNotification = () => {
  const [filter, setFilter] = useState("");
  return (
    <>
      <div className="pt-[21px] px-[25px] mb-3">
        <WrapperInput>
          <WrapperInputText
            type="text"
            placeholder="Find your pods"
            onChange={(e) => setFilter(e.target.value)}
            value={filter}
          />
          <div className="absolute inset-y-0 right-2 flex items-center">
            <label htmlFor="currency" className="sr-only">
              Search
            </label>
            <SearchIcon height={25} width={25} className="main-grey-300" />
          </div>
        </WrapperInput>
      </div>
      <div className="w-100 bg-main-grey-200 h-[90%] px-[25px] pt-3 mb-5 overflow-auto no-scrollbar flex flex-col gap-3">
        <CardNotification />
        <CardNotification />
        <CardNotification />
        <CardNotification />
        <CardNotification />
        <CardNotification />
        <CardNotification />
        <CardNotification />
        <CardNotification />
        <CardNotification />
        <CardNotification />
        <CardNotification />
      </div>
    </>
  );
};

export default ListNotification;
