import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/api";

const podAdapter = createEntityAdapter({
  selectId: (state) => state?._id,
});

const initialState = podAdapter.getInitialState();

export const podsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPods: builder.query({
      query: (arg = "") => `/pod${arg}`,
      // transformResponse: (responseData) => {
      //   return podAdapter.setAll(initialState, responseData.data);
      // },
      providesTags: (result, error, arg) => [{ type: "Pod", id: "LIST" }],
    }),
    getDetailPod: builder.query({
      query: (id) => `/pod/${id}`,
      transformResponse: (responseData) => {
        return podAdapter.setOne(initialState, responseData);
      },
      providesTags: (result, error, arg) => [
        { type: "Pod", id: "LIST" },
        // ...result.ids.map((id) => ({ type: "Pod", id })),
      ],
    }),
    getPodsByUserId: builder.query({
      query: (params) => `/user-pod?$limit=50${params || ""}`,
      transformResponse: (responseData) => {
        return podAdapter.setAll(initialState, responseData.data);
      },
      providesTags: (result, error, arg) => [{ type: "Pod", id: "USERPOD" }],
    }),
    assignUserPod: builder.mutation({
      query: (initialPost) => ({
        url: `/user-pod`,
        method: "POST",
        body: {
          ...initialPost,
        },
      }),
      providesTags: (result, error, arg) => [{ type: "Pod", id: "USERPOD" }],
    }),
    deleteUserPod: builder.mutation({
      query: (podId) => ({
        url: `/user-pod/${podId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Pod", id: arg.id }],
    }),
    unAssignUserPod: builder.mutation({
      query: (podId) => ({
        url: `/user-pod/${podId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Pod", id: arg.id }],
    }),
    updatePod: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/pod/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Pod", id: "LIST" },
        { type: "Pod", id: arg.id },
      ],
    }),
    createPod: builder.mutation({
      query: (payload) => ({
        url: `/pod`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [{ type: "Pod", id: "LIST" }],
    }),
  }),
});

export const {
  useGetPodsQuery,
  useGetPodsByUserIdQuery,
  useAssignUserPodMutation,
  useDeleteUserPodMutation,
  useUpdatePodMutation,
  useCreatePodMutation,
  useGetDetailPodQuery,
  useUnAssignUserPodMutation,
} = podsApiSlice;

export const selectPodResult = podsApiSlice.endpoints.getPods.select();

const selectPodsData = createSelector(
  selectPodResult,
  (podsResult) => podsResult.data // normalized state object with ids & entities
);

export const { selectAll: selectAllPods, selectById: selectPodById } = podAdapter.getSelectors(
  (state) => selectPodsData(state) ?? initialState
);

// POD USER BY ID
export const selectPodUserResult = podsApiSlice.endpoints.getPodsByUserId.select();

const selectPodsUserData = createSelector(
  selectPodUserResult,
  (podsResult) => podsResult.data // normalized state object with ids & entities
);

export const { selectAll: selectAllUserPods, selectById: selectUserPodById } = podAdapter.getSelectors(
  (state) => selectPodsUserData(state) ?? initialState
);
