import styled from "styled-components";

export const WrapperFilter = styled.div``;

export const WrapperTabPage = styled.div.attrs({
  className: "flex flex-row items-center gap-3",
})`
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-decoration: underline;
    color: #64748b;
    cursor: pointer;

    &.active {
      color: #43c667;
    }
  }
`;

export const WrapperInput = styled.div.attrs({
  className: "relative rounded-md shadow-sm",
})``;

export const WrapperInputText = styled.input.attrs({
  className: "block w-full",
})`
  padding: 10px 34px 10px 16px;
  width: 259px;
  height: 40px;
  background: #e2e8f0;
  border-radius: 8px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #64748b;

  :focus {
    outline: #43c667;
    border-color: #43c667;
  }
`;

export const WrapperList = styled.div`
  grid-area: main;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(489px, 1fr));

  @media (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
`;

export const WrapperListPod = styled.div`
  grid-area: main;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(332px, 1fr));

  @media (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
`;

export const WrapperListActivity = styled.div`
  grid-area: main;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));

  @media (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
`;

export const WrapperCard = styled.div`
  height: 214px;
  background: #ffffff;
  border-radius: 8px;
`;

export const WrapperStatus = styled.div`
  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #64748b;
  }
`;

export const Status = styled.div`
  border: 1px solid
    ${(props) => (props.variant === "greener" ? "#43C667" : "#ffa24d")};
  display: flex;
  padding: 2px 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.variant === "greener" ? "#43C667" : "#ffa24d")};
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  border-radius: 8px;
`;

export const WrapperTime = styled.div`
  &.value-index {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #64748b;
  }

  .value-green {
    color: #43c667;
  }
`;
export const WrapperTextInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #94a3b8;
  }

  input {
    padding: 8px 25px 8px 20px;
    /* background: #e2e8f0; */
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    width: 100%;
    color: #64748b;

    ::placeholder {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }
  }

  button {
    padding: 9px 40px 9px 20px;
    background: #e2e8f0;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    width: 100%;
    height: 40px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #64748b;
  }

  textarea {
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    padding: 12px 25px 8px 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #64748b;
  }
`;

export const WrapperTable = styled.table`
  .span {
    color: #64748b;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
`;
