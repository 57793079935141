import Filter from "../../components/products/product-active/filter.component";
import ProductList from "../../components/products/product-ordered/list-product-list.component";

const ProductListPage = () => {
  return (
    <>
      <div className="flex flex-col w-100 gap-5 pb-10">
        <Filter />
        <hr className="text-grey-300" />
        <ProductList />
      </div>
    </>
  );
};

export default ProductListPage;
