import styled from "styled-components";

export const WrapperAside = styled.aside.attrs((props) => ({
  className: `flex flex-col ${props.isExpanded && "is-expanded"}`,
}))`
  background-color: #ffffff;
  width: calc(2rem + 32px);
  border-radius: 20px;
  overflow: hidden;
  transition: 0.5s ease-in-out;
  padding: 1rem;

  &.is-expanded {
    transition: 0.5s ease-in;
    min-width: 263px;
    width: 263px;
    max-width: 263px;

    .wrapper-logo {
      background-color: white;
      span,
      p {
        visibility: visible;
      }

      .img-brand {
        visibility: visible;
      }
    }

    .wrapper-menu {
      .menu {
        padding: 10px 42px 10px 50px;

        .title {
          opacity: 1;
          visibility: visible;
          font-size: 16px;
          line-height: 20px;
        }

        .menu-icons {
          margin-right: 0.5rem;
        }

        .toggle {
          transform: rotate(-180deg);
        }
      }
    }
  }
`;

export const WrapperLogo = styled.div.attrs({ className: "wrapper-logo" })`
  margin: 0 -1.2rem;
  padding-left: 23%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  /* margin-top: 3rem; */
  /* gap: 0.5rem; */

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    transition: visibility 0s, opacity 0.5s linear;
    visibility: hidden;
  }

  p {
    color: #94a3b8;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    transition: visibility 0s, opacity 0.5s linear;
    visibility: hidden;
  }

  .img-brand {
    transition: visibility 0s, opacity 0.5s linear;
    visibility: hidden;
  }
`;

export const Flex = styled.div`
  flex: 1 1 0%;
`;

export const WrapperMenu = styled.div.attrs((props) => ({
  className: "wrapper-menu",
}))`
  margin: 0 -1rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

export const Menu = styled.div.attrs((props) => ({
  className: "menu",
}))`
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  padding: 10px 1.3rem;

  .menu-icons {
    min-width: 1.5rem;
    min-height: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    color: #43c667;
    transition: 0.2s ease-in-out;
  }

  .title {
    color: #64748b;
    transition: 0.2s ease-in-out;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    visibility: hidden;

    font-style: normal;
    font-weight: 600;
    font-size: 0px;
    line-height: 0px;
    white-space: nowrap;
  }

  .toggle {
  }

  :hover {
    /* background-color: #43c667; */
    .menu-icons,
    .title {
      color: #43c667;
      /* fill: #ffffff; */
    }
  }
`;
