import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { Mutex } from "async-mutex";
// import { destroyCookie, parseCookies } from "nookies";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const baseUrl = process.env.REACT_APP_API_BASE_URL;

// Create a new mutex
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl,
  mode: "cors",
  prepareHeaders: (headers, { getState }) => {
    // const { "greensGarden.Token": token } = parseCookies();
    headers.set("Access-Control-Allow-Origin", "*");
    const token = cookies.get("greensGarden.Token");
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const customFetchBase = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        cookies.remove("greensGarden.User", {
          path: "/",
        });
        cookies.remove("greensGarden.Token", {
          path: "/",
        });
        window.location.href = "/login";
      } finally {
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export default customFetchBase;
