import Filter from "../../components/products/product-active/filter.component";
import ProductList from "../../components/products/product-new-request/products-list.component";
import TabsButton from "../../components/products/product-active/tabs-button.component";

const ProductListPage = () => {
  return (
    <>
      <div className="flex flex-col w-100 gap-5 pb-10">
        <Filter />
        <hr className="text-grey-300" />
        <TabsButton />
        <ProductList />
      </div>
    </>
  );
};

export default ProductListPage;
