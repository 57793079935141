import Modal from "../../modal/modal.component";
import Button from "../../button/button.component";
import { useState, useEffect } from "react";
import {
  useCreatePlantProductTypeMutation,
  useUpdatePlantProductTypeMutation,
  useGetPlantCatgeoryQuery,
  useGetPlantNameQuery,
} from "../../../store/product/product.slice";
import Spinner from "../../loader/spinner.component";
import Dropdown from "../../dropdown/dropdown.component";
import { WrapperFormCreate, WrapperTextInput } from "./styles";

const CreatePlantCategory = ({ isOpen, handleClose, setModalData, setOpenConfirm, category }) => {
  const { data: dataCategories } = useGetPlantCatgeoryQuery();
  const { data: dataPlants } = useGetPlantNameQuery();
  const [save] = useCreatePlantProductTypeMutation();
  const [update] = useUpdatePlantProductTypeMutation();

  const [payload, setPayload] = useState({ productType: "", plantCategoryId: "", plantNameId: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [optionCategories, setOptionCategories] = useState([]);
  const [optionPlants, setOptionPlants] = useState([]);

  const onSaveClicked = async () => {
    setIsLoading(true);
    try {
      if (!category) {
        console.log("Create Plant Product Type with Payload : ", payload);
        console.log(`Create Plant Product Type URL POST : ${process.env.REACT_APP_API_BASE_URL}/plant-product-type`);
        const response = await save(payload).unwrap();
        console.log("Create Plant Product Type Response : ", response);

        setModalData({
          title: "Success",
          subTitle: "Succes",
          description: "You’ve successfully added new plant product type.",
          typeModal: "success",
          typeConfirm: "success",
          isError: false,
        });
      } else {
        console.log("Edit Plant Product Type with Payload : ", payload);
        console.log(
          `Edit Plant Product Type URL POST : ${process.env.REACT_APP_API_BASE_URL}/plant-plant/${category._id}`
        );
        const response = await update({ id: category._id, payload }).unwrap();
        console.log("Edit Plant Product Type Response : ", response);

        setModalData({
          title: "Success",
          subTitle: "Success",
          description: "You’ve successfully edit the plant product Type.",
          typeModal: "success",
          typeConfirm: "success",
          isError: false,
        });
      }

      setTimeout(() => {
        setOpenConfirm(true);
        setIsLoading(false);
        handleClose();
      }, 700);
    } catch (err) {
      console.log("Plant  Response : ", err);

      setModalData({
        title: "Failed",
        subTitle: "Failed to save plant",
        description: err.data.message,
        typeModal: "failed",
        typeConfirm: "failed",
        isError: true,
      });
      setTimeout(() => {
        setOpenConfirm(true);
        setIsLoading(false);
        handleClose();
      }, 500);
    }
  };

  useEffect(() => {
    if (category) {
      setPayload({
        productType: category.productType,
        plantCategoryId: category.plantCategoryId,
        plantNameId: category.plantNameId,
      });
    }
  }, [category]);

  useEffect(() => {
    if (dataCategories) {
      const newOptionCategories = [];
      dataCategories.data.map((obj) => newOptionCategories.push({ id: obj._id, label: obj.text }));
      setOptionCategories(newOptionCategories);
    }
  }, [dataCategories]);

  useEffect(() => {
    if (dataPlants) {
      const newOptionPlants = [];
      dataPlants.data.map((obj) => newOptionPlants.push({ id: obj._id, label: obj.text }));
      setOptionPlants(newOptionPlants);
    }
  }, [dataPlants]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        handleClose={handleClose}
        size="md"
        type="success"
        padding="p-0"
        title="Create Product Type"
      >
        <WrapperFormCreate className="justify-between">
          <WrapperTextInput>
            <span>Product type</span>
            <input
              type="text"
              value={payload.productType}
              placeholder="Input product type"
              onChange={(e) => setPayload({ ...payload, productType: e.target.value })}
            />
          </WrapperTextInput>
          <WrapperTextInput>
            <span>Plant Cetegory</span>
            <Dropdown
              options={optionCategories}
              selectedOption={optionCategories.filter((opt) => opt.id === payload.plantCategoryId)[0]}
              handelChange={(e) => {
                setPayload({ ...payload, plantCategoryId: e.id });
              }}
              placeHolder="Input category"
            />
          </WrapperTextInput>
          <WrapperTextInput>
            <span>Plant Name</span>
            <Dropdown
              options={optionPlants}
              selectedOption={optionPlants.filter((opt) => opt.id === payload.plantNameId)[0]}
              handelChange={(e) => {
                setPayload({ ...payload, plantNameId: e.id });
              }}
              placeHolder="Input plant"
            />
          </WrapperTextInput>
          <div className="flex flex-row w-100 justify-center items-center gap-2 pt-2 pb-4">
            <Button
              variant="filled"
              variants="mainFilled"
              size="lg"
              className="w-full"
              type="button"
              disabled={isLoading}
              onClick={() => {
                onSaveClicked();
              }}
            >
              {isLoading && <Spinner />}
              Save
            </Button>
          </div>
        </WrapperFormCreate>
      </Modal>
    </>
  );
};

export default CreatePlantCategory;
