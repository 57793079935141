import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tabProduct: 'active',
  tabPod: 'active-pods',
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setTabProduct: (state, action) => {
      state.tabProduct = action.payload;
      return state;
    },
    setTabPod: (state, action) => {
      state.tabPod = action.payload;
      return state;
    },
  },
});

export const { setTabProduct, setTabPod } = layoutSlice.actions;

export default layoutSlice.reducer;
