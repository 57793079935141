import Modal from "../../modal/modal.component";
import {
  WrapperButtonCreate,
  WrapperButtonEdit,
  WrapperDetail,
  WrapperFormCreate,
  WrapperImageCreate,
  WrapperTextInput,
} from "./poduct-list.styles";
import Button from "../../button/button.component";
import Dropdown from "../../dropdown/dropdown.component";
import { useState, useEffect } from "react";
import { selectAllPresetss } from "../../../store/presets/presets.slice";
import { useCreateProductsTemplateMutation, useGetPlantProductTypeQuery } from "../../../store/product/product.slice";
import { useSelector } from "react-redux";
import Spinner from "../../loader/spinner.component";
import { PencilIcon } from "@heroicons/react/solid";
import getBase64 from "../../../utils/base64";

const Create = ({ isOpen, handleClose, modalData, setModalData, setOpenConfirm }) => {
  const presets = useSelector(selectAllPresetss);
  const [optionPresets, setOptionPresets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [createTemplate] = useCreateProductsTemplateMutation();
  const { data: dataProductTypes } = useGetPlantProductTypeQuery();
  const [optionCategories, setOptionCategories] = useState([]);
  let inputRef;

  const [payload, setPayload] = useState({
    productName: "",
    productDescription: "",
    productType: "",
    imageLink: "",
    preset: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const handleDescription = (e) => {
    setPayload({ ...payload, productDescription: e.target.value });
  };

  // const canPost =
  //   [payload.preset, payload.productDescription, payload.productName, payload.productType].every(Boolean) && !isLoading;

  const onSaveClicked = async () => {
    // if (canPost) {
      try {
        setIsLoading(true);
        console.log("Create Product Template with Payload : ", payload);
        console.log(`Create Product Template URL POST : ${process.env.REACT_APP_API_BASE_URL}/product-template`);
        const response = await createTemplate(payload).unwrap();
        console.log("Create Product Template Response : ", response);

        setModalData({
          title: "New Product Added",
          subTitle: "New Product Added",
          description: "You’ve successfully added new product.",
          typeModal: "",
          typeConfirm: "success",
          isError: false,
        });

        setTimeout(() => {
          setOpenConfirm(true);
          handleClose();
          setIsLoading(false);
        }, 1000);
      } catch (err) {
        console.log("Create Product Template Response : ", err);

        setModalData({
          title: "Failed",
          subTitle: "Failed create New Product",
          description: err.data.message,
          typeModal: "failed",
          typeConfirm: "failed",
          isError: true,
        });
        setTimeout(() => {
          setOpenConfirm(true);
          handleClose();
          setIsLoading(false);
        }, 500);
      }
    // }
  };

  const handleFileSelected = (e) => {
    const file = e.target.files && e.target.files[0];
    getBase64(file, (result) => {
      setPayload({ ...payload, imageLink: result });
    });
  };

  useEffect(() => {
    const arrayPods = [];
    presets?.map(({ _id, label }) => arrayPods.push({ id: _id, label: label }));
    setOptionPresets(arrayPods);
  }, [presets]);

  useEffect(() => {
    if (dataProductTypes) {
      const newOptionProductTypes = [];
      dataProductTypes.data.map((obj) => newOptionProductTypes.push({ id: obj.productType, label: obj.label }));
      setOptionCategories(newOptionProductTypes);
    }
  }, [dataProductTypes]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        handleClose={handleClose}
        size="md"
        type="success"
        padding="p-0"
        title="Product Information"
      >
        <WrapperDetail>
          <WrapperImageCreate className="relative">
            {payload.imageLink && <img src={payload.imageLink} alt="img" className="h-full w-full object-contain" />}
            <WrapperButtonEdit
              className="absolute h-[37px] w-[37px] flex flex-col justify-center items-center rounded-full bg-black -bottom-4 right-7 cursor-pointer"
              onClick={() => inputRef.click()}
            >
              <PencilIcon className="text-black" height={20} width={20} />
            </WrapperButtonEdit>
          </WrapperImageCreate>
          {/* <div className="p-6 flex flex-col"> */}
          <WrapperFormCreate>
            <input ref={(refParam) => (inputRef = refParam)} type="file" onChange={handleFileSelected} hidden={true} />
            <WrapperTextInput>
              <span>Product Name</span>
              <input
                type="text"
                value={payload.productName}
                placeholder="Input Product Name"
                name="productName"
                onChange={handleChange}
              />
            </WrapperTextInput>
            <WrapperTextInput>
              <span>Choose Cetegory</span>
              <Dropdown
                options={optionCategories}
                selectedOption={optionCategories.filter((opt) => opt.id === payload.productType)[0]}
                handelChange={(e) => {
                  setPayload({ ...payload, productType: e.id });
                }}
                placeHolder="Input Category"
              />
            </WrapperTextInput>
            <WrapperTextInput>
              <span>Description</span>
              <textarea
                defaultValue={payload.productDescription}
                rows={8}
                placeholder="Input Description"
                onChange={handleDescription}
              />
            </WrapperTextInput>
            <WrapperTextInput>
              <span>Choose Environment</span>
              <Dropdown
                options={optionPresets}
                selectedOption={
                  optionPresets.filter((opt) => {
                    return opt.id === payload.preset;
                  })[0]
                }
                handelChange={(e) => {
                  setPayload({ ...payload, preset: e.id });
                }}
                placeHolder="Choose Environment"
              />
            </WrapperTextInput>
            <WrapperButtonCreate>
              <Button variant="outlined" variants="dangerOutlined" size="lg" className="w-full" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="filled"
                variants="mainFilled"
                size="lg"
                className="w-full"
                type="button"
                // disabled={isLoading}
                onClick={() => {
                  onSaveClicked();
                }}
              >
                {isLoading && <Spinner />}
                Save
              </Button>
            </WrapperButtonCreate>
          </WrapperFormCreate>
          {/* </div> */}
        </WrapperDetail>
      </Modal>
    </>
  );
};

export default Create;
