/* eslint-disable react-hooks/exhaustive-deps */
import { useState, memo, useEffect } from "react";
import Card from "./card.component";
import ModalDetail from "./detail.component";
import { WrapperProductList } from "./product-active.styles";
import ModalForm from "./new-request/form.component";
import ModalConfirm from "./new-request/confirm.component";
import ModalConfirmPrepare from "./new-request/confirm-prepare.component";
import ModalConfirmDelete from "../../modal/modal-confirm.component";
import {
  useGetProductNewRequestsActiveQuery,
  useDeleteProductRequestMutation,
} from "../../../store/product/product.slice";
import InfiniteScroll from "react-infinite-scroll-component";
import Bounce from "../../loader/bounce.component";

const ListProduct = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [param, setParam] = useState({
    limit: 10,
    skip: 0,
    total: 0,
    hasMore: true,
  });
  const [query, setQuery] = useState(`?$limit=${param.limit}&$skip=${param.skip}`);
  const { data } = useGetProductNewRequestsActiveQuery(query, {
    refetchOnMountOrArgChange: true,
  });
  const [id, setId] = useState("");
  const [openDetail, setOpenDetail] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [typeModal, setTypeModal] = useState("newrequest-confirm");
  const [modalData, setModalData] = useState({});
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);

  const [deleteRequest] = useDeleteProductRequestMutation();

  useEffect(() => {
    if (data) {
      const newProducts = [];
      data?.data?.map((obj) => newProducts.push(obj));
      const concatProduct = [...products, ...newProducts];
      const unique = [...new Map(concatProduct.map((item) => [item["_id"], item])).values()];
      setParam({ ...param, total: data.total, hasMore: unique.length < data.total });
      setProducts(unique);
    }
  }, [data]);

  const fetchMoreData = () => {
    setQuery(`?$limit=${param.limit}&$skip=${param.skip + 10}`);
    setParam({ ...param, skip: param.skip + 10 });
  };

  const removeProductAfterAction = () => {
    const newProduct = products.filter((obj) => obj._id !== product._id);
    setProducts(newProduct);
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      console.log(`Delete Data Product New Request ID ${id}`);
      console.log(
        `Delete Data Product New Request URL DELETE : ${process.env.REACT_APP_API_BASE_URL}/product-template-user/${id}`
      );
      const response = await deleteRequest({
        id: id,
      }).unwrap();
      console.log(`Delete Data Product New Request Response : `, response);

      setTimeout(() => {
        setModalData({
          title: product?.productTemplate?.productName,
          description: `You’ve successfully deleted the product.`,
          typeModal: "succes",
          typeConfirm: "success",
          isError: false,
        });
        setTypeModal("confirm-success");
        setIsLoading(false);
        removeProductAfterAction();
      }, 1000);
    } catch (error) {
      setTypeModal("confirm-failed");
      setModalData({
        title: error?.data?.name,
        description: error?.data?.message,
        typeModal: "failed",
        typeConfirm: "failed",
        isError: true,
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      {openDetail && (
        <ModalDetail isOpen={openDetail} handleClose={() => setOpenDetail(false)} id={id} product={product} />
      )}

      {typeModal === "newrequest" && (
        <ModalForm
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          setTypeModal={setTypeModal}
          setModalData={setModalData}
          product={product}
        />
      )}

      {typeModal === "confirm-success" && (
        <ModalConfirm
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          modalData={modalData}
          product={product}
        />
      )}

      {typeModal === "confirm-failed" && (
        <ModalConfirm
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          type="failed"
          modalData={modalData}
          product={product}
        />
      )}

      {typeModal === "confirm-delete" && (
        <ModalConfirmDelete
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          title="Delete product request"
          description={`Are you sure, you want to delete this product ${product?.productTemplate?.productName}?`}
          typeModal="failed"
          typeConfirm="failed"
          isError={true}
          handleSave={handleDelete}
          withAction={true}
          isLoading={isLoading}
          titleButton="Delete"
        />
      )}

      {typeModal === "confirm-prepare" && (
        <ModalConfirmPrepare
          isOpen={openEdit}
          handleClose={() => setOpenEdit(false)}
          id={id}
          modalData={modalData}
          product={product}
          handleDelete={() => setTypeModal("confirm-delete")}
          handlePrepare={() => setTypeModal("newrequest")}
        />
      )}
      <em className="text-total-data">
        Showing {products.length} out of {param.total}
      </em>
      <InfiniteScroll
        dataLength={products.length}
        next={fetchMoreData}
        hasMore={param.hasMore}
        loader={
          <div className="flex flex-row w-100 justify-center items-center mt-10">
            <Bounce />
          </div>
        }
        scrollableTarget="scrollableDiv"
      >
        <WrapperProductList>
          {products?.map((product, index) => (
            <Card
              key={index}
              product={product}
              openDetail={() => {
                setProduct(product);
                setOpenDetail(true);
              }}
              openEdit={() => {
                setProduct(product);
                setOpenEdit(true);
              }}
              setTypeModal={setTypeModal}
              setId={setId}
            />
          ))}
        </WrapperProductList>
      </InfiniteScroll>
    </>
  );
};

export default memo(ListProduct);
