import Modal from "../../modal/modal.component";
import {
  WrapperDescriptionDetail,
  WrapperDetail,
  WrapperImageDetail,
  WrapperPresetDetail,
} from "./product-active.styles";

const Detail = ({ isOpen, handleClose, id, product }) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      size="md"
      type="success"
      padding="p-0"
      title={product?.productTemplate?.productName}
    >
      <WrapperDetail>
        <WrapperImageDetail className="relative">
          {product?.productTemplate?.imageLink && (
            <img src={product?.productTemplate?.imageLink} alt="img" className="h-full w-full object-contain" />
          )}
        </WrapperImageDetail>
        <WrapperDescriptionDetail>
          <span>{product?.productTemplate?.productDescription}</span>
          <WrapperPresetDetail>
            <span>Environment</span>
            <p>{product?.productTemplate?.presetLabel}</p>
            <p>{product?.pod?.address}</p>
          </WrapperPresetDetail>
        </WrapperDescriptionDetail>
      </WrapperDetail>
    </Modal>
  );
};

export default Detail;
