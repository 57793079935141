const bannerArray = [
  {
    name: "GRAY",
    value: 3,
  },
  {
    name: "GREEN",
    value: 2,
  },
  {
    name: "YELLOW",
    value: 1,
  },
  {
    name: "RED",
    value: 0,
  },
];

export const setBanner = (indicator) => {
  if (!indicator) return "GRAY";
  let banner = bannerArray.find((obj) => obj.name === indicator.air_humidity);

  let air_co2 = bannerArray.find((obj) => obj.name === indicator.air_co2);

  if (banner.value > air_co2.value) {
    banner = air_co2;
  }

  let water_acid = bannerArray.find((obj) => obj.name === indicator.water_acid);

  if (banner.value > water_acid.value) {
    banner = water_acid;
  }

  let water_nutrient = bannerArray.find(
    (obj) => obj.name === indicator.water_nutrient
  );

  if (banner.value > water_nutrient.value) {
    banner = water_nutrient;
  }

  return banner.name;
};
