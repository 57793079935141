import Modal from "../../../modal/modal.component";
import Button from "../../../button/button.component";
import {
  WrapperDescriptionDetail,
  WrapperDetail,
  WrapperImageDetail,
  WrapperPresetDetail,
} from "../product-active.styles";

const Confirm = ({ handleClose, isOpen, type = "success", modalData, product, handlePrepare, handleDelete }) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      size="md"
      type="success"
      padding="p-0"
      title={product?.productTemplate?.productName}
    >
      <WrapperDetail>
        <WrapperImageDetail className="relative">
          {product?.productTemplate?.imageLink && (
            <img src={product?.productTemplate?.imageLink} alt="img" className="h-full w-full object-contain" />
          )}
        </WrapperImageDetail>
        <WrapperDescriptionDetail>
          <span>{product?.productTemplate?.productDescription}</span>
          <WrapperPresetDetail>
            <span>Environment</span>
            <p>{product?.productTemplate?.presetLabel}</p>
            <p>{product?.pod?.address}</p>
          </WrapperPresetDetail>
          <div className="flex flex-row gap-3 mt-4">
            <Button variants="dangerFilled" size="lg" type="button" onClick={handleDelete} className="w-[50%]">
              Delete
            </Button>
            <Button variants="mainFilled" size="lg" type="button" onClick={handlePrepare} className="w-[50%]">
              Prepare
            </Button>
          </div>
        </WrapperDescriptionDetail>
      </WrapperDetail>
    </Modal>
  );
};

export default Confirm;
