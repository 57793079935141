import {
  Flex,
  Menu,
  WrapperAside,
  WrapperLogo,
  WrapperMenu,
} from "./Aside.styles";
import BrandLogo from "../../assets/img/brand-logo.svg";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { useState, memo, useEffect } from "react";
import DashboardSvg from "../../assets/img/dashboard.svg";
import GreensIotSvg from "../../assets/img/greens-iot.svg";
import GreensProductSvg from "../../assets/img/greens-products.svg";
import UserSvg from "../../assets/img/user.svg";
import SettingsSvg from "../../assets/img/settings.svg";
import LogoutSvg from "../../assets/img/logout.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setTabProduct } from "../../store/layout/layout.slice";
import { logout } from "../../store/auth/auth.slice";

const Aside = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleProductTab = () => {
    dispatch(setTabProduct("active"));
    navigate("/products/active");
  };

  const handleLogout = async () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [navigate, user]);

  return (
    <WrapperAside isExpanded={isExpanded} className="hidden md:block my-3 ml-3">
      <WrapperLogo>
        <div
          className="bg-gray-600 rounded-full mb-2 flex flex-col justify-center items-center img-brand"
          style={{ height: "60px", width: "60px" }}
        >
          <img
            src={BrandLogo}
            className="img-fluid h-100 w-100 rounded-circle"
            alt="img"
          />
        </div>
        <span className="whitespace-nowrap">Front End Test</span>
        <p>Botanist</p>
      </WrapperLogo>
      <WrapperMenu>
        <Link to="/">
          <Menu>
            <img src={DashboardSvg} className="menu-icons" alt="dashboard" />
            <span className="title">Dashboard</span>
          </Menu>
        </Link>
        <Link to="/pod/active-pods">
          <Menu>
            <img src={GreensIotSvg} className="menu-icons" alt="greens iot" />
            <span className="title">GREENS IoT</span>
          </Menu>
        </Link>
        <Menu onClick={handleProductTab}>
          <img
            src={GreensProductSvg}
            className="menu-icons"
            alt="greens product"
          />
          <span className="title">GREENS Product</span>
        </Menu>
        <Link to="/users">
          <Menu>
            <img src={UserSvg} className="menu-icons" alt="dashboard" />
            <span className="title">Users</span>
          </Menu>
        </Link>
        <Link to="/settings">
          <Menu>
            <img src={SettingsSvg} className="menu-icons" alt="dashboard" />
            <span className="title">Settings</span>
          </Menu>
        </Link>
        <Menu onClick={handleLogout}>
          <img src={LogoutSvg} className="menu-icons" alt="dashboard" />
          <span className="title">Log Out</span>
        </Menu>
        <Menu className="mt-10" onClick={() => setIsExpanded(!isExpanded)}>
          <ChevronRightIcon className="menu-icons toggle" />
          <span className="title">Hide</span>
        </Menu>
      </WrapperMenu>
      <Flex />
    </WrapperAside>
  );
};

export default memo(Aside);
