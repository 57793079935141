import Login from "../../components/login/login.component";

const Index = () => {
  return (
    <div className="w-full h-screen bg-main-color flex flex-col justify-center items-center px-4">
      <Login />
    </div>
  );
};

export default Index;
