import { useEffect, useState } from "react";
import ListNotification from "../components/dashboard/list-notification";
import WrapperCounting from "../components/dashboard/wrapper-counting";
import { useGetAllDataQuery } from "../store/dashboard/dashboard.api";

const Index = () => {
  const { data: response, isSuccess, isLoading } = useGetAllDataQuery();
  const [data, setData] = useState(null);
  useEffect(() => {
    if (isSuccess) {
      if (response) {
        const { ids, entities } = response;
        setData(entities[ids[0]]);
      }
    }
  }, [isSuccess, response]);

  return (
    <div className="h-screen w-100">
      <div className="flex flex-row gap-1">
        <div className="xs:w-full md:w-[75%]">
          <WrapperCounting data={data} />
        </div>
        <div className="hidden md:block w-[25%] bg-main-grey-200 h-screen rounded-[10px] mb-5">
          <ListNotification />
        </div>
      </div>
    </div>
  );
};

export default Index;
