import {
  WrapperButton,
  WrapperCardProductList,
  WrapperDescription,
  WrapperImage,
  WrapperPreset,
} from './product-history.styles';
import { memo } from 'react';

const CardList = ({ product, openDetail, openEdit, setIdDetail }) => {
  return (
    <WrapperCardProductList>
      <WrapperImage />
      <WrapperDescription>
        <span
          onClick={() => {
            setIdDetail(product?._id);
            openDetail();
          }}
        >
          {product?.productName || 'BabyGREENS - Kale'}
        </span>
        <label className="text-grey-300">060B21-GP-00-1-0001-00-002</label>
        <hr className="text-grey-300" />
        <WrapperPreset>
          <span className="value-green">
            {product?.presetLabel || 'Harvest'}
          </span>
          <p>Status</p>
        </WrapperPreset>
        <WrapperPreset>
          <span className="value-green">
            {product?.presetLabel || '30 August 2021'}
          </span>
          <p>Harvest Date</p>
        </WrapperPreset>
      </WrapperDescription>
      <WrapperButton
        onClick={() => {
          // setIdDetail(product?._id || '1');
          openDetail();
        }}
      >
        Details
      </WrapperButton>
    </WrapperCardProductList>
  );
};

export default memo(CardList);
