import Modal from "../../../modal/modal.component";
import Button from "../../../button/button.component";
import {
  WrapperButton,
  WrapperDescription,
  WrapperDetail,
  WrapperImageDetail,
  WrapperTextInput,
} from "./enroute.styles";
import { useEffect, useState } from "react";
import Autocomplete from "../../../dropdown/autocomplete.component";
import { useUpdateStateMutation } from "../../../../store/product/product.slice";
import { HARVEST_STATE } from "../../../../helper/harvestState";
import { useGetPodsQuery } from "../../../../store/pod/pod.slice";
import Spinner from "../../../loader/spinner.component";
import produce from "immer";
import moment from "moment/moment";

const EnRoute = ({ isOpen, handleClose, id, setTypeModal, setModalData, product, removeProductAfterAction }) => {
  const [updateState] = useUpdateStateMutation();
  const { data: dataPods } = useGetPodsQuery("?$limit=50&visible=true");
  const [isLoading, setIsLoading] = useState(false);
  const [pods, setPods] = useState([]);
  const [selectedPod, setSelectedPod] = useState([]);
  const [listPod, setListPod] = useState([]);
  const [payload, setPayload] = useState({
    totalTray: 0,
    podId: product?.pod?.id,
    harvestState: "",
  });

  const handleChange = (index, value) => {
    const nextState = produce(listPod, (draftState) => {
      listPod.map((obj, idx) => {
        if (idx === index) {
          draftState[index].totalTray = +value;
        }
        return true;
      });
    });

    setListPod(nextState);
  };

  const removeItem = (index) => {
    let newList = [...listPod];
    if (newList.length > 0) {
      newList.splice(index, 1);
    } else {
      newList = [];
    }
    setListPod(newList);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      let newPayload = {};
      listPod?.map(async (obj) => {
        newPayload = {
          harvestState: HARVEST_STATE["PROCESSING"],
          codeName: obj.id,
          totalTray: obj.totalTray,
          notes: "",
        };
      });

      console.log("Set Product to Processing with Payload : ", newPayload);
      console.log(`Set Product to Processing URL PATCH : ${process.env.REACT_APP_API_BASE_URL}/product/${id}`);
      const response = await updateState({
        id,
        initialPost: newPayload,
      }).unwrap();
      console.log("Set Product to Processing Response : ", response);

      setModalData({
        description: `Batch is processing Est. Harvest ${moment(product.plantDate).format("DD MMM YYYY")}`,
      });

      removeProductAfterAction();
      setTypeModal("confirm-success");
      setIsLoading(false);
    } catch (err) {
      setModalData({
        description: err.data.message,
      });
      setTypeModal("confirm-failed");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (dataPods?.data) {
      const newPods = [];
      dataPods?.data.map((pod) =>
        newPods.push({ id: pod.codeName, name: `${pod.codeName} - ${pod.podTypeLabel ?? ""}` })
      );
      setPods(newPods);
    }
  }, [dataPods]);

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      size="lg"
      type="success"
      padding="p-0"
      title={product?.productName}
    >
      <WrapperDetail className="rounded-b-[18px] overflow-hidden">
        <WrapperImageDetail className="relative">
          {product?.imageLink && <img src={product?.imageLink} alt="img" className="h-full w-full object-contain" />}
        </WrapperImageDetail>
        <WrapperDescription>
          <WrapperTextInput>
            <span>Add Batch</span>
            <div className="flex gap-5 w-full items-center">
              <Autocomplete
                options={pods}
                selected={selectedPod}
                onChange={(e) => setSelectedPod({ ...e, totalTray: "" })}
                query={""}
                onChangeQuery={() => {}}
              />
              <Button
                size="lg"
                variant="filled"
                variants="mainFilled"
                type="button"
                className="w-1/5"
                onClick={() => {
                  const newList = [...listPod, selectedPod];
                  setListPod(newList);
                  setSelectedPod([]);
                }}
                disabled={listPod.length > 0}
              >
                Add
              </Button>
            </div>
          </WrapperTextInput>
          <WrapperTextInput className="mt-4">
            <span>Batch Arrive</span>
          </WrapperTextInput>
          {listPod?.map((pod, index) => (
            <WrapperTextInput className="" key={index}>
              <div className="flex gap-5 w-100">
                <input
                  type="text"
                  value={pod.id}
                  className="maxSM:text-xs maxSM:w-[50%]"
                  disabled
                  onChange={() => {}}
                />
                <input
                  type="text"
                  value={pod.totalTray}
                  placeholder="Qty"
                  className="txt-qty"
                  onChange={(e) => handleChange(index, e.target.value)}
                />
                <Button
                  size="lg"
                  variant="outlined"
                  variants="dangerOutlined"
                  type="button"
                  className="w-1/5"
                  onClick={() => removeItem(index)}
                >
                  Delete
                </Button>
              </div>
            </WrapperTextInput>
          ))}
        </WrapperDescription>
        <WrapperButton>
          <Button variants="mainFilled" size="lg" type="button" onClick={handleSubmit} className="w-52">
            {isLoading && <Spinner />}
            Arrive
          </Button>
        </WrapperButton>
      </WrapperDetail>
    </Modal>
  );
};

export default EnRoute;
