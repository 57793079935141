import { createSelector, createEntityAdapter } from '@reduxjs/toolkit';
import { apiSlice } from '../api/api';

const podTypeAdapter = createEntityAdapter({
  selectId: (state) => state._id,
});

const initialState = podTypeAdapter.getInitialState();

export const podTypesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPodTypes: builder.query({
      query: () => '/pod-type',
      transformResponse: (responseData) => {
        return podTypeAdapter.setAll(initialState, responseData.data);
      },
      providesTags: (result, error, arg) => [
        { type: 'Pod Type', id: 'LIST' },
        // ...result.ids.map((id) => ({ type: 'Pod Type', id })),
      ],
    }),
  }),
});

export const { useGetPodTypesQuery } = podTypesApiSlice;

export const selectPodTypeResult =
  podTypesApiSlice.endpoints.getPodTypes.select();

const selectPodTypesData = createSelector(
  selectPodTypeResult,
  (podTypesResult) => podTypesResult.data // normalized state object with ids & entities
);

export const { selectAll: selectAllPodTypes, selectById: selectPodTypeById } =
  podTypeAdapter.getSelectors(
    (state) => selectPodTypesData(state) ?? initialState
  );
