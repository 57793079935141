import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './api/api';
import layoutSlice from './layout/layout.slice';
import authSlice from './auth/auth.slice';

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    layout: layoutSlice,
    auth: authSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
});
